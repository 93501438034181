import { apiAxios } from "../axios";

import Europess from "../wangZhi";

let Europe = Europess + "/client/";

export default {
  // 获取分类栏目
  getShopGroup: (data) => apiAxios("post", Europe + "goodsGroup", data),
  // 网站Banner列表
  getShopBanner: (data) => apiAxios("post", Europe + "webBanner", data),
  // 个性化商品推荐(注释参照商品搜索)
  hotGoods: (data) => apiAxios("post", Europe + "hotGoods", data),
  // 获取常规推荐商品
  autoGoods: (data) => apiAxios("post", Europe + "autoGoods", data),
  // 获取常规推荐商品
  youLikeGoods: (data) => apiAxios("post", Europe + "youLikeGoods", data),
  //弹出未作答的必答问卷
  mustParticipateQuestionnaire: (data) =>
    apiAxios("post", Europe + "user.mustParticipateQuestionnaire", data),
  //问券详情
  questionnaireDetail: (data) =>
    apiAxios("post", Europe + "user.questionnaireDetail", data),
  //提交调查问卷作答
  questionnaireSubmit: (data) =>
    apiAxios("post", Europe + "user.questionnaireSubmit", data),
  //网易分类
  homeCategories: (data) => apiAxios("post", Europe + "homeCategories", data),
  //网易首页推荐商品
  recommendedGoods: (data) =>
    apiAxios("post", Europe + "recommendedGoods", data),
  //分类详情列表
  categoryList: (data) => apiAxios("post", Europe + "categoryList", data),
  //网易分类下商品
  categoryGoodsList: (data) =>
    apiAxios("post", Europe + "categoryGoodsList", data),
  // 新手指南-文章-列表
  guideArticleList: (data) =>
    apiAxios("post", comm_api + "/api/common/config.guide.articleList", data),
};
