<!-- 聊天弹出框 -->
<template>
  <van-popup
    v-if="false"
    v-model:show="show"
    position="right"
    :style="{ height: '100%', width: '100%' }"
  >
    <MyLoading v-if="loading" />
    <!-- <div class="loadingModel" v-if="loading">
      <van-loading color="#b4272b" size="150" />
    </div> -->
    <div class="page">
      <div class="zuJianHead" style="height: 56px">
        <div
          class="closeBtn"
          @click="
            $store.commit('changeshowChat', !this.$store.state.showChat);
            show = false;
          "
        >
          <van-icon name="cross" />
        </div>
      </div>

      <div class="chatBody">
        <iframe
          id="chatIframe"
          :src="
            'https://rakuchat.rakumart.com/' +
            `client_h5/#/?id=${
              ($store.state.userInfo || {}).operation_id
            }&token=${token || ''}`
          "
          frameborder="0"
          class="chatBody"
        ></iframe>
      </div>
    </div>
  </van-popup>
  <div class="propBox" :class="{ open: show }" v-loading="loading">
    <!-- <MyLoading v-if="loading" /> -->
    <div class="zuJianHead" style="height: 56px">
      <div
        class="closeBtn"
        @click="
          $store.commit('changeshowChat', !this.$store.state.showChat);
          show = false;
        "
      >
        <van-icon name="cross" />
      </div>
    </div>
    <div class="chatBody">
      <iframe
        :key="key"
        id="chatIframe"
        :src="'https://rakuchat.rakumart.com/client_h5/#/' + txt"
        frameborder="0"
        class="chatBody"
      ></iframe>
    </div>
  </div>
</template>

<script>
import MyLoading from "../loading";
import { Popup } from "vant";
export default {
  data() {
    return {
      show: false,
      loading: true,
      key: 999,
      token: localStorage.getItem("japan_user_token"),
      txt: "?id=hsdhjdhdjdjd&token=asdfsadfdsfsafdsaf",
    };
  },
  watch: {
    "$store.state.showChat": {
      handler(newValue, oldValue) {
        if (newValue) {
          this.open();
        } else {
          this.show = this.$store.state.showChat;
          // 调用清除聊天数量的接口
          this.$api.setTimMessageRead();
        }
      },
    },
    // 在用户信息有变动的时候重新加载聊天
    "localStorage.getItem('japan_user_token')": {
      handler(newValue, oldValue) {
        // this.key++;
        this.token = localStorage.getItem("japan_user_token") || "";
      },
    },
  },
  components: {
    [Popup.name]: Popup,
    MyLoading,
  },
  computed: {},
  created() {
    this.$store.commit("changeshowChat", false);
    // setTimeout(() => {
    //   this.loading = false;
    // }, 3000);
    // if (this.$store.state.showChat) {
    //   this.open();
    // } else {
    //   this.show = this.$store.state.showChat;
    // }
  },
  mounted() {},
  methods: {
    // 判断iframe是否加载完成
    iframeLoad() {
      setTimeout(() => {
        var _this = this;
        const iframe = document.querySelector("#chatIframe");
        // 处理兼容行问题 兼容IE
        if (iframe.attachEvent) {
          iframe.attachEvent("onload", function () {
            // iframe加载完毕以后执行操作

            // console.log("iframe已加载完毕");
            _this.loading = false;
            _this.$store.dispatch("getTimMessageUnreadNum");
          });
        } else {
          iframe.onload = function () {
            // iframe加载完毕以后执行操作
            //  console.log("iframe已加载完毕");
            _this.loading = false;
            _this.$store.dispatch("getTimMessageUnreadNum");
          };
        }
      }, 300);
    },
    open() {
      // this.show = true;
      // this.iframeLoad();
      // return;
      if (this.txt == "?id=hsdhjdhdjdjd&token=asdfsadfdsfsafdsaf") {
        this.key++;
      }
      if (localStorage.getItem("japan_user_token")) {
        this.txt = `?id=${
          (this.$store.state.userInfo || {}).operation_id
        }&token=${this.token || ""}`;
        this.$api
          .userTimGroupCheck({
            user_id: this.$store.state.userInfo.operation_id,
          })
          .then((res) => {
            // if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          });
        ////console.log('事件名',res)
        //接下来的操作
        this.token = localStorage.getItem("japan_user_token");
        this.show = true;
        setTimeout(() => {
          this.loading = false;
        }, 3000);
        this.iframeLoad();
      } else {
        this.$store.commit("changeshowChat", false);
        this.$store.commit(
          "changeshowLoginDia",
          !this.$store.state.showLoginDia
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.propBox {
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(100%);
  position: fixed;
  z-index: 1333333;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  .loadingModel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #ffffff, $alpha: 0.6);
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    .van-loading {
      width: 80px;
      height: 80px;
    }
  }
}
.propBox.open {
  transform: translateX(0%);
}
.page {
  overflow: auto;
  background-color: white;
  padding: 0 0px 100px;
  height: 100%;
}

.zuJianHead {
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  z-index: 12;
  display: flex;
  justify-content: flex-end;

  .closeBtn {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 40px;
    }
  }
}

.chatBody {
  width: 100%;
  height: 100%;
}
</style>
