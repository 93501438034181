import { Notify, Toast } from "vant";
export default (() => {
  return {
    error: (data) => {
      return Toast(data);
    },
    success: (data) => {
      return Toast(data);
    },
    warning: (data) => {
      return Toast(data);
    },
    primary: (data) => {
      return Toast(data);
    },
    // error: (data) => {
    //     return Notify({ type: 'danger', message: data });
    // },
    // success: (data) => {
    //     return Notify({ type: 'success', message: data });
    // },
    // warning: (data) => {
    //     return Notify({ type: 'warning', message: data });
    // },
    // primary: (data) => {
    //     return Notify({ type: 'primary', message: data });
    // },
  };
})();
