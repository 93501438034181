import { createApp } from "vue";
import "amfe-flexible";
import App from "./App.vue";
import "./utlis/browserPatch.js";
// API挂到vue原型下
// 浏览器滚动插件 加快页面响应速度 不加会有黄色报错 :https://blog.csdn.net/pia_ji/article/details/115325760
import "default-passive-events";
import api from "./api/apis";
import fun from "./utlis/CommonlyUsedFunctions";
import fanyi from "./utlis/language.js";
import "./css/vantH5.scss"; //vant 适应样式
import { router } from "./router/index";
import "@/css/openSans.css";
import "@/css/common.css";
import "@/styles/theme.css"; // 定制主题
import "@/styles/scss/index.scss"; // 引入全局样式
import imdata from "./utlis/allImportData";
import store from "./store";

// 解决 ElementUI校验[Violation] Added non-passive event listener to a scroll-blocking ‘touchmove‘ event. 问题的插件
import "default-passive-events";

const app = createApp(App);
// 根据van做的自定义全局加载中动画
import myloading from "./components/loading/index.js";
app.use(myloading);
app.config.globalProperties.$fun = fun;
app.config.globalProperties.$api = api;
app.config.globalProperties.$fanyi = fanyi;
app.config.globalProperties.$store = store;
app.config.globalProperties.$imdata = imdata;

app.use(store);
app.use(router);

import messageJs from "./utlis/notice.js";
app.config.globalProperties.$message = messageJs;

// 引入vantUi
import vantUiImport from "./vantUiImport";
vantUiImport(app);
// 引入自定义指令
import directive from "./utlis/directive";
directive(app);

import myImg from "./components/myPicture/index.vue";
app.component("myImg", myImg);
app.mount("#app");
