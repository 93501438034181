import { apiAxios } from "../axios";
import Europess from "../wangZhi";

let comm_api = Europess;

export default {
  // 实时汇率
  commonExchangeRate: (data) =>
    apiAxios("post", comm_api + "/api/common/getExchangeRat", data),

  // 发送验证码
  sendVerificationCode: (data) =>
    apiAxios("post", comm_api + "/client/getRegisterVerificationCode", data),
  // 上传文件
  uploadFile: (data) => apiAxios("post", comm_api + "/common/uploadFile", data),
  //获取订单全部状态名
  getOrderTypes: (data) =>
    apiAxios("post", comm_api + "/api/common/getOrderTypes", data),
  //比对验证码
  checkVerificationCode: (data) =>
    apiAxios("post", comm_api + "/client/checkRegisterCode", data),
  //国际物流说明页
  logisticsTableData: (data) =>
    apiAxios("post", comm_api + "/api/common/explain.logisticsTableData", data),
  //国际物流说明页-试算
  getLogisticsAmountAll: (data) =>
    apiAxios(
      "post",
      comm_api + "/api/common/explain.getLogisticsAmountAll",
      data
    ),
  //商品链接转商品ID
  goodsUrlToGoodsId: (data) =>
    apiAxios("post", comm_api + "/api/common/goodsUrlToGoodsId", data),
  //文件url转换成文件流
  downloadFile: (data) =>
    apiAxios(
      "post",
      "https://laveltest.rakumart.cn/api/common/downloadFile",
      data
    ),
  //文章-列表
  articleList: (data) =>
    apiAxios("post", comm_api + "/client/config.qa.articleList", data),
  //文章-热门问题
  top10Articles: (data) =>
    apiAxios("post", comm_api + "/client/config.qa.top10Articles", data),
  //搜索关键词
  keywordSNQuery: (data) =>
    apiAxios("post", comm_api + "/client/keywordSNQuery", data),
  // 获取上传token
  getUploadToken: (data) =>
    apiAxios("post", comm_api + "/api/common/getUploadToken", data),
  // 新手指南-文章-列表H5
  articleListH5: (data) =>
    apiAxios("post", comm_api + "/api/common/config.guide.articleListH5", data),
};
