let urlHead = "";
let urlarr = ["https://apiwww.rakumart.com", "https://jpapi.rakumart.cn"];
// urlHead = 'https://jpapi.rakumart.cn'
// urlHead = 'https://apiwww.rakumart.com'
if (location.origin.indexOf("192.168") != -1) {
  urlHead = urlarr[1];
} else if (
  location.origin.indexOf("8080") != -1 ||
  location.origin.indexOf("8081") != -1 ||
  location.origin.indexOf("8082") != -1 ||
  location.origin.indexOf("8083") != -1
) {
  urlHead = urlarr[1];
} else if (location.host.indexOf(".com") !== -1) {
  urlHead = urlarr[0];
} else {
  urlHead = urlarr[1];
}
// if (urlHead == 'https://jpapi.rakumart.cn') {
//     alert('现在是测试接口')
// }
if (
  location.href.indexOf("rakumart.com") != -1 &&
  urlHead == "https://jpapi.rakumart.cn"
) {
  document.getElementsByTagName("body")[0].style.pointerEvents = "none";
}
export default urlHead;
