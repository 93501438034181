<template>
  <!-- :close-on-click-overlay="true" -->
  <van-dialog v-model:show="show" class="loginDialog">
    <template #title>
      <div class="diaHead">
        <div class="closeBtn" @click="close">
          <div></div>
          <img :src="require('@/assets/icon/chaLogin.png')" alt="" />
        </div>
      </div>
    </template>
    <div>
      <div class="loginCon">
        <img
          class="logoIcon"
          @click="$fun.routerToPage('/')"
          :src="require('@/assets/icon/app-logo.png')"
          alt=""
        />
        <van-form>
          <van-cell-group inset>
            <!-- 通过 pattern 进行正则校验 -->
            <van-field
              v-model="loginData.login_name"
              name="pattern"
              :label="$fanyi('账号')"
              :placeholder="$fanyi('登录名/邮箱/手机号')"
              :rules="[]"
            />
            <!-- 密码 -->
            <van-field
              class="passwordInput"
              v-model.trim="loginData.password"
              name="password"
              :type="passwordtype"
              label-align="top"
              v-model="loginData.password"
              :label="$fanyi('密码')"
              :placeholder="$fanyi('请输入密码')"
              :rules="[]"
            >
              <template #right-icon>
                <img
                  src="../../assets/register/pskiqjtniew8qn40zy6z27vmuj8pz83nlpk1ff25a83-0550-43e4-b065-7ac1b43e24c0.png"
                  alt=""
                  v-if="passwordtype"
                  @click="passwordtype = ''"
                />
                <img
                  src="../../assets/register/psf6jdb4b7bul6anwcjhoyc6okp25iqe2fef905-6352-437e-987e-3612fa48c9c9.png"
                  alt=""
                  v-else
                  @click="passwordtype = 'password'"
                />
              </template>
            </van-field>
          </van-cell-group>
        </van-form>
        <div class="yongHuCaoZuo">
          <div class="rememberPassword">
            <input type="checkbox" v-model="Save_the_password" />
            <span @click="Save_the_password = !Save_the_password">
              {{ $fanyi("记住密码") }}
            </span>
          </div>
          <div class="forgetPassword">
            <span
              class="wangJiMiMa"
              @click="
                close();
                $fun.routerToPage('/ForgetThePassword');
              "
              >{{ $fanyi("忘记密码") }}
            </span>
          </div>
        </div>
        <button class="loginBtn" @click="login">
          {{ $fanyi("登录") }}
        </button>
        <div class="toRegister">
          <span> {{ $fanyi("还没有账号？") }}</span>
          <span class="linkSpan" @click="$fun.routerToPage('/register')">{{
            $fanyi("前往注册")
          }}</span>
        </div>
        <div class="technicalSupport">
          {{ $fanyi("由1688提供部分技术服务⽀持") }}
        </div>
      </div>
    </div>
  </van-dialog>
  <mydialog ref="mydialog"></mydialog>
  <mydialog ref="loginDia" class="errorMsgDia">
    <div class="errorMsgBox">
      <div v-html="error_msg"></div>
    </div>
  </mydialog>
</template>
<script>
import { ref } from "vue";
import mydialog from "../Dialog";
import { getCurrentInstance, onMounted, watch } from "vue";
import { Dialog, Form, Field, CellGroup } from "vant";
export default {
  setup() {
    const { proxy } = getCurrentInstance(); //获取上下文实例，ctx=vue2的this
    const show = ref(false);
    const parameter = ref({});
    const loginData = ref({});
    const Save_the_password = ref(false);
    const loginmethods = ref(null); // 登录执行后的操作
    const passwordtype = ref("password"); //是否明文显示密码
    const error_msg = ref(); //登录错误信息
    const closeMethods = ref(() => {
      return true;
    }); //用户点击关闭事件
    const login = () => {
      if (loginData.value.login_name === "") {
        proxy.$message.error(proxy.$fanyi("请输入账号或手机号或邮箱"));
        return;
      }
      if (loginData.value.password === "") {
        proxy.$message.error(proxy.$fanyi("请输入密码"));
        return;
      }
      proxy.$api
        .logins({
          account: loginData.value.login_name,
          password: loginData.value.password,
          client_tool: 2,
        })
        .then((res) => {
          if (!res.success) {
            error_msg.value = proxy.$fanyi(res.msg);
            proxy.$refs.loginDia.open({
              successBtn: proxy.$fanyi("确认"),
            });
            return;
          }
          localStorage.setItem("japan_user_token", res.data.userToken);
          proxy.$api.EuropegetUserInfo().then((res) => {
            // 记住密码
            if (Save_the_password.value == true) {
              localStorage.setItem("jpusername", loginData.value.login_name);
              localStorage.setItem("jppassword", loginData.value.password);
              localStorage.setItem("jpkeep", 1);
            } else {
              localStorage.removeItem("jpusername");
              localStorage.removeItem("jppassword");
              localStorage.removeItem("jpkeep");
            }
            // 获取用户信息
            proxy.$store.commit("userData", res.data);
            // 获取用户购物车数量
            proxy.$store.dispatch("goodsToCartNum");
            // proxy.$store.commit("userInfo", res.data);
            proxy.$store.commit("changeshowLoginDia", false);
            // proxy.$fun.routerToPage("/");
            if (loginmethods.value) {
              loginmethods.value();
            }
          });
        });
    };

    const close = () => {
      show.value = false;
      closeMethods.value();
      proxy.$store.commit("changeshowLoginDia", false);
    };
    watch(
      () => proxy.$store.state.showLoginDia,
      (val, preVal) => {
        //val为修改后的值,preVal为修改前的值
        // 初始化数据
        loginmethods.value = null;
        localStorage.getItem("jpkeep") == 1
          ? (Save_the_password.value = true)
          : (Save_the_password.value = false);

        loginData.value.login_name = localStorage.getItem("jpusername")
          ? localStorage.getItem("jpusername")
          : "";
        loginData.value.password = localStorage.getItem("jppassword")
          ? localStorage.getItem("jppassword")
          : "";
        // 如果传入vuex的是方法就在登陆成功后执行方法
        if (typeof proxy.$store.state.showLoginDia == "function") {
          show.value = true;
          loginmethods.value = proxy.$store.state.showLoginDia;
        } else if (typeof proxy.$store.state.showLoginDia == "object") {
          show.value = true;
          loginmethods.value = proxy.$store.state.showLoginDia.success; //登陆成功事件赋值
          closeMethods.value = proxy.$store.state.showLoginDia.cancel; //关闭弹窗事件赋值
        } else {
          show.value = val;
        }
      },
      {
        //如果加了这个参数，值为true的话，就消除了惰性，watch会在创建后立即执行一次
        //那么首次执行，val为默认值,preVal为undefined
        // immediate: true,
        // //这个参数代表监听对象时，可以监听深度嵌套的对象属性
        // //比如message是一个对象的话，可以监听到message.a.b.c，也就是message下的所有属性
        // deep: true,
      }
    );
    return {
      // 参数
      show,
      parameter,
      loginData,
      Save_the_password,
      passwordtype,
      error_msg,
      // 事件
      login,
      closeMethods,
      close,
    };
  },
  mounted() {},
  components: {
    [Dialog.Component.name]: Dialog.Component,
    mydialog,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.loginCon {
  width: 650px;
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .passwordInput,
  :deep(.passwordInput) {
    .van-field__right-icon {
      position: absolute;
      right: 40px;
      font-size: 20px;
      * {
        font-size: 20px;
        width: 30px;
        height: 20px;
      }
    }
  }
  // logo图标
  .logoIcon {
    display: block;
    width: 400px;
    margin-bottom: 40px;
  }

  // 表单样式
  .van-form,
  :deep().van-form {
    border-radius: 0;

    width: 100%;

    .van-cell-group {
      margin: 0;
    }

    .van-cell {
      padding: 15px 40px;
      flex-direction: column;

      &::after {
        border-bottom: none;
      }

      .van-cell__title {
        font-size: 28px;
        line-height: 1;
        margin: 0 11px 15px;
        font-weight: 400;
      }

      .van-field__body {
        input {
          padding: 0 10px;
          width: 570px;
          height: 70px;
          background: #f2f2f2;
          border: none;
          border-radius: 6px;
          font-size: 28px;
        }
      }
    }
  }

  // 忘记密码和保存密码
  .yongHuCaoZuo {
    padding: 15px 40px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 24px;
    }

    .rememberPassword {
      display: flex;
      align-items: center;
      color: #999999;
    }

    .forgetPassword {
      color: #b4272b;
    }
  }

  // 登录按钮
  .loginBtn {
    margin: 100px auto 20px;
    display: block;
    width: 570px;
    height: 70px;
    background: #b4272b;
    border-radius: 6px;
    font-size: 28px;
    font-weight: 400;
    color: #ffffff;
  }

  // 前往注册
  .toRegister {
    span {
      font-size: 24px;
      font-weight: 400;
      color: #999999;
    }

    .linkSpan {
      color: #b4272b;
      text-decoration: underline;
    }
  }
  .technicalSupport {
    margin: 59px 0 30px;
    font-size: 20px;
    color: #999999;
    line-height: 1;
    text-align: center;
  }
}
</style>
<style lang="scss">
.van-dialog.loginDialog {
  border-radius: 6px;
  width: 650px;

  .van-dialog__header {
    height: 0;
    padding: 0;
  }

  .closeBtn {
    width: 17px;
    height: 17px;
    position: absolute;
    top: 25px;
    right: 40px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 18px;
      height: 18px;
    }
  }

  .van-dialog__content {
  }

  .van-dialog__footer {
    display: none;
  }
}

.errorMsgDia {
  width: 500px;
  .errorMsgBox {
    padding: 0 35px;
    color: #999999;
    font-size: 28px;
    line-height: 34px;
    a {
      margin-top: 15px;
      display: block;
      word-break: break-all;
      color: #b4272b;
      margin-top: 10px;
      font-size: 28px;
      line-height: 34px;
    }
  }
}
</style>
