import axios from "axios";
import qs from "qs";
import store from "../store"; // 引入vuex
import messageJs from "@/utlis/notice.js";

//
// axios.defaults.withCredentials = true; // cookies是否发送
axios.interceptors.request.use(
 (config) => {
   // 获取token 添加headers发送请求
   let japan_user_token = localStorage.getItem("japan_user_token");
   if (japan_user_token) {
     config.headers["ClientToken"] = "Bearer " + japan_user_token;
   }
   config.headers["PageUrlTrace"] = location.href;
   // 转为formdata数据格式
   if (config.method === "post") {
     if (config.data) {
       if (config.url.indexOf("/api/cross") != -1) {
         config.headers["RequestApi"] = config.data.url;
         config.headers["Solution"] = "XYT";
         delete config.data.url;
       }
     }
     config.data = qs.stringify(config.data);
     config.headers["Content-Type"] = "application/x-www-form-urlencoded";
     return config;
   }
   return config;
 },
 (error) => {
   return Promise.reject(error);
 }
);

// 添加响应拦截器(**具体查看axios文档**)----------------------------------------------------------------
axios.interceptors.response.use(
 function (response) {
   // 对响应数据做点什么，允许在数据返回客户端前，修改响应的数据
   // 如果只需要返回体中数据，则如下，如果需要全部，则 return response 即可
   
   return response.data;
 },
 function (error) {
   // 对响应错误做点什么
   
   return Promise.reject(error);
 }
);

// 封装数据返回失败提示函数---------------------------------------------------------------------------
function errorState(response) {
  // 隐藏loading
  // 如果http状态码正常，则直接返回数据
  if (
   response &&
   (response.status === 200 ||
    response.status === 304 ||
    response.status === 400 ||
    response.status === 500)
  ) {
    // 如果不需要除了data之外的数据，可以直接 return response.data
    return response;
  } else {
    // alert(lang('数据获取错误'))
  }
}

// 封装数据返回成功提示函数---------------------------------------------------------------------------
function successState(res) {
  // 隐藏loading
  //统一判断后端返回的错误码(错误码与后台协商而定)
  // 如有错误的消息提示，弹出消息提示
  // if (res.message) {
  //     return Message.error(res.message)
  // }
}

// 封装axios--------------------------------------------------------------------------------------
export function apiAxios(method, url, params) {
  let httpDefault = {
    method: method, // 请求方式
    // baseURL: process.env.NODE_ENV !== "development" ? 'https://admin.rakumart.com' : '/api', // 默认请求地址 可能需要统一做跨域
    url: url,
    // `params` 是即将与请求一起发送的 URL 参数 `data` 是作为请求主体被发送的数据
    params: method === "get" || method === "DELETE" ? params : null,
    data: params,
    timeout: 100000,
  };
  // 注意**Promise**使用(Promise首字母大写)
  return new Promise((resolve, reject) => {
    axios(httpDefault)
    // 此处的.then属于axios
    .then((res) => {
      successState(res);
      if (res.code == 10001 || res.msg == "登录失效") {
        messageJs.error("再度ログインしてください");
        localStorage.removeItem("japan_user_token");
        store.commit("setcartCount", 0);
        store.commit("userInfo", null);
        // 打开登录弹窗
        store.commit("changeshowLoginDia", {
          success: () => {
            location.reload();
          },
          cancel: () => {
            location.href = "/";
          },
        });
        return false;
      }
      resolve(res);
    })
    .catch((response) => {
      errorState(response);
      reject(response);
    });
  });
}
