import { apiAxios } from "../axios";

import Europess from "../wangZhi";

let Europe = Europess + "/client/";
let comm_api = Europess + "/api";

export default {
  // 登录
  logins: (data) => apiAxios("post", Europe + "userLogin", data),
  // 忘记密码验证检验
  checkForgetPasswordCode: (data) =>
    apiAxios("post", Europe + "checkForgetPasswordCode", data),
  // 获取忘记密码的验证码
  getForgetPasswordVerificationCode: (data) =>
    apiAxios("post", Europe + "getForgetPasswordVerificationCode", data),
  // 忘记密码重设
  resetForgetPassword: (data) =>
    apiAxios("post", Europe + "resetForgetPassword", data),
  // 注册
  register: (data) => apiAxios("post", Europe + "userRegister", data),
  // 获取修改密码验证码
  getUpdatePasswordVerificationCode: (data) =>
    apiAxios("post", Europe + "user.getUpdatePasswordVerificationCode", data),
  // 修改密码，验证码校验
  checkUpdatePasswordValidationCode: (data) =>
    apiAxios("post", Europe + "user.checkUpdatePasswordValidationCode", data),
  // 注销账户
  unsetAccount: (data) => apiAxios("post", Europe + "user.unsetAccount", data),
  // 发送验证码
  sendRegisterCode: (data) =>
    apiAxios("post", Europe + "sendRegisterCode", data),
  //个人中心看板统计-头部
  headCountNum: (data) => apiAxios("post", Europe + "user.headCount", data),
  //子账户列表
  childrenUserList: (data) =>
    apiAxios("post", Europe + "children.childrenUserList", data),
  //注册子账户参数检验
  checkChildrenUserRegisterField: (data) =>
    apiAxios("post", Europe + "children.checkChildrenUserRegisterField", data),
  //注册子账户提交
  childrenUserRegister: (data) =>
    apiAxios("post", Europe + "children.childrenUserRegister", data),
  //编辑子账户信息提交
  childrenUserEdit: (data) =>
    apiAxios("post", Europe + "children.childrenUserEdit", data),
  //切换子账户状态【开启or禁用】
  childrenUserEditStatus: (data) =>
    apiAxios("post", Europe + "children.childrenUserEditStatus", data),
  //个人中心看板统计-底部统计
  contentCountNum: (data) =>
    apiAxios("post", Europe + "user.contentCount", data),
  //RAKUMART仓库处理-顶部数据的数量统计
  warehouseOrderCountNum: (data) =>
    apiAxios("post", Europe + "orderListDataNumV2", data),
  //RAKUMART仓库处理
  warehouseOrderList: (data) => apiAxios("post", Europe + "orderListV2", data),
  //物流与运输-顶部数量
  logisticsOrderCountNum: (data) =>
    apiAxios("post", Europe + "logisticsListDataNumV2", data),
  //物流与运输-列表
  logisticsOrderList: (data) =>
    apiAxios("post", Europe + "logisticsListV2", data),
  //订单列表侧边栏数量统计
  leftSidebarNum: (data) => apiAxios("post", Europe + "leftSidebarNumV2", data),
  // 比对验证码
  check_code: (data) => apiAxios("post", Europe + "checkRegisterCode", data),
  // 注册字段校验
  checkRegisterField: (data) =>
    apiAxios("post", Europe + "checkRegisterField", data),
  // 获取用户资料
  EuropegetUserInfo: (data) => apiAxios("post", Europe + "user.info", data),
  // 修改邮箱
  userEmailSave: (data) => apiAxios("post", Europe + "user.emailSave", data),
  // 修改手机号
  userMobileSave: (data) => apiAxios("post", Europe + "user.mobileSave", data),
  // 购物车列表
  goodsCartList: (data) =>
    apiAxios("post", Europe + "cart.goodsCartList", data),
  // 删除购物车商品
  goodsCartDelete: (data) =>
    apiAxios("post", Europe + "cart.goodsCartDelete", data),
  // 修改购物车商品数量
  goodsCartEdit: (data) =>
    apiAxios("post", Europe + "cart.goodsCartEdit", data),
  // 商品添加购物车
  goodsToCart: (data) => apiAxios("post", Europe + "cart.goodsToCart", data),
  // 核对账户信息
  forgetGetInfo: (data) => apiAxios("post", Europe + "forget.getInfo", data),
  // 查询购物车总条目数
  goodsToCartNum: (data) =>
    apiAxios("post", Europe + "cart.goodsCartNum", data),
  // 查询未读消息数量
  getTimMessageUnreadNum: (data) =>
    apiAxios("post", Europe + "user.getTimMessageUnreadNum", data),
  // 获取子账户权限
  childrenUserGetAuth: (data) =>
    apiAxios("post", Europe + "children.childrenUserGetAuth", data),
  // 编辑子账户权限
  childrenUserEditAuth: (data) =>
    apiAxios("post", Europe + "children.childrenUserEditAuth", data),
  // 找回密码（忘记密码重置
  forgetPasswordReset: (data) =>
    apiAxios("post", Europe + "forget.passwordReset", data),
  // 获取各状态订单条目数
  ordersSum: (data) => apiAxios("post", Europe + "order.orderListTables", data),
  // 获取各状态仓库条目数
  stockTableNum: (data) => apiAxios("post", Europe + "wms.stockTableNum", data),
  // 用户账号等级规则
  getUserLevelRoot: (data) =>
    apiAxios("post", Europe + "user.getUserLevelRoot", data),

  goodsCartPostscript: (data) =>
    apiAxios("post", Europe + "goodsCartPostscript", data),
  // 订单列表
  orderList: (data) => apiAxios("post", Europe + "order.orderList", data),
  // 地址列表
  userAddressList: (data) => apiAxios("post", Europe + "address.list", data),
  // 删除地址
  userAddressDelete: (data) =>
    apiAxios("post", Europe + "address.delete", data),
  // 保存地址（新增or编辑）
  userAddressUpdate: (data) => apiAxios("post", Europe + "address.save", data),
  // 第三方仓库地址
  thirdPartyWarehouseList: (data) =>
    apiAxios("post", Europe + "address.third_party", data),
  // 商品添加收藏
  favoriteGoodsAdd: (data) =>
    apiAxios("post", Europe + "favorite.goodsAdd", data),
  // 实时汇率
  commonExchangeRate: (data) =>
    apiAxios("post", comm_api + "/common/exchangeRate", data),
  //账户优惠券展示
  couponGet: (data) => apiAxios("post", Europe + "user.usableDiscount", data),
  //账户优惠券使用记录
  couponRecord: (data) => apiAxios("post", Europe + "user.pastDiscount", data),
  // 收藏夹标签列表
  favoriteTagList: (data) =>
    apiAxios("post", Europe + "favorite.tagList", data),
  //商品收藏Table数据量合计
  goodsTableNum: (data) =>
    apiAxios("post", Europe + "favorite.goodsTableNum", data),
  //商品库Table数据量合计
  inventoryTableNum: (data) =>
    apiAxios("post", Europe + "favorite.inventoryTableNum", data),
  // 收藏夹商品列表
  favoriteGoodsList: (data) =>
    apiAxios("post", Europe + "favorite.goodsList", data),
  //修改子账户密码
  childrenUserUpdatePassword: (data) =>
    apiAxios("post", Europe + "children.childrenUserUpdatePassword", data),
  // 商品收藏分配标签
  favoriteGoodsToTags: (data) =>
    apiAxios("post", Europe + "favorite.goodsToTags", data),
  // 店铺收藏分配标签
  favoriteShopToTags: (data) =>
    apiAxios("post", Europe + "favorite.shopToTags", data),
  // 删除标签
  favoriteTagDelete: (data) =>
    apiAxios("post", Europe + "favorite.tagDelete", data),
  //添加标签
  favoriteTagAdd: (data) => apiAxios("post", Europe + "favorite.tagAdd", data),
  //商品收藏移除
  favoriteGoodsDelete: (data) =>
    apiAxios("post", Europe + "favorite.goodsDelete", data),
  // 店铺收藏列表
  favoriteShopList: (data) =>
    apiAxios("post", Europe + "favorite.shopList", data),
  // 店铺收藏移除
  favoriteShopDelete: (data) =>
    apiAxios("post", Europe + "favorite.shopDelete", data),
  // 店铺收藏备注信息编辑
  favoriteShopNoteSave: (data) =>
    apiAxios("post", Europe + "favorite.shopNoteSave", data),
  // 商品库商品列表
  favoriteInventoryList: (data) =>
    apiAxios("post", Europe + "favorite.inventoryList", data),
  // 商品库商品移除
  favoriteInventoryDelete: (data) =>
    apiAxios("post", Europe + "favorite.inventoryDelete", data),
  // 商品库商品批量保存
  favoriteInventoryBatchSave: (data) =>
    apiAxios("post", Europe + "favorite.inventoryBatchSave", data),
  // 余额提现明细
  capitalCashOutList: (data) =>
    apiAxios("post", Europe + "user.balanceExtractDetail", data),
  // 出入金记录
  balanceChange: (data) =>
    apiAxios("post", Europe + "user.balanceChange", data),
  // 获取出入金类型&&交易方式
  getBalanceChangeTypes: (data) =>
    apiAxios("post", Europe + "user.getBalanceChangeTypes", data),
  // paypal充值余额
  payPalPayBalance: (data) =>
    apiAxios("post", Europe + "user.payPalPayBalance", data),
  // payPal支付结果查询
  payPalSuccess: (data) =>
    apiAxios("post", Europe + "user.payPalSuccess", data),
  // 订单支持使用的优惠券
  orderUsableDiscount: (data) =>
    apiAxios("post", Europe + "order.usableDiscount", data),
  // payPal支付订单
  orderPayPalPayOrder: (data) =>
    apiAxios("post", Europe + "order.payPalPayOrder", data),
  // payPal支付配送单
  porderPayPalPayOrder: (data) =>
    apiAxios("post", Europe + "porder.payPalPayOrder", data),
  // 余额提现申请
  capitalCashOutSend: (data) =>
    apiAxios("post", Europe + "user.balanceExtract", data),
  // 修改密码
  userPasswordSave: (data) =>
    apiAxios("post", Europe + "user.passwordSave", data),
  // 验证密码
  userCheckPassword: (data) =>
    apiAxios("post", Europe + "user.checkLoginUserPassword", data),
  // 问题产品列表
  problemGoodsOrderList: (data) =>
    apiAxios("post", Europe + "question.list", data),
  //  问题产品Table旁边的数量
  problemGoodsOrderNum: (data) =>
    apiAxios("post", Europe + "question.listTableNum", data),
  // 客户提交处理意见多条
  questionoverBatch: (data) =>
    apiAxios("post", Europe + "question.overBatch", data),
  // 客户反馈处理意见
  problemGoodsClientDeal: (data) =>
    apiAxios("post", Europe + "question.over", data),
  //  国内物流状态列表
  chinaLogisticsList: (data) =>
    apiAxios("post", Europe + "logistics.chinaLogistics.list", data),
  //  中国国内物流Table数据量合计
  chinaLogisticsListTableNum: (data) =>
    apiAxios("post", Europe + "logistics.chinaLogistics.listTableNum", data),
  //  清除商品缓存
  clearGoodsCache: (data) => apiAxios("post", Europe + "clearGoodsCache", data),
  //  获取修改手机号验证码
  getUpdateMobileVerificationCode: (data) =>
    apiAxios("post", Europe + "user.getUpdateMobileVerificationCode", data),
  //  普通仓库列表
  stockAutoList: (data) => apiAxios("post", Europe + "wms.stockAutoList", data),
  //  收费仓库列表
  stockFeeList: (data) => apiAxios("post", Europe + "wms.stockFeeList", data),
  //  出入金统计
  balanceStatistics: (data) =>
    apiAxios("post", Europe + "user.balanceStatistics", data),
  //  商品历史标签（订单详情通用）
  goodsTagLogs: (data) =>
    apiAxios("post", Europe + "favorite.goodsTagLogs", data),
  // 国内物流状态Table旁边的数量
  chinaLogisticsOrderNum: (data) =>
    apiAxios("post", Europe + "chinaLogistics.orderNum", data),
  //   修改头像
  userPortraitSave: (data) => Europe + "user.uploadPortraitImg",
  // 创建新配送单
  storageSendDeliver: (data) =>
    apiAxios("post", Europe + "porder.porderCreate", data),
  // 配送单列表
  storageDeliverList: (data) =>
    apiAxios("post", Europe + "porder.porderList", data),
  // 配送单详情
  storageDeliverDetail: (data) =>
    apiAxios("post", Europe + "porder.porderDetail", data),
  // 配送单状态Table旁边的数量
  storageDeliverNum: (data) =>
    apiAxios("post", Europe + "porder.porderListTables", data),
  // 临时配送单删除
  storageTampDeliverDelete: (data) =>
    apiAxios("post", Europe + "porder.porderDeleteTemporary", data),
  // 配送单-评价
  porderEvaluate: (data) => apiAxios("post", Europe + "porder.evaluate", data),
  // 国际物流状态Table旁边的数量
  internationalLogisticsOrderNum: (data) =>
    apiAxios("post", Europe + "internationalLogistics.orderNum", data),
  // 余额支付开通VIP
  balancePayVip: (data) =>
    apiAxios("post", Europe + "user.balancePayVip", data),
  // vip 服务列表
  uservipList: (data) => apiAxios("post", Europe + "user.vipList", data),
  // 客户签收配送单
  internationalLogisticsClientReceive: (data) =>
    apiAxios("post", Europe + "porder.porderReceive", data),
  // 银行汇款充值余额
  capitalBankUpBalance: (data) =>
    apiAxios("post", Europe + "user.bankPayBalance", data),
  // 获取未查看的通知
  noticeUnreadNotice: (data) =>
    apiAxios("post", Europe + "notice.unreadNotice", data),
  // 标记已读
  noticeReadNotice: (data) =>
    apiAxios("post", Europe + "notice.readNotice", data),
  // 验证码校验(修改手机号)
  checkUpdateMobileValidationCode: (data) =>
    apiAxios("post", Europe + "user.checkUpdateMobileValidationCode", data),
  // 查看通知列表
  noticeClientNoticeList: (data) =>
    apiAxios("post", Europe + "notice.clientNoticeList", data),
  // 批量删除通知
  noticeBatchDelete: (data) =>
    apiAxios("post", Europe + "notice.batchDelete", data),
  // 支付授权
  payAuthorized: (data) =>
    apiAxios("post", Europe + "allow.payAuthorized", data),
  // 装箱授权
  encasementAuthorized: (data) =>
    apiAxios("post", Europe + "allow.encasementAuthorized", data),
  // 设置默认地址
  setDefaultAddress: (data) =>
    apiAxios("post", Europe + "address.setDefault", data),
  // 客户提交反馈
  addFeedback: (data) =>
    apiAxios("post", Europe + "feedback.addFeedback", data),
  //订单导出-临时token
  getOrderDetailDownloadToken: (data) =>
    apiAxios("post", Europe + "download.orderDetailDownloadToken", data),
  // 座机和注意事项修改(无修改风险的)
  updateAutoInfo: (data) =>
    apiAxios("post", Europe + "user.updateAutoInfo", data),
  //获取修改邮箱验证码
  getUpdateEmailVerificationCode: (data) =>
    apiAxios("post", Europe + "user.getUpdateEmailVerificationCode", data),
  //验证码校验(修改邮箱地址)
  checkUpdateEmailValidationCode: (data) =>
    apiAxios("post", Europe + "user.checkUpdateEmailValidationCode", data),
  //通过验证码修改邮箱地址
  updateEmailViaValidationCode: (data) =>
    apiAxios("post", Europe + "user.updateEmailViaValidationCode", data),
  //通过旧密码设置新密码
  updatePasswordViaOldPassword: (data) =>
    apiAxios("post", Europe + "user.updatePasswordViaOldPassword", data),
  //通过验证码修改新手机号
  updateMobileViaValidationCode: (data) =>
    apiAxios("post", Europe + "user.updateMobileViaValidationCode", data),
  //通过密码设置新邮箱
  updateEmailViaOldPassword: (data) =>
    apiAxios("post", Europe + "user.updateEmailViaOldPassword", data),
  //通过密码设置新手机号
  updateMobileViaOldPassword: (data) =>
    apiAxios("post", Europe + "user.updateMobileViaOldPassword", data),
  //通过验证码修改新密码
  updatePasswordViaValidationCode: (data) =>
    apiAxios("post", Europe + "user.updatePasswordViaValidationCode", data),
  //通过验证码修改新密码
  userTimGroupCheck: (data) =>
    apiAxios("post", comm_api + "/common/userTimGroupCheck", data),
  //设置聊天消息为已读
  setTimMessageRead: (data) =>
    apiAxios("post", Europess + "/mobile/user.setTimMessageRead", data),
  //快捷发送TIM消息
  sendTimMessage: (data) =>
    apiAxios("post", Europess + "/mobile/user.sendTimMessage", data),
  // 获取图形验证码
  clientcodeImg: (data) => apiAxios("get", Europess + "/client/codeImg", data),
  // 跨境版-店铺全部商品
  sellerOfferList: (data) =>
    apiAxios("post", Europess + "/client/sellerOfferList", data),
  // 获取老版1688商品店铺名
  saleShopName: (data) =>
    apiAxios("post", Europess + "/client/saleShopName", data),
  // 礼物兑换
  giftCodeExchange: (data) =>
    apiAxios("post", Europess + "/client/gift.giftCodeExchange", data),
};
