<template>
  <div>
    <van-dialog v-model:show="show" class="questionnaireDialog">
      <template #title>
        <div class="diaHead">
          <div class="closeBtn" @click="close">
            <div></div>
            <img :src="require('@/assets/icon/chaLogin.png')" alt="" />
          </div>
        </div>
      </template>
      <van-image
        lazy-load
        class="questionnaireImg"
        src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/649d46de4217a.png"
        @click="
          $fun.routerToPage(
            '/questionnaire?id=' + questionnaireInfo.questionnaire_id,
            true
          )
        "
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
    </van-dialog>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const show = ref(false);
const questionnaireInfo = ref({});
//------------------------ methods -------------------------------------
// 判断用户有没有填写调查问卷
const getSurvey = () => {
  if (
    localStorage.getItem("japan_user_token") &&
    !sessionStorage.getItem("firstOpenSurvey")
  ) {
    sessionStorage.setItem("firstOpenSurvey", true);
    proxy.$api.mustParticipateQuestionnaire().then((result) => {
      if (result.data.length) {
        show.value = true;
      }
      questionnaireInfo.value = result.data[0];
    });
  }
};
const close = () => {
  show.value = false;
};
//------------------------ pageLoad -------------------------------------
getSurvey();
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.questionnaireImg {
  min-height: 300px;
}
</style>
<style lang="scss">
.van-dialog.questionnaireDialog {
  border-radius: 6px;
  width: 650px;

  .van-dialog__header {
    height: 0;
    padding: 0;
  }

  .closeBtn {
    width: 17px;
    height: 17px;
    position: absolute;
    top: 25px;
    right: 40px;
    z-index: 3;
  }

  .van-dialog__content {
  }

  .van-dialog__footer {
    display: none;
  }
}
</style>
