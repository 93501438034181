(function () {
  if (typeof EventTarget !== "undefined") {
    const func = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function (type, fn, capture) {
      this.func = func;
      if (typeof capture !== "boolean") {
        capture = capture || {};
        capture.passive = false;
      }
      this.func(type, fn, capture);
    };
  }
})();
// 用来去除自定义指令directive里阻止元素默认事件的报错
// https://blog.csdn.net/weixin_44846945/article/details/134304340
