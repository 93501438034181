import index from "./apis/index";
import order from "./apis/order";
import user from "./apis/user.js";
import product from "./apis/product.js";
import opublic from "./apis/public";
export default {
  ...index,
  ...order,
  ...user,
  ...product,
  ...opublic,
};
