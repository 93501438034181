<template>
  <van-dialog
    v-model:show="show"
    show-cancel-button
    :className="
      parameter.isprompt ? 'customizeDia ispromptCss' : 'customizeDia'
    "
    :showCancelButton="false"
    @close="closeMethods"
    :closeOnClickOverlay="parameter.closeOnClickOverlay"
    :showConfirmButton="false"
    :lockScroll="false"
    :overlay-class="parameter['overlay-class']"
  >
    <template #title>
      <div class="diaHead">
        <span>{{ parameter.title }}</span>
        <div class="closeBtn" @click="close">
          <div></div>
          <van-icon name="cross" />
        </div>
      </div>
    </template>
    <div class="diaContent">
      <div
        class="diaContentText"
        :class="{ textLeft: parameter.textAlign == 'left' }"
        v-if="parameter.text"
      >
        <div v-html="parameter.text"></div>
      </div>
      <slot></slot>
      <div class="btnGroup">
        <button
          v-if="parameter.cancelBtn"
          class="cancel"
          @click="cancelMethods()"
        >
          {{ parameter.cancelBtn }}
        </button>
        <button
          v-if="parameter.successBtn"
          class="success"
          @click="successMethods()"
        >
          {{ parameter.successBtn }}
        </button>
      </div>
    </div>
  </van-dialog>
</template>
<script>
import { Dialog } from "vant";
export default {
  props: {
    config: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },
  data() {
    return {
      show: false, //控制是否显示弹窗
      parameter: {
        width: "", //弹窗宽
        title: this.$fanyi("提示"), //标题文本
        text: "", //提示文本
        closeOnClickOverlay: false, //是否可以点击遮罩层关闭
        successBtn: null, //成功按钮文本
        cancelBtn: null, //关闭按钮文本
        textAlign: "center", //提示文本排序风格
        successMethods: () => {}, //点击成功按钮的事件
        cancelMethods: () => {}, //点击关闭按钮事件
        closeMethods: () => {}, //所有关闭弹窗事件
        befoCloseMethods: "noMethods", //所有关闭弹窗前事件
        isprompt: false, //是否是提示弹窗(仅样式变化)
        "overlay-class": "", //遮罩层名字
      }, //传入组件参数
    };
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  computed: {},
  created() {},
  methods: {
    // 挂载节点
    container() {
      return document.body;
    },
    open(change) {
      // 初始化设置
      this.parameter.isprompt = false;
      for (const i in this.config) {
        if (this.config[i]) {
          const element = this.config[i];
          this.parameter[i] = element;
        }
      }
      for (const i in change) {
        if (change[i]) {
          const element = change[i];
          this.parameter[i] = element;
        }
      }
      this.show = true;
    },
    // 点击关闭按钮事件
    close() {
      if (this.parameter.befoCloseMethods != "noMethods") {
        // 需要自己在befoCloseMethods里写关闭事件,例:this.$refs.dialog.show = false;
        this.parameter.befoCloseMethods();
      } else {
        this.show = false;
      }
      // this.parameter.closeMethods(); 因为在van组件里设置了关闭事件所以这句不用写,在关闭弹窗时van组件会调用
    },
    // 仅关闭弹窗和执行closeMethods
    safeClose() {
      this.show = false;
      // return false;
    },
    successMethods() {
      // console.log("成功事件");
      if (!this.parameter.successMethods()) {
        this.close();
      } else {
        this.parameter.successMethods();
      }
    },
    cancelMethods() {
      if (!this.parameter.cancelMethods()) {
        this.close();
      } else {
        this.parameter.cancelMethods();
        this.close();
      }
    },
    closeMethods() {
      this.parameter.closeMethods();
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
</style>
<style lang="scss">
.diaHead {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 30px;
    text-align: center;
  }
  .closeBtn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    :deep(.van-icon-cross) {
      font-size: 24px;
      color: #dfdfdf;
    }
  }
}
.van-dialog.customizeDia {
  border-radius: 10px;
  border: 1px solid #e8e8e8;

  .van-dialog__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    line-height: 24px;
    top: 50%;
    padding: 0px;
  }
  .diaContent {
    max-height: 70vh;
    overflow: auto;
    .diaContentText {
      margin: 0 auto;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: #999999;
    }
    .diaContentText.textLeft {
      text-align: left;
    }
    .btnGroup {
      margin-top: 40px;
      margin-bottom: 30px;
      text-align: center;
      display: flex;
      justify-content: center;
      button {
        min-width: 160px;
        min-height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background: #b4272b;
        border-radius: 6px;
        font-size: 28px;
        color: #ffffff;
        &:nth-child(2) {
          margin-left: 30px;
        }
      }
      .cancel {
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        color: black;
      }
    }
  }
}
.van-dialog.ispromptCss {
  width: 500px;
}
</style>
