/**
 * 返回年月日
 * @export
 * @param {Date} date
 * @param {string} [splitor='-']
 * @returns
 */
export function getDateMonth(date, splitor = "-") {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}${splitor}${addZeroPrefix(month)}`;
}
/**
 * 返回年月
 * @export
 * @param {Date} date
 * @param {string} [splitor='-']
 * @returns
 */
export function getDate(date, splitor = "-") {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}${splitor}${addZeroPrefix(month)}${splitor}${addZeroPrefix(
    day
  )}`;
}

/**
 * 返回时分秒/时分
 * @export
 * @param {*} date
 * @param {boolean} [withSecond=false]
 * @returns
 */
export function getTime(date, withSecond = false) {
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  return withSecond
    ? `${addZeroPrefix(hour)}:${addZeroPrefix(minute)}:${addZeroPrefix(second)}`
    : `${hour}:${addZeroPrefix(minute)}`;
}

export function getFullDate(date) {
  return `${getDate(date)} ${getTime(date)}`;
}

export function isToday(date) {
  return date.toDateString() === new Date().toDateString();
}

/**
 * 个位数，加0前缀
 * @param {*} number
 * @returns
 */
function addZeroPrefix(number) {
  return number < 10 ? `0${number}` : number;
}

// 当前月
export const getCurrentMonth = () => {
  var date = new Date();
  date.setDate(1);
  var month = parseInt(date.getMonth() + 1);
  var day = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }

  return date.getFullYear() + "-" + month;
};
// 判断日期是否过期
export const pastDue = function (time) {
  let now = new Date();
  let dbtime = new Date(time);

  if (
    now.getFullYear() == dbtime.getFullYear() &&
    now.getMonth() == dbtime.getMonth() &&
    now.getDate() == dbtime.getDate()
  ) {
    return false;
  }

  if (now > dbtime) {
    // 已过期
    return true;
  } else {
    return false;
  }
};

// 判断日期是否是今天
export const sameDate = function (time) {
  let now = new Date();
  let dbtime = new Date(time);
  now = now.getFullYear() + "-" + now.getMonth() + "-" + now.getDate();
  dbtime =
    dbtime.getFullYear() + "-" + dbtime.getMonth() + "-" + dbtime.getDate();
  if (now == dbtime) {
    return true;
  } else {
    return false;
  }
};
// 当前日期指定分隔符
export const getCurrentDayFGF = (val) => {
  if (val.time) {
    var date = new Date(val.time);
  } else {
    var date = new Date();
  }
  if (!val.fgf) {
    val.fgf = "-";
  }
  if (date == "Invalid Date") {
    const regehms = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    const regehm = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
    const str = val.time;
    const isValidFormat = regehms.test(str) || regehm.test(str);
    if (isValidFormat) {
      // return val.time.substr(19, 2) || '00'
      // return val.time.substr(5, 2);
      // console.log("字符串符合 2023-10-02 11:11:11 格式");
      let year = val.time.substr(0, 4);
      let month = val.time.substr(5, 2);
      let day = val.time.substr(8, 2);
      return year + val.fgf + month + val.fgf + day;
    } else {
      // console.log("字符串不符合 2023-10-02 11:11:11 格式");
      return val.time;
    }
  }
  //console.log(val.time, 5654879);
  //console.log(date);
  var month = parseInt(date.getMonth() + 1);
  var day = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }

  return date.getFullYear() + val.fgf + month + val.fgf + day;
};
// 当前日期 timer.format 例:yyyy-mm-dd hh-min-ss==年-月-日 时-分-秒
export const specifiedFormatDate = (timer) => {
  var date = new Date();
  if (timer.date) {
    var date = new Date(timer.date);
  }
  // 苹果浏览器有可能会识别不了"年-月-日 时:分:秒"格式,这个时候需要使用字符串方法判断
  if (date == "Invalid Date") {
    const regehms = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    const regehm = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
    const str = timer.date;
    const isValidFormat = regehms.test(str) || regehm.test(str);
    if (isValidFormat) {
      // return timer.date.substr(19, 2) || '00'
      // return timer.date.substr(5, 2);
      // console.log("字符串符合 2023-10-02 11:11:11 格式");
      let year = timer.date.substr(0, 4);
      let month = timer.date.substr(5, 2);
      let day = timer.date.substr(8, 2);
      let hour = timer.date.substr(11, 2); //得到小时
      let minu = timer.date.substr(14, 2); //得到分钟
      let sec = timer.date.substr(17, 2) || "00"; //得到秒
      if (timer.format) {
        return timer.format
          .replace("yyyy", year)
          .replace("mm", month)
          .replace("dd", day)
          .replace("hh", hour)
          .replace("min", minu)
          .replace("ss", sec);
      }
      return date.getFullYear() + "-" + month + "-" + day;
    } else {
      // console.log("字符串不符合 2023-10-02 11:11:11 格式");
      return timer.date;
    }
  }
  var month = parseInt(date.getMonth() + 1);
  var day = date.getDate();
  var hour = date.getHours(); //得到小时
  var minu = date.getMinutes(); //得到分钟
  var sec = date.getSeconds(); //得到秒

  if (month < 10) {
    month = "0" + month.toString() || "0";
  }
  if (day < 10) {
    day = "0" + day.toString() || "0";
  }
  if (hour < 10) hour = "0" + hour.toString() || "0";
  if (minu < 10) minu = "0" + minu.toString() || "0";
  if (sec < 10) sec = "0" + sec.toString() || "0";

  if (timer.format) {
    return timer.format
      .replace("yyyy", date.getFullYear())
      .replace("mm", month)
      .replace("dd", day)
      .replace("hh", hour)
      .replace("min", minu)
      .replace("ss", sec);
  }
  return date.getFullYear() + "-" + month + "-" + day;
};

// 当前日期
export const getCurrentDay = (timer) => {
  var date = new Date();
  if (timer) {
    var date = new Date(timer);
  }
  // 苹果浏览器有可能会识别不了"年-月-日 时:分:秒"格式,这个时候需要使用字符串方法判断
  if (date == "Invalid Date") {
    // 只有年-月-日+时间才会出现问题,所以只用两个正则表达式匹配
    const regehms = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    const regehm = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
    const str = timer;
    const isValidFormat = regehms.test(str) || regehm.test(str);
    if (isValidFormat) {
      let year = timer.substr(0, 4);
      let month = timer.substr(5, 2);
      let day = timer.substr(8, 2);
      return year + "-" + month + "-" + day;
    } else {
      // console.log("字符串不符合 2023-10-02 11:11:11 格式");
      return timer;
    }
  }
  var month = parseInt(date.getMonth() + 1);
  var day = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }

  return date.getFullYear() + "-" + month + "-" + day;
};

// 当前月的上一个月
export const getCurrentMonth_last = () => {
  var date = new Date();
  date.setDate(1);
  var month = parseInt(date.getMonth());
  var day = date.getDate();
  var year = date.getFullYear();
  if (month < 10) {
    month = "0" + month;
  }
  if (month == "00") {
    month = 12;
    year--;
  }
  if (day < 10) {
    day = "0" + day;
  }

  return year + "-" + month;
};

// 当前月份第一天
export const getCurrentMonthFirst = () => {
  var date = new Date();
  date.setDate(1);
  var month = parseInt(date.getMonth() + 1);
  var day = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return date.getFullYear() + "-" + month + "-" + day;
};

// 当前月份最后一天
export const getCurrentMonthLast = () => {
  var date = new Date();
  var currentMonth = date.getMonth();
  var nextMonth = ++currentMonth;
  var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
  var oneDay = 1000 * 60 * 60 * 24;
  var lastTime = new Date(nextMonthFirstDay - oneDay);
  var month = parseInt(lastTime.getMonth() + 1);
  var day = lastTime.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return date.getFullYear() + "-" + month + "-" + day;
};

// 指定月份第一天
export const getCurrentMonthFirst_zd = (date) => {
  if (date) {
    var date = new Date(date);
  } else {
    var date = new Date();
  }

  date.setDate(1);
  var month = parseInt(date.getMonth() + 1);
  var day = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return date.getFullYear() + "-" + month + "-" + day;
};
// 对比两个时间
export const duibishijian = function (PlanStartTime, PlanEndTime) {
  const _PlanEndTime = new Date(PlanEndTime);
  const _PlanStartTime = new Date(PlanStartTime);
  if (_PlanEndTime.getTime() > _PlanStartTime.getTime()) {
    return false;
  } else {
    return true;
  }
};
// 指定月份最后一天
export const getCurrentMonthLast_zd = (date) => {
  if (date) {
    var date = new Date(date);
  } else {
    var date = new Date();
  }
  var currentMonth = date.getMonth();
  var nextMonth = ++currentMonth;
  var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
  var oneDay = 1000 * 60 * 60 * 24;
  var lastTime = new Date(nextMonthFirstDay - oneDay);
  var month = parseInt(lastTime.getMonth() + 1);
  var day = lastTime.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return date.getFullYear() + "-" + month + "-" + day;
};
/**
 *获取当前时间
 *format=1精确到天
 *format=2精确到分
 */
export const getCurrentDate = (format) => {
  var now = new Date();
  var year = now.getFullYear(); //得到年份
  var month = now.getMonth(); //得到月份
  var date = now.getDate(); //得到日期
  var day = now.getDay(); //得到周几
  var hour = now.getHours(); //得到小时
  var minu = now.getMinutes(); //得到分钟
  var sec = now.getSeconds(); //得到秒
  month = month + 1;
  if (month < 10) month = "0" + month;
  if (date < 10) date = "0" + date;
  if (hour < 10) hour = "0" + hour;
  if (minu < 10) minu = "0" + minu;
  if (sec < 10) sec = "0" + sec;
  var time = "";
  //精确到天
  if (format == 1) {
    time = year + "-" + month + "-" + date;
  }
  //精确到分
  else if (format == 2) {
    time =
      year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;
  }
  return time;
};

// 获取指定日期之间的天数
export const daysDistance = (date1, date2) => {
  //parse() 是 Date 的一个静态方法 , 所以应该使用 Date.parse() 来调用，而不是作为 Date 的实例方法。返回该日期距离 1970/1/1 午夜时间的毫秒数
  date1 = Date.parse(date1);
  date2 = Date.parse(date2);
  //计算两个日期之间相差的毫秒数的绝对值
  var ms = Math.abs(date2 - date1);
  //毫秒数除以一天的毫秒数,就得到了天数
  var days = Math.floor(ms / (24 * 3600 * 1000));
  return days;
};

export const getDateDifference = (targetDate) => {
  const currentDate = new Date(getCurrentDay(new Date())); // 当前日期 用getCurrentDay是为了去除后面的时间,让天数准确
  const target = new Date(targetDate); // 指定日期
  // 计算差值，单位为毫秒
  const timeDifference = target - currentDate;

  // 转换为天数，1天 = 24小时 * 60分钟 * 60秒 * 1000毫秒
  const dayDifference = timeDifference / (1000 * 3600 * 24);

  return Math.floor(dayDifference); // 返回天数，向下取整
};
