<!-- loading组件 -->
<template>
  <div id="loadingpageId">
    <div class="loadingBg">
      <van-loading color="#b4272b" size="50" />
    </div>
  </div>
</template>
<script>
import { Loading } from "vant";
import { onMounted } from "vue";
export default {
  props: {},
  setup(props) {
    onMounted(() => {});
  },
  data() {
    return {};
  },
  components: {
    [Loading.name]: Loading,
  },
  computed: {},
  created() {},
  mounted() {
    // document
    //   .getElementById(loadingpageId)
    //   .addEventListener("click", function (event) {
    //     // 阻止默认行为
    //     event.preventDefault();
    //     event.stopPropagation();
    //   });
  },
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.loadingBg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 3010;
  pointer-events: none;
  background-color: rgba($color: #fff, $alpha: 0.7);
  justify-content: center;
  align-items: center;
}
.unshow {
  display: none;
}
</style>
