import store from "../store/index";
import LiData from "./Lilanguages";

let langs = [
  ...LiData,
  {
    key: "售后编号",
    value: "售后编号",
  },
  {
    key: "出入金记录",
    value: "出入金记录",
  },
  {
    key: "具体说明",
    value: "具体说明",
  },
  {
    key: "费用变更",
    value: "费用变更",
  },
  {
    key: "出货商品信息",
    value: "出货商品信息",
  },
  {
    key: "费用调整名义",
    value: "费用调整名义",
  },
  {
    key: "具体赔款信息",
    value: "具体赔款信息",
  },
  {
    key: "反馈问题图片",
    value: "反馈问题图片",
  },
  {
    key: "售后原因",
    value: "售后原因",
  },
  {
    key: "出货数量",
    value: "出货数量",
  },
  {
    key: "售后信息",
    value: "售后信息",
  },
  {
    key: "售后单号",
    value: "售后单号",
  },
  {
    key: "售后详情",
    value: "售后详情",
  },
  {
    key: "查看详情",
    value: "查看详情",
  },
  {
    key: "赔款金额",
    value: "赔款金额",
  },
  {
    key: "售后完成时间",
    value: "售后完成时间",
  },
  ///////////////////////////////////////////////
  {
    key: "刷新",
    value: "リセット",
  },
  {
    key: "日本公司",
    value: "日本会社",
  },
  {
    key: "关于rakumart",
    value: "rakumartについて",
  },
  {
    key: "用户指南",
    value: "初めての方",
  },
  {
    key: "国际运费详情",
    value: "国際送料詳細",
  },
  {
    key: "关于手续费",
    value: "手数料について",
  },
  {
    key: "附加服务费用说明",
    value: "オプション費用",
  },
  {
    key: "再次订购",
    value: "注文にする",
  },
  {
    key: "定额会员",
    value: "定額会員",
  },
  {
    key: "是否登录",
    value: "ログインしていますか？",
  },
  {
    key: "是否前往登录?",
    value: "ログインに進みますか？",
  },
  {
    key: "前往登录",
    value: "ログインへ進む",
  },
  {
    key: "提示",
    value: "注意",
  },
  {
    key: "收费会员说明",
    value: "有料会員の説明",
  },
  {
    key: "其他说明",
    value: "その他の説明",
  },
  {
    key: "请输入关键词搜索",
    value: "キーワードを入力して検索してください",
  },
  {
    key: "请上传图片",
    value: "写真をアップロードしてください",
  },
  {
    key: "记住密码",
    value: "パスワードを記憶する",
  },
  {
    key: "数量",
    value: "数量",
  },
  {
    key: "小计",
    value: "小計",
  },
  {
    key: "提出",
    value: "提出",
  },
  {
    key: "未选择操作的商品",
    value: "操作対象の商品が選択されていません",
  },
  {
    key: "该商品将移出购物车",
    value: "この商品はカートから削除されます",
  },
  {
    key: "提醒",
    value: "注意",
  },
  {
    key: "移除成功",
    value: "削除が成功しました",
  },
  {
    key: "一个订单最多添加100个商品",
    value: "1つの注文には最大100個の商品を追加できます",
  },
  {
    key: "确认",
    value: "確認",
  },
  {
    key: "元",
    value: "元",
  },
  {
    key: "有效期",
    value: "有効期限",
  },
  {
    key: "提货数量不能为零",
    value: "商品の数量は0にすることはできません",
  },
  {
    key: "是否将选中的商品移除配送单详情",
    value: "選択した商品を配送詳細から削除しますか？",
  },
  {
    key: "在rakumart库数量",
    value: "rakumartの在庫数",
  },
  {
    key: "已提出数",
    value: "すでに提出された数",
  },
  {
    key: "提出数不能超过可提出数",
    value: "提出できる数を超えてはいけません",
  },
  {
    key: "发送可能数",
    value: "発送可能数",
  },
  {
    key: "国际运费",
    value: "国際運賃",
  },
  {
    key: "其他费用",
    value: "その他",
  },
  {
    key: "氏名",
    value: "氏名",
  },
  {
    key: "公司名",
    value: "会社名",
  },
  {
    key: "住所",
    value: "住所",
  },
  {
    key: "邮编",
    value: "郵便番号",
  },
  {
    key: "进口商",
    value: "輸入業者",
  },
  {
    key: "商品管理编码",
    value: "商品コード",
  },
  {
    key: "请输入商品管理编码",
    value: "商品管理コードを入力してください",
  },
  {
    key: "标签编码",
    value: "ラベル番号",
  },
  {
    key: "请输入标签编码",
    value: "ラベルコードを入力してください",
  },
  {
    key: "保存",
    value: "保存",
  },
  {
    key: "提货数量不能超过可提货数量",
    value: "提貨数量は在庫数を超えることはできません",
  },
  {
    key: "提货数量不能为0",
    value: "引き出し数量は0にすることはできません",
  },
  {
    key: "已使用免手续费券",
    value: "手数料無料クーポンを使用済み",
  },
  {
    key: "物流方式",
    value: "物流方法",
  },
  {
    key: "手机号",
    value: "携帯電話番号",
  },
  {
    key: "电子邮箱",
    value: "電子メール",
  },
  {
    key: "请输入新密码",
    value: "新しいパスワードを入力してください",
  },
  {
    key: "邮箱错误",
    value: "メールアドレスが間違っています",
  },
  {
    key: "请输入正确的手机号码",
    value: "正しい携帯電話番号を入力してください",
  },
  {
    key: "请输入邮箱",
    value: "メールアドレスを入力してください",
  },
  {
    key: "请先输入密码",
    value: "パスワードを入力してください",
  },
  {
    key: "姓氏",
    value: "苗字",
  },
  {
    key: "请输入您正常使用的邮箱地址，将用于接收重要的验证和通知",
    value:
      "正常に使用しているメールアドレスを入力してください。重要な検証と通知を受け取るために使用されます",
  },
  {
    key: "请输入1位-24位字符，允许特殊符号",
    value: "1〜24文字の文字を入力してください。特殊文字が使用できます",
  },
  {
    key: "请填写正确物流信息",
    value: "正しい物流情報を入力してください",
  },
  {
    key: "物流信息",
    value: "追跡情報",
  },

  {
    key: "名称",
    value: "名前",
  },
  {
    key: "请输入正常使用的手机号，将用于接收重要的验证和通知",
    value:
      "正常に使用している携帯電話番号を入力してください。重要な検証と通知を受け取るために使用されます",
  },
  {
    key: "请输入 6 到 16 位的密码,可由字母数字及特殊符号组成，不支持空格",
    value:
      "6〜16桁のパスワードを入力してください。文字、数字、特殊文字を使用できます。空白はサポートされていません",
  },
  {
    key: "确认无误，立即注册",
    value: "確認して即座に登録する",
  },
  {
    key: "返回上一步",
    value: "前のステップに戻る",
  },
  {
    key: "完成注册",
    value: "登録完了",
  },
  {
    key: "账号注册完成，非常感谢您的注册",
    value:
      "アカウントの登録が完了しました。ご登録いただき、誠にありがとうございます。",
  },
  {
    key: "请输入2位-24位字符，允许特殊符号，不能使用空格",
    value:
      "2〜24文字の文字を入力してください。特殊文字が使用できますが、スペースは使用できません。",
  },
  {
    key: "当前登录名已被注册，请使用其他登录名进行注册。",
    value:
      "現在のログイン名は既に登録されています。他のログイン名を使用して登録してください。",
  },
  {
    key: "邮箱格式不正确，请输入正确的邮箱格式",
    value:
      "メールアドレスの形式が正しくありません。正しいメールアドレスの形式を入力してください。",
  },
  {
    key: "当前邮箱已被注册，请使用其他邮箱进行注册。",
    value:
      "現在のメールアドレスは既に登録されています。他のメールアドレスを使用して登録してください。",
  },
  {
    key: "您输入的手机号格式不正确，请输入正确的手机号",
    value:
      "入力された携帯電話番号の形式が正しくありません。正しい携帯電話番号を入力してください。",
  },
  {
    key: "当前手机号已被注册，请使用其他手机号进行注册。",
    value:
      "現在の携帯電話番号は既に登録されています。他の携帯電話番号を使用して登録してください。",
  },
  {
    key: "输入密码和前一次输入的不一致",
    value: "パスワードが前回の入力と一致しません",
  },
  {
    key: "操作失败",
    value: "操作が失敗しました",
  },
  {
    key: "使用条款和隐私政策还没有被同意",
    value: "利用規約とプライバシーポリシーに同意されていません",
  },
  {
    key: "当前订单所有商品已删除，重新提出订单会直接取消，请确认",
    value:
      "現在の注文のすべての商品は削除されました。注文を再提出すると直接キャンセルされますので、ご確認ください",
  },
  {
    key: "商品查找方法",
    value: "商品検索方法",
  },
  {
    key: "下单方法",
    value: "注文方法",
  },
  {
    key: "优惠券使用方法",
    value: "クーポンの使用方法",
  },
  {
    key: "提出发货方法",
    value: "配送方法を選択してください",
  },
  {
    key: "注册方法",
    value: "登録方法",
  },
  {
    key: "进口流程",
    value: "仕入れの流れ",
  },
  {
    key: "操作成功",
    value: "そうさ せいこう",
  },
  {
    key: "提出订单",
    value: "注文を提出する",
  },
  {
    key: "请至少添加一个商品",
    value: "少なくとも1つの商品を追加してください",
  },
  {
    key: "订单已保存成功，可前往会员中心-订单列表查看",
    value: "注文は正常に保存されました。マイページの注文リストで確認できます",
  },
  {
    key: "订单已提交。",
    value: "注文書提出されました。",
  },
  {
    key: "追加",
    value: "追加",
  },
  {
    key: "附加服务选择",
    value: "追加サービスの選択",
  },
  {
    key: "明细",
    value: "詳細",
  },
  {
    key: "番号",
    value: "番号",
  },
  {
    key: "手续费比例",
    value: "手数料率",
  },
  {
    key: "国内运费",
    value: "中国国内送料",
  },
  {
    key: "中国运费",
    value: "中国送料",
  },
  {
    key: "付款时汇率",
    value: "レート",
  },
  {
    key: "账户当前可用余额",
    value: "アカウントの現在の利用可能残高",
  },
  {
    key: "付款后剩余",
    value: "支払い後の残高",
  },
  {
    key: "銀行名",
    value: "銀行名",
  },
  {
    key: "支店番号",
    value: "支店番号",
  },
  {
    key: "支店名",
    value: "支店名",
  },
  {
    key: "預金種目",
    value: "預金種目",
  },
  {
    key: "口座名義",
    value: "口座名義",
  },
  {
    key: "选择日期",
    value: "日付を選択",
  },

  {
    key: "支付失败",
    value: "支払いに失敗しました",
  },
  {
    key: "余额支付",
    value: "残高支払い",
  },
  {
    key: "请更换其他支付方式",
    value: "他の支払い方法を選択してください",
  },
  {
    key: "原url来源",
    value: "元URL確認",
  },
  {
    key: "链接已复制",
    value: "リンクがコピーされました",
  },
  {
    key: "支付成功",
    value: "支払いが成功しました",
  },
  {
    key: "件",
    value: "件",
  },
  {
    key: "当前商品已失效或已下架，无法继续购买，请查看其他商品。",
    value:
      "現在の商品は無効または取り下げられており、購入を続行できません。他の商品をご覧ください。",
  },
  {
    key: "提现记录",
    value: "出金履歴",
  },
  {
    key: "当前账户余额",
    value: "現在の口座残高",
  },
  {
    key: "国际物流",
    value: "国際物流",
  },
  {
    key: "定額会員",
    value: "定額会員",
  },
  {
    key: "RAKUMART 公司费用介绍",
    value: "RAKUMARTの会社費用についての説明",
  },
  {
    key: "附加服务",
    value: "追加サービス",
  },
  {
    key: "在Rakumart,您还可以享受到各个仓库的标签服务（比如FBA，乐天，雅虎），产品定制摄影，ODM定制，等额外服务",
    value:
      "Rakumartでは、各倉庫のラベルサービス（FBA、楽天、Yahooなど）や製品のカスタム撮影、ODMのカスタマイズなど、さまざまな追加サービスを提供しています",
  },
  {
    key: "收费会员",
    value: "定額会員",
  },
  {
    key: "商品调查",
    value: "商品調査",
  },
  {
    key: "免费",
    value: "無料",
  },
  {
    key: "图片翻译",
    value: "画像翻訳",
  },
  {
    key: "商品详情介绍图片翻译",
    value: "商品詳細説明の画像翻訳",
  },
  {
    key: "特殊情况优先处理",
    value: "特殊な状況を優先的に処理",
  },
  {
    key: "您的订单特殊情况下为您优先处理",
    value: "お客様の注文は特殊な状況下で優先的に処理されます",
  },
  {
    key: "普通会员",
    value: "一般会員",
  },
  {
    key: "无",
    value: "なし",
  },
  {
    key: "※推荐给每月订购50万日元以上商品的顾客。",
    value: "※毎月50万円以上の商品を注文するお客様にお勧めです",
  },
  {
    key: "关于规则事项",
    value: "ルールについて",
  },
  {
    key: "目前无论客户等级如何，会费支付完毕及确定后，以后的代理手续费一律为0%。",
    value:
      "現在、お客様のランクに関係なく、会費の支払いと確定後、以降注文の手数料はすべて0％です。",
  },
  {
    key: "国内派送中",
    value: "配送中",
  },
  {
    key: "售后",
    value: "アフターサービス",
  },
  {
    key: "收藏商品",
    value: "お気に入り商品",
  },
  {
    key: "条",
    value: "件",
  },
  {
    key: "地址",
    value: "住所",
  },
  {
    key: "新增地址",
    value: "新しい住所を追加",
  },
  {
    key: "请先添加地址",
    value: "住所を先に追加してください",
  },
  {
    key: "请输入氏名",
    value: "氏名を入力してください",
  },
  {
    key: "请输入住所",
    value: "住所を入力してください",
  },
  {
    key: "最多输入255个字符",
    value: "最大255文字まで入力できます",
  },
  {
    key: "姓氏长度不能超过24个字符",
    value: "姓の長さは24文字を超えることはできません",
  },
  {
    key: "住所长度不能超过255个字符",
    value: "住所の長さは255文字を超えることはできません",
  },
  {
    key: "格式不正确",
    value: "形式が正しくありません",
  },
  {
    key: "リアルタイム口座输入错误",
    value: "リアルタイム口座の入力が間違っています",
  },
  {
    key: "リアルタイム口座请输入14位纯数字",
    value: "リアルタイム口座には14桁の数字を入力してください",
  },
  {
    key: "添加成功",
    value: "追加が成功しました",
  },
  {
    key: "注销成功",
    value: "アカウントの削除に成功しました",
  },
  {
    key: "是否删除该临时配送单？",
    value: "この一時的な配送依頼書を削除しますか？",
  },
  {
    key: "是否确认当前配送单已收到货?",
    value: "現在の配送依頼書の受け取りを確認しますか？",
  },
  {
    key: "收件人",
    value: "受取人",
  },
  {
    key: "签收时间",
    value: "受領時間",
  },
  {
    key: "签收",
    value: "受領",
  },
  {
    key: "未读",
    value: "未読",
  },
  {
    key: "已读",
    value: "既読",
  },
  {
    key: "当前选中的通知中，包含有未读的通知，知否确认删除选中的内容？",
    value:
      "現在の選択した通知には未読の通知が含まれています。選択した内容を削除してもよろしいですか？",
  },
  {
    key: "请至少选择一项通知",
    value: "少なくとも1つの通知を選択してください",
  },
  {
    key: "请输入登录密码",
    value: "ログインパスワードを入力してください",
  },
  {
    key: "验证码错误",
    value: "確認コードが間違っています",
  },
  {
    key: "请输入确认密码",
    value: "確認パスワードを入力してください",
  },
  {
    key: "两次输入密码不一致",
    value: "パスワードが一致しません",
  },
  {
    key: "密码修改成功，需要重新登录",
    value: "パスワードが正常に変更されました。再ログインが必要です",
  },
  {
    key: "当前邮箱已被使用",
    value: "現在のメールアドレスはすでに使用されています",
  },
  {
    key: "请输入验证吗",
    value: "確認コードを入力してください",
  },
  {
    key: "手机号码格式不正确",
    value: "携帯電話番号の形式が正しくありません",
  },
  {
    key: "处理时间",
    value: "処理時間",
  },
  {
    key: "问题产品完成时间",
    value: "問題製品の完了時間",
  },
  {
    key: "请输入处理意见",
    value: "処理意見を入力してください",
  },
  {
    key: "提交失败，当前有商品未填写处理意见",
    value:
      "申し込みに失敗しました。現在、処理意見が記入されていない商品があります。",
  },
  {
    key: "天",
    value: "日",
  },
  {
    key: "立即申请",
    value: "すぐに申し込む",
  },
  {
    key: "申请成功后，从负责人通知确定的当天开始计算天数。收费会员期限不是按月计算，按天数计算。例如，1月有31天，从1/1日开始的话，有效期是1/30天，1/31就不适用了。",
    value:
      "申し込みが承認された日から日数をカウントします。定額会員の期間は月単位ではなく、日数単位で計算されます。たとえば、1月は31日ありますが、1月1日からの有効期間は30日であり、31日は適用されません。",
  },
  {
    key: "确定为收费会员后，关于未支付的订单，手续费将自动变为0%。",
    value:
      "定額会員に確定した場合、未払いの注文に対して手数料は自動的に0％になります。",
  },
  {
    key: "期间因中国的法定假日等原因连续休息5天以上的情况自动加上相应的天数。(例如:国庆节、春节等)",
    value:
      "中国の法定休日などにより連続して5日以上休業する場合、該当する日数が自動的に追加されます。（例：国慶節、春節など）。",
  },
  {
    key: "您正在申请收费会员天数，支付后实时生效",
    value: "有料会員の期間を申請中です。支払い後、即座に有効となります。",
  },
  {
    key: "收费会员失效前5天，系统会自动发送通知邮件，但由于某些原因延迟。因为也有无法到达的情况，关于失效期限，请自己管理。",
    value:
      "定額会員の有効期限が5日前になると、システムから自動的に通知メールが送信されますが、何らかの理由で遅延する場合もあります。配信できない場合もあるため、有効期限については自己管理をお願いします。",
  },
  {
    key: "请注意，关于成为收费会员期间的变更、退款不能接受。因为我们事先预付了手续费(约定)，是可以产生优惠的服务。万一，在交易中发生了什么问题，由弊公司引起的原因的话，将会提出收费会员费返还以外的补偿方案。",
    value:
      "定額会員期間中の変更や返金は基本受け付けられません。事前に手数料（契約金額）を支払っているため、優待のサービスを提供しています。万が一、取引中に何か問題が発生した場合で弊社が原因の場合、定額会員料金の返還以外の補償策を提案させていただきます。",
  },
  {
    key: "是否取消选中的订单",
    value: "選択した注文を削除しますか？",
  },
  {
    key: "个人信息",
    value: "個人情報",
  },
  {
    key: "装箱数",
    value: "梱包数",
  },
  {
    key: "部分商品已打包装箱，不可手动提出，请联系您的负责人进行了解",
    value:
      "一部の商品はすでにパッケージ化されており、手動で申し出ることはできません。責任者に連絡して詳細をお知らせください。",
  },
  {
    key: "同意以下协议，进入下一步",
    value: "以下の契約に同意し、次に進む",
  },
  {
    key: "RAKUMART的",
    value: "RAKUMARTの",
  },
  {
    key: "以及",
    value: "そして",
  },
  {
    key: "用户名",
    value: "ユーザー名",
  },
  {
    key: "请输入用户名",
    value: "ユーザー名を入力してください",
  },
  {
    key: "长度在 1 到24个字符",
    value: "長さは1から24文字",
  },
  {
    key: "请填写验证码",
    value: "認証コードを入力してください",
  },
  {
    key: " 请输入全名",
    value: "フルネームを入力してください",
  },
  {
    key: "请输入电子邮箱",
    value: "メールアドレスを入力してください",
  },
  {
    key: "手机号不能为空",
    value: "携帯電話番号は空白にできません",
  },
  {
    key: "请输入座机号",
    value: "電話番号を入力してください",
  },
  {
    key: "请确认密码",
    value: "パスワードを確認してください",
  },
  {
    key: "请输入验证码",
    value: "認証コードを入力してください",
  },
  {
    key: "验证方式",
    value: "検証方法",
  },
  {
    key: "发送验证码",
    value: "認証コードを送信",
  },
  {
    key: "1688",
    value: "1688",
  },
  {
    key: "淘宝",
    value: "淘宝",
  },
  {
    key: "请求失败，点击重新加载",
    value: "要求に失敗しました、クリックして再読み込みしてください",
  },
  {
    key: "加载中",
    value: "読み込み中",
  },
  {
    key: "没有更多了",
    value: "これ以上はありません",
  },
  {
    key: "按照图片搜索",
    value: "画像による検索",
  },
  {
    key: "释放即可刷新...",
    value: "リリースして更新...",
  },

  {
    key: "下拉刷新",
    value: "プルダウンして更新",
  },
  { key: "加载成功", value: "ロード成功" },
  { key: "请选择认证方式", value: "認証方法を選択してください" },
  { key: "手机号认证", value: "携帯電話番号認証" },
  { key: "邮箱认证", value: "メール認証" },
  { key: "修改新密码", value: "新しいパスワードを変更する" },
  { key: "登录密码修改成功", value: "ログインパスワードの変更に成功しました" },
  { key: "返回登录", value: "ログインに戻る" },
  { key: "加载中...", value: "読み込み中..." },
  { key: "新用户注册优惠券", value: "新規ユーザー登録クーポン" },
  { key: "未使用", value: "未使用" },
  { key: "已过期", value: "期限切れ" },
  { key: "替换头像", value: "画像を変更する" },
  { key: "真名", value: "実名" },
  { key: "配送管理", value: "配送先管理" },
  { key: "账户设置", value: "登録情報変更" },
  { key: "客户授权", value: "顧客認証" },
  { key: "版本更新", value: "バージョン更新" },
  { key: "设置", value: "設定" },
  {
    key: "使用原密码进行验证",
    value: "元のパスワードで認証します",
  },
  { key: "使用电子邮箱进行验证", value: "メールアドレスで認証します" },
  { key: "使用手机号进行验证", value: "電話番号で認証します" },
  { key: "密码修改", value: "パスワード変更" },
  { key: "电子邮箱", value: "電子メール" },
  { key: "当前绑定手机号", value: "現在の携帯電話番号の紐づけ" },
  { key: "验证成功", value: "認証成功" },
  { key: "保存成功", value: "保存が成功しました" },
  { key: "更换绑定邮箱", value: "メルアドレスの変更" },
  { key: "更换绑定手机号", value: "電話番号の変更" },
  { key: "注销账号", value: "アカウント削除" },
  { key: "立即修改", value: "すぐに変更" },
  { key: "两次密码输入不一致", value: "2回のパスワード入力が一致しません" },
  { key: "邮箱修改成功", value: "メールアドレスの変更が成功しました" },
  { key: "邮箱已被使用", value: "メールアドレスはすでに使用されています" },
  { key: "手机号修改", value: "電話番号の変更" },
  { key: "手机号码已被使用", value: "電話番号はすでに使用されています" },
  { key: "手机号修改成功", value: "電話番号の変更に成功しました" },
  { key: "验证码错误", value: "認証コードエラー" },
  { key: "修改新手机号", value: "新しい電話番号を変更する" },
  { key: "请输入新的手机号", value: "新しい電話番号を入力してください" },
  { key: "邮箱修改", value: "メールアドレスの変更" },
  { key: "请输入邮箱地址", value: "メールアドレスを入力してください" },
  { key: "两次输入密码不一致", value: "2回のパスワード入力が一致しません" },
  { key: "登录密码错误", value: "ログインパスワードエラー" },
  {
    key: "修改新邮箱",
    value: "新しいメールアドレスを変更する",
  },
  {
    key: "临时保存",
    value: "一時保存",
  },
  {
    key: "全部",
    value: "全て",
  },
  {
    key: "装箱中",
    value: "梱包中",
  },
  {
    key: "等待付款",
    value: "支払い待ち",
  },
  {
    key: "发货中",
    value: "出荷中",
  },
  {
    key: "已发货",
    value: "出荷済み",
  },
  {
    key: "配送单号",
    value: "配送伝票番号",
  },
  {
    key: "商品番数",
    value: "総SKU数",
  },
  {
    key: "商品数量",
    value: "合計商品数量",
  },
  {
    key: "收件人",
    value: "受取人",
  },
  {
    key: "收件地址",
    value: "受取住所",
  },
  {
    key: "配送单创建时间",
    value: "配送伝票作成時間",
  },
  {
    key: "付款时间",
    value: "入金日",
  },
  {
    key: "发货时间",
    value: "出荷時間",
  },
  {
    key: "签收时间",
    value: "受領時間",
  },
  {
    key: "请输入配送单号",
    value: "配送伝票番号を入力してください",
  },
  {
    key: "已评价",
    value: "評価済み",
  },
  {
    key: "订单创建时间",
    value: "提出日",
  },
  {
    key: "问题番数",
    value: "問題番号",
  },
  {
    key: "历史问题产品",
    value: "履歴",
  },
  {
    key: "问题产品总数量",
    value: "問題のある製品の総数",
  },
  {
    key: "立即处理",
    value: "即時処理",
  },
  {
    key: "问题图片",
    value: "問題の画像",
  },
  {
    key: "问题详情",
    value: "問題の詳細",
  },
  {
    key: "问题产品数量",
    value: "問題のある製品の数量",
  },
  {
    key: "Únete 和 Rakumart",
    value: "ÚneteとRakumart",
  },
  {
    key: "商品变体",
    value: "商品詳細",
  },
  {
    key: "我的钱包",
    value: "マイウォレット",
  },
  {
    key: "申请提现",
    value: "出金申請",
  },
  {
    key: "请输入银行账户",
    value: "銀行口座を入力してください",
  },
  {
    key: "请输入提现金额",
    value: "引き出し額を入力してください",
  },
  {
    key: "提现原因",
    value: "出金の理由",
  },
  {
    key: "请输入提现原因",
    value: "出金の理由を入力してください",
  },
  {
    key: "店铺信息",
    value: "店舗情報",
  },
  {
    key: "会员有效期",
    value: "会員の残り日数",
  },
  {
    key: "请选择开通时长",
    value: "有効期間を選択してください",
  },
  {
    key: "1.手续费 0%",
    value: "1.手数料0％",
  },
  {
    key: "2.商品调查 免费 ",
    value: "2.商品調査無料（回数制限あり）",
  },
  {
    key: "3.商品详情介绍图片翻译",
    value: "3.商品ページの画像内文字自動翻訳",
  },
  {
    key: "4.订单为您优先处理",
    value: "4.優先処理",
  },
  {
    key: "会员",
    value: "会員",
  },
  {
    key: "立省",
    value: "節約",
  },
  {
    key: "当前还不是收费会员",
    value: "現在まだ定額会員ではありません",
  },
  {
    key: "当您是收费会员时",
    value: "定額会員である場合",
  },
  {
    key: "中国国内状态",
    value: " 中国国内状態",
  },
  {
    key: "临时订单",
    value: "未提出注文書",
  },
  {
    key: "临时配送单",
    value: "未提出配送書",
  },
  {
    key: "临时保存（未提交）",
    value: "一時保存(未提出)",
  },
  {
    key: "问题处理",
    value: "問題処理",
  },
  {
    key: "钱包",
    value: "ウォレット",
  },
  {
    key: "劵包",
    value: "クーポン",
  },
  {
    key: "枚",
    value: "枚",
  },
  {
    key: "立即开通",
    value: "申込",
  },
  {
    key: "首页",
    value: "トップ",
  },
  {
    key: "分类",
    value: "カテゴリー",
  },
  {
    key: "可根据订单号搜索",
    value: "注文番号で検索可能",
  },
  {
    key: "规格查看",
    value: "規格を見る",
  },
  {
    key: "暂无历史记录",
    value: "ありません",
  },
  {
    key: "联系担当",
    value: "担当者に連絡する",
  },
  {
    key: "商品规格属性",
    value: "商品規格",
  },
  {
    key: "店铺名称",
    value: "店舗名",
  },
  {
    key: "规格选择",
    value: "規格選択",
  },
  {
    key: "库存数",
    value: "在庫数",
  },
  {
    key: "选择数量",
    value: "数量を選択",
  },
  {
    key: "总计",
    value: "合計",
  },
  {
    key: "请输入备注",
    value: "備考欄",
  },
  {
    key: "请至少选择一项",
    value: "少なくとも一つ選択してください",
  },
  {
    key: "追加商品",
    value: "商品を追加",
  },
  {
    key: "删除商品",
    value: "商品を削除",
  },
  {
    key: "是否删除商品",
    value: "商品を削除する",
  },
  {
    key: "请确认以下信息是否正确!",
    value: "以下の情報が正しいかどうかご確認ください!",
  },
  {
    key: "修改订单",
    value: "注文を修正",
  },
  {
    key: "修改配送单",
    value: "配送書を修正",
  },
  {
    key: "订单明细",
    value: "注文詳細",
  },
  {
    key: "番数",
    value: "番数",
  },
  {
    key: "商品总数",
    value: "商品総数",
  },
  {
    key: "当前汇率",
    value: "レート",
  },
  {
    key: "附加服务查看",
    value: "追加サービスを見る",
  },
  {
    key: "请选择附加服务",
    value: "追加サービスを選択してください",
  },
  {
    key: "请输入备注...",
    value: "メモを入力してください...",
  },
  {
    key: "完成",
    value: "完了",
  },
  {
    key: "将该商品将移出购物车",
    value: "この商品はカートから削除されます",
  },
  {
    key: "是否将选中的商品移除订单详情",
    value: "選択した商品を注文詳細から削除しますか",
  },
  {
    key: "暂无相关订单",
    value: "関連する注文はありません",
  },
  {
    key: "购买完成时间",
    value: "買付完了日",
  },
  {
    key: "待支付尾款",
    value: "残債支払い待ち",
  },
  {
    key: "尾款待财务确认",
    value: "残債入金確認中",
  },
  {
    key: "下载订单",
    value: "注文をダウンロード",
  },
  {
    key: "取消时间",
    value: "キャンセル時間",
  },
  {
    key: "订单详情",
    value: "注文詳細",
  },
  {
    key: "选择优惠券",
    value: "クーポンを選択",
  },
  {
    key: "取消修改",
    value: "変更をキャンセル",
  },
  {
    key: "再次提出",
    value: "再提出",
  },
  {
    key: "物流选择",
    value: "物流選択",
  },
  {
    key: "订单备注",
    value: "注文書備考欄",
  },
  {
    key: "* 对该订单有特殊要求的，可以在这边备注 \n* 备注后会同步显示给业务员",
    value:
      "* この注文に特別な要求がある場合、ここにメモを入力できます \n* メモは業務員に同期表示されます",
  },
  {
    key: "附加服务费",
    value: "オプション手数料",
  },
  {
    key: "待支付金额",
    value: "支払い金額",
  },
  {
    key: "选择支付方式",
    value: "支払い方法を選択",
  },
  {
    key: "银行支付",
    value: "銀行支払い",
  },
  {
    key: "paypal支付",
    value: "PayPal支払い",
  },
  {
    key: "跳转下一步",
    value: "次へ",
  },
  {
    key: "支付",
    value: "支払う",
  },
  {
    key: "支付方式选择",
    value: "支払い方法選択",
  },
  {
    key: "请选择支付方式",
    value: "支払い方法を選択してください",
  },
  {
    key: "楽天銀行",
    value: "楽天銀行",
  },
  {
    key: "ゆうちょ銀行",
    value: "ゆうちょ銀行",
  },
  {
    key: "ジャパンネット銀行",
    value: "ジャパンネット銀行",
  },
  {
    key: "口座番号",
    value: "口座番号",
  },
  {
    key: "复制银行信息",
    value: "銀行情報をコピー",
  },
  {
    key: "复制银行卡号",
    value: "口座番号情報コピー",
  },
  {
    key: "最低入金金额",
    value: "最低入金額",
  },
  {
    key: "注意",
    value: "注意",
  },
  {
    key: "确认付款",
    value: "支払い確認",
  },

  {
    key: "财务确认后，充值金额将反应在账户余额中",
    value: "財務確認後、入金額がアカウント残高に反映されます",
  },
  {
    key: "交易流水号",
    value: "取引明細番号",
  },
  {
    key: "回到个人中心",
    value: "マイページに戻る",
  },
  {
    key: "已勾选番数",
    value: "選択した番号数",
  },
  {
    key: "暂无相关仓库",
    value: "関連する倉庫がありません",
  },
  {
    key: "收费仓库里货物需要发货，请直接与您的负责人联系！",
    value:
      "有料倉庫の商品が出荷を必要としています、直接あなたの責任者に連絡してください！",
  },
  {
    key: "请选择商品",
    value: "商品を選択してください",
  },
  {
    key: "元/点",
    value: "元/点",
  },
  {
    key: "商品标签编辑",
    value: "タグ編集",
  },
  {
    key: "配送信息",
    value: "配送情報",
  },
  {
    key: "费用明细",
    value: "料金詳細",
  },
  {
    key: "装箱信息",
    value: "梱包情報",
  },
  {
    key: "提货数量",
    value: "発送数量",
  },
  {
    key: "选择标签信息",
    value: "タグ情報を選択",
  },
  {
    key: "请输入单番装箱备注",
    value: "備考欄",
  },

  {
    key: "配送信息填写（配送方式，订单备注）",
    value: "配送情報の記入（配送方法、注文メモ）",
  },
  {
    key: "国際運送会社選択",
    value: "国際配送会社の選択",
  },
  {
    key: "复制成功",
    value: "コピーが成功しました",
  },
  {
    key: "一箱30kg以内，含有电池，水的商品不能发送。",
    value: "一箱30kg以内、電池や水を含む商品は送信できません。",
  },
  {
    key: "请输入备注信息",
    value: "備考情報を入力してください",
  },
  {
    key: "这个选项不能为空",
    value: "この項目は空白にできません",
  },
  {
    key: "ローマ字（氏名）：",
    value: "ローマ字（氏名）：",
  },
  {
    key: "ローマ字（住所）：",
    value: "ローマ字（住所）：",
  },
  {
    key: "手机：",
    value: "携帯電話：",
  },
  {
    key: "座机：",
    value: "固定電話：",
  },
  {
    key: "法人番号：",
    value: "法人番号：",
  },
  {
    key: "リアルタイム口座：",
    value: "リアルタイム口座：",
  },
  {
    key: "標準コード：",
    value: "標準コード：",
  },
  {
    key: "收件人地址数据为空",
    value: "受取人の住所情報が空です",
  },
  {
    key: "暂无相关配送单",
    value: "関連する配送伝票はありません",
  },
  {
    key: "配送单详情",
    value: "配送伝票の詳細",
  },
  {
    key: "配送单需要调整或者有其他疑问，请直接与负责人联系！",
    value: "変更される場合は担当者までご連絡ください。",
  },
  {
    key: "配送单汇率",
    value: "レート",
  },
  {
    key: "使用时间",
    value: "使用時間",
  },
  {
    key: "配送依頼書",
    value: "配送依頼書",
  },
  {
    key: "通知详情",
    value: "通知詳細",
  },
  {
    key: "关闭",
    value: "閉じる",
  },
  {
    key: "是否确认删除选中的内容？",
    value: "選択した内容を削除してもよろしいですか？",
  },
  {
    key: "可通过关键词或者1688，淘宝链接进行搜索",
    value: "キーワードまたは1688、タオバオの商品URL",
  },
  {
    key: "还没有账号？",
    value: "まだアカウントをお持ちでないですか？",
  },
  {
    key: "前往注册",
    value: "登録に進む",
  },
  {
    key: "登录名/邮箱/手机号",
    value: "ログイン名/メールアドレス/携帯電話番号",
  },
  {
    key: "未匹配到对应的用户",
    value: "対応するユーザーが見つかりません",
  },
  {
    key: "收费天数",
    value: "請求日数",
  },
  {
    key: "提现",
    value: "出金",
  },
  {
    key: "冻结",
    value: "凍結",
  },
  {
    key: "入金待确认金额",
    value: "入金確認待ち金額",
  },
  {
    key: "充值金额",
    value: "チャージ金額",
  },
  {
    key: "请输入充值金额并选择付款方式",
    value: "チャージ金額を入力して支払い方法を選択してください",
  },
  {
    key: "入金金额",
    value: "入金金額",
  },
  {
    key: "回到钱包",
    value: "残高へ戻る",
  },
  {
    key: "出入金明细",
    value: "入出金詳細",
  },
  {
    key: "出金",
    value: "出金",
  },
  {
    key: "入金",
    value: "入金",
  },
  {
    key: "充值入金",
    value: "入金をチャージする",
  },
  {
    key: "年份选择",
    value: "年を選択",
  },
  {
    key: "可根据商品名称模糊匹配",
    value: "商品名キーワード検索",
  },
  {
    key: "收藏夹暂无内容",
    value: "お気に入りフォルダは現在空です",
  },
  {
    key: "删除成功",
    value: "削除成功",
  },
  {
    key: "请选择物流方式",
    value: "配送業者を選択してください",
  },
  {
    key: "验证码获取",
    value: "認証コードを取得",
  },
  {
    key: "请输入您的新邮箱",
    value: "新しいメールアドレスを入力してください",
  },
  {
    key: "输入的邮箱格式不正确",
    value: "入力されたメールアドレスの形式が正しくありません",
  },
  {
    key: "密码为6-16位，可由字母数字及@符号组成，不支持空格 6 到 16 位的密码,不支持空格",
    value:
      "パスワードは6〜16文字で、英数字と@記号で構成され、スペースはサポートされません。 6〜16文字のパスワード、スペースはサポートされません",
  },
  {
    key: "请再次输入新密码进行确认",
    value: "新しいパスワードを再入力して確認してください",
  },
  {
    key: "密码为6-16位,可由大小写字母数字及特殊符号组成，不支持空格",
    value:
      "パスワードは6〜16文字で、大文字と小文字の英数字および特殊記号で構成され、スペースはサポートされません",
  },
  {
    key: "敬请您注意并知悉，当您注销账号后，将（可能）产生包括但不限于如下后果：",
    value:
      "あなたがアカウントをキャンセルすると、次のような結果が（可能性があります）生じることにご注意ください：",
  },
  {
    key: "1.注销账号后，您将无法登录、使用该账号及账号原验证手机或邮箱登录RAKUMART相关产品与服务，即您将无法再以此账号登录、使用RAKUMART相关产品与服务。",
    value:
      "1. アカウントをキャンセルすると、そのアカウントやアカウントでの元の携帯電話番号またはメールアドレスでRAKUMART関連の製品やサービスにログインしたり使用したりすることはできなくなります。つまり、このアカウントでRAKUMART関連の製品やサービスにログインしたり使用したりすることはできません。",
  },
  {
    key: "2.注销账号后，您曾通过该账号登录、使用的RAKUMART相关产品与服务下的所有内容、信息、数据、记录将会被全部删除或匿名化处理（但国家法律法规、规章、规范性文件，政府的政策、命令等另有要求或为履行我们的合规义务的除外），您也无法再检索、访问、获取、继续使用和找回这些信息或数据，包括但不限于：",
    value:
      "2. アカウントをキャンセルすると、そのアカウントでログインしたり使用したりしたRAKUMART関連の製品やサービスのすべての内容、情報、データ、記録はすべて削除または匿名化処理されます（ただし、国家の法律、規則、規制文書、政府の政策、命令などの要件または弊社のコンプライアンス義務の履行に関する場合を除く）。これらの情報やデータにアクセスしたり、検索したり、取得したり、継続使用したり、回復したりすることはできません。",
  },

  {
    key: "(1)该账号下的您自主设置的个人资料（如：头像、昵称等);",
    value: "(1) そのアカウントで設定した個人情報（アバター、ニックネームなど）",
  },
  {
    key: "(2)其他与该账号相关的内容、信息、数据、历史记录或服务记录等。",
    value:
      "(2) そのアカウントに関連する他のコンテンツ、情報、データ、履歴記録、またはサービス記録など",
  },
  {
    key: "3.注销账号后，您在使用RAKUMART产品与服务期间产生的账号内资产和虚拟权益将视为自动放弃，无法予以退还或退换，包括但不限于：",
    value:
      "3. アカウントのキャンセル後、RAKUMART製品やサービスを利用する過程で生じたアカウント内の資産や仮想的な権益は自動的に放棄され、返金または交換はできません。これには、次のものが含まれますが、これらに限定されません：",
  },
  {
    key: "(1)您在RAKUMART产品与服务中的付费会员、会员等级等各类身份权益、积分、金币等；",
    value:
      "(1) RAKUMARTの製品やサービスでの有料会員、会員レベルなどの身分権益、ポイント、コインなど",
  },
  {
    key: " (2)其他已产生但未消耗完毕的权益及资产或未来的预期收益。",
    value:
      " (2) その他、既に発生しているが完全に消費されていない権益や資産、または将来の予想収益",
  },
  {
    key: "4.账号注销具有不可逆转和不可撤销性，账号一旦注销完成，将无法恢复。如果您在注销后以相同手机号码或相同电子邮箱再次注册账号，将会注册失败，之前注册过的邮箱与手机号不能再次用来进行注册。",
    value:
      "4. アカウントのキャンセルは不可逆的かつ取り消し不可であり、アカウントが完全にキャンセルされると、元に戻すことはできません。キャンセル後、同じ電話番号や電子メールアドレスでアカウントを再登録しようとすると、登録に失敗します。以前に登録したメールアドレスや電話番号は再登録に使用できません。",
  },
  {
    key: "5.账号一旦注销，您将不再享有您与我们签署的相关用户协议、其他权利义务性文件中约定的适用于注册用户的权利，但法律法规、规章、规范性文件，政府的政策、命令等另有要求或用户服务协议中另有规定的除外。",
    value:
      "5. アカウントがキャンセルされると、登録ユーザーに適用される関連するユーザー契約や他の権利義務に関する文書に記載された権利を享受することはできなくなりますが、法令、規則、規範文書、政府の政策、命令などの要求またはユーザーサービス契約で別途規定されている場合を除きます。",
  },
  {
    key: "我已了解，继续进行",
    value: "了解しました、続行します",
  },
  {
    key: "注销账户",
    value: "アカウントを削除する",
  },
  {
    key: "请您注意",
    value: "ご注意ください",
  },
  {
    key: "注销成功之后不可恢复，请谨慎操作。",
    value:
      "アカウントを削除すると元に戻すことはできません。慎重に操作してください。",
  },
  {
    key: "由于您正在进行敏感操作，请输入您的登录密码进行确认",
    value:
      "機密操作を実行しているため、ログインパスワードを入力して確認してください",
  },
  {
    key: "立即注销",
    value: "すぐにアカウントを削除する",
  },
  {
    key: "用户名或密码错误",
    value: "ユーザー名またはパスワードが間違っています",
  },
  {
    key: "账号或密码不对",
    value: "アカウントまたはパスワードが間違っています",
  },
  {
    key: "没有找到相关商品",
    value: "関連する商品が見つかりませんでした",
  },
  {
    key: "您可以尝试更换关键词或者图片进行查找。",
    value: "キーワードや画像を変更して検索してみてください",
  },
  {
    key: "待财务确认",
    value: "入金確認中",
  },
  {
    key: "实际重量(kg)",
    value: "実際の重量（kg）",
  },
  {
    key: "长(cm)",
    value: "長さ（cm）",
  },
  {
    key: "宽(cm)",
    value: "幅（cm）",
  },
  {
    key: "高(cm)",
    value: "高さ（cm）",
  },
  {
    key: "体积(cm³)",
    value: "体積（cm³）",
  },
  {
    key: "国际快递单号",
    value: "国際配送伝票番号",
  },
  {
    key: "箱子货物信息",
    value: "箱子の荷物情報",
  },
  {
    key: "暂无收货地址",
    value: "配送先住所がありません",
  },
  {
    key: "请输入地址名称",
    value: "住所名を入力してください",
  },
  {
    key: "地址名称 :",
    value: "住所名:",
  },
  {
    key: "地址名称",
    value: "住所名",
  },
  {
    key: "氏名:",
    value: "お名前:",
  },
  {
    key: " 请输入邮编",
    value: "郵便番号を入力してください",
  },
  {
    key: "手机号为空",
    value: "電話番号が空です",
  },
  {
    key: "修改成功",
    value: "修正が成功しました",
  },
  {
    key: "查询不到您输入的订单号",
    value: "ご入力いただいた注文番号は見つかりませんでした",
  },
  {
    key: "去购物",
    value: "ショッピングに行く",
  },
  //   ---------------------------------------------
  {
    key: "密码",
    value: "パスワード",
  },
  {
    key: "忘记密码",
    value: "パスワードを忘れた場合",
  },
  {
    key: "添加地址",
    value: "添加地址",
  },
  {
    key: "还没有账号",
    value: "無料",
  },
  {
    key: "创建新账户",
    value: "新規会員登録",
  },
  {
    key: "标记*的请务必填写",
    value: "※は必ずご記入ください",
  },
  {
    key: "还没有账号",
    value: "新規会員登",
  },
  {
    key: "成功",
    value: "成功",
  },
  {
    key: "数据导出中",
    value: "数据导出中",
  },
  {
    key: "长度在 1 到 24 个字符",
    value: "半角1文字以上24文字以内に収めてください",
  },
  {
    key: "登录名不能全由数字组成",
    value: "数字のみのユーザー名は対応できません",
  },
  {
    key: "保持登录状态",
    value: "登録状態を維持",
  },
  {
    key: "从淘宝、阿里巴巴、天猫轻松转型进口业务",
    value: "タオバオ・アリババ・天猫から楽々と輸入事業へ",
  },
  {
    key: "加入商品库",
    value: "加入商品库",
  },
  {
    key: "请输入邮箱/手机号",
    value: "请输入邮箱/手机号",
  },
  {
    key: "新会员注册",
    value: "新会员注册",
  },
  {
    key: "倉庫保管費用",
    value: "倉庫保管費用",
  },
  {
    key: "请输入密码",
    value: "パスワードを記入",
  },
  {
    key: "カウント期間",
    value: "カウント期間",
  },
  {
    key: "正在发送验证码",
    value: "正在发送验证码",
  },
  {
    key: "一つの注文書に於いて、全ての商品がマイページ『倉庫配送依頼』に入った日（出荷可能日）~ 配送依頼見積書を提出した日（出荷依頼日）の前日まで。",
    value:
      "一つの注文書に於いて、全ての商品がマイページ『倉庫配送依頼』に入った日（出荷可能日）~ 配送依頼見積書を提出した日（出荷依頼日）の前日まで。",
  },
  {
    key: "無料保管期間",
    value: "無料保管期間",
  },
  {
    key: "通常会員様",
    value: "通常会員様",
  },
  {
    key: "無料保管",
    value: "無料保管",
  },
  {
    key: "月額有料会員様",
    value: "月額有料会員様",
  },
  {
    key: "無料保管期限を過ぎた場合の保管手数料について",
    value: "無料保管期限を過ぎた場合の保管手数料について",
  },
  {
    key: "注意事项",
    value: "注意事项",
  },
  {
    key: "法人番号",
    value: "法人番号",
  },
  {
    key: "標準コード",
    value: "標準コード",
  },
  {
    key: "リアルタイム口座",
    value: "リアルタイム口座",
  },
  {
    key: "将该信息设为默认进口商",
    value: "将该信息设为默认进口商",
  },
  {
    key: "アカウント残高",
    value: "アカウント残高",
  },
  {
    key: "授权管理",
    value: "権限管理",
  },
  {
    key: "您已进行装箱授权，如需要提货请与负责人沟通",
    value: "您已进行装箱授权，如需要提货请与负责人沟通",
  },
  {
    key: "現在ご利用可能の金額",
    value: "現在ご利用可能の金額",
  },
  {
    key: "入金確認待ち金額",
    value: "入金確認待ち金額",
  },
  {
    key: "不可用金额",
    value: "不可用金额",
  },
  {
    key: "物流失效，请重新选择物流",
    value: "物流失效，请重新选择物流",
  },
  {
    key: "失效",
    value: "URL無効",
  },
  {
    key: "您对该商品添加过的数据可以快捷填充，目前标签只支持：FBA，乐天，雅虎",
    value:
      "您对该商品添加过的数据可以快捷填充，目前标签只支持：FBA，乐天，雅虎",
  },
  {
    key: "不可用",
    value: "不可用",
  },
  {
    key: "余额提现",
    value: "余额提现",
  },
  {
    key: "振込先",
    value: "振込先",
  },
  {
    key: "请输入手机号",
    value: "日本国内の携帯電話を入力してください",
  },
  {
    key: "填写个人信息",
    value: "個人情報入力",
  },
  {
    key: "注册完成",
    value: "新規登録完了",
  },
  {
    key: "名字",
    value: "名字",
  },
  {
    key: "重置密码",
    value: "重置密码",
  },
  {
    key: "名字需要修改，请与业务员进行联系",
    value: "名字需要修改，请与业务员进行联系",
  },
  {
    key: "变更",
    value: "变更",
  },
  {
    key: "请输入正确的邮箱地址",
    value: "正しいメールアドレスをご記入ください",
  },
  {
    key: "会员到期日",
    value: "残り日",
  },
  {
    key: "订单问题产品",
    value: "注文書　問題商品",
  },
  {
    key: "权限管理",
    value: "权限管理",
  },
  {
    key: "功能权限",
    value: "功能权限",
  },
  {
    key: "查看范围",
    value: "詳細范围",
  },
  {
    key: "电话",
    value: "电话",
  },
  {
    key: "验证码",
    value: "確認コード",
  },
  {
    key: "同意以上协议，进入下一步",
    value: "上記規約に同意して確認画面へ",
  },
  {
    key: "非常感谢您的注册",
    value:
      "この度新規登録いただきましてありがとうございました。今後ともよろしくお願いいたします。",
  },
  {
    key: "注册完了",
    value: "登録完了",
  },
  {
    key: "立即登录",
    value: "ログインへ",
  },
  {
    key: "个人中心",
    value: "マイページ",
  },
  {
    key: "退出登录",
    value: "ログアウト",
  },
  {
    key: "注文書No",
    value: "注文書No",
  },
  {
    key: "キーワードまたは1688、タオバオの商品URL",
    value: "キーワードまたは1688、タオバオの商品URL",
  },
  {
    key: "登录",
    value: "ログイン",
  },
  {
    key: "注册",
    value: "新規登録",
  },
  {
    key: "仕入れ会員",
    value: "仕入れ会員",
  },
  {
    key: "無在庫について",
    value: "無在庫について",
  },
  {
    key: "費用について",
    value: "費用について",
  },
  {
    key: "附加服务说明",
    value: "オプション費用",
  },
  {
    key: "普检精检详细说明",
    value: "検品について",
  },
  {
    key: "option公司费用介绍",
    value: "オプション費用",
  },
  {
    key: "收费会员服务",
    value: "定額会員",
  },
  {
    key: "会社案内",
    value: "会社案内",
  },
  {
    key: "中国公司",
    value: "中国会社",
  },
  {
    key: "よくある質問",
    value: "よくある質問",
  },
  {
    key: "問い合わせ",
    value: "問い合わせ",
  },
  {
    key: "国際送料詳細",
    value: "国際送料詳細",
  },
  {
    key: "FBA直送サービス",
    value: "FBA直送サービス",
  },
  {
    key: "隐私政策",
    value: "プライバシーポリシー",
  },
  {
    key: "会员利用规章",
    value: "会員利用規約",
  },
  {
    key: "退货、退款及免责事项",
    value: "返品・返金及び免責事項",
  },
  {
    key: "会员服务",
    value: "会員サービス",
  },
  {
    key: "写真撮影（中国）",
    value: "写真撮影（中国）",
  },
  {
    key: "物流追跡",
    value: "物流追跡",
  },
  {
    key: "收费体系",
    value: "料金体系",
  },
  {
    key: "购物车",
    value: "買い物かご",
  },
  {
    key: "円",
    value: "円",
  },
  {
    key: "カードから追加",
    value: "カードから追加",
  },
  {
    key: "オプション",
    value: "オプション",
  },
  {
    key: "オプション选择",
    value: "オプション选择",
  },
  {
    key: "类别",
    value: "検索サイト",
  },
  {
    key: "标签号",
    value: "ラベル番号",
  },
  {
    key: "商品码",
    value: "商品コード",
  },
  {
    key: "特色类别",
    value: "特色種類",
  },
  {
    key: "加入购物车",
    value: "カートに入れる",
  },
  {
    key: "账号",
    value: "ユーザー名",
  },
  {
    key: "请输入账号或手机号或邮箱",
    value: "ユーザー名／携帯番号／Email",
  },
  {
    key: "其他认证方式",
    value: "其他认证方式",
  },
  {
    key: "免费注册会员",
    value: "無料会員登録",
  },
  {
    key: "利用ガイド",
    value: "利用ガイド",
  },
  {
    key: "确认个人信息",
    value: "确认个人信息",
  },
  {
    key: "账户注册",
    value: "账户注册",
  },
  {
    key: "登录名",
    value: "ユーザー名",
  },
  {
    key: "标记*的请务必填写",
    value: "标记*的请务必填写",
  },
  {
    key: "国家",
    value: "国家",
  },
  {
    key: "密码确认",
    value: "パスワード再確認",
  },
  {
    key: "手机",
    value: "携帯番号",
  },
  {
    key: "获取验证码",
    value: "確認コード取得",
  },
  {
    key: "在Rakumart，您还可以享受到各个仓库的标签服务（比如FBA，乐天，雅虎），产品定制摄影，ODM定制，等额外服务",
    value:
      "在Rakumart，您还可以享受到各个仓库的标签服务（比如FBA，乐天，雅虎），产品定制摄影，ODM定制，等额外服务",
  },
  {
    key: "如果您有自己的私人品牌，太好了！请联系我们。",
    value: "如果您有自己的私人品牌，太好了！请联系我们。",
  },
  {
    key: "包装，标签，精细检品",
    value: "包装，标签，精细检品",
  },
  {
    key: "为您的私人品牌生产定制包装，标签，按照您的要求进行",
    value: "为您的私人品牌生产定制包装，标签，按照您的要求进行",
  },
  {
    key: "個",
    value: "個",
  },
  {
    key: "产品摄影",
    value: "产品摄影",
  },
  {
    key: "贴标服务",
    value: "贴标服务",
  },
  {
    key: "更换条码，标签",
    value: "更换条码，标签",
  },
  {
    key: "更换包装袋",
    value: "更换包装袋",
  },
  {
    key: "双面打印",
    value: "双面打印",
  },
  {
    key: "缝制标签",
    value: "缝制标签",
  },
  {
    key: "缝制个性化标签到您的产品上，如纺织品，衣物等。",
    value: "缝制个性化标签到您的产品上，如纺织品，衣物等。",
  },
  {
    key: "拆卸织物标签",
    value: "拆卸织物标签",
  },
  {
    key: "放置吊牌",
    value: "放置吊牌",
  },
  {
    key: "移除吊牌",
    value: "移除吊牌",
  },
  {
    key: "联合包装",
    value: "联合包装",
  },
  {
    key: "泡沫袋保护",
    value: "泡沫袋保护",
  },
  {
    key: "我们可以去掉一些纺织品上，从工厂出来时一直带有的织物标签。",
    value: "我们可以去掉一些纺织品上，从工厂出来时一直带有的织物标签。",
  },
  {
    key: "在您的产品上按照您的需求放上您的品牌标签。",
    value: "在您的产品上按照您的需求放上您的品牌标签。",
  },
  {
    key: "RAKUMART手续费说明",
    value: "RAKUMART手续费说明",
  },
  {
    key: "把商品上带有的吊牌进行移除。",
    value: "把商品上带有的吊牌进行移除。",
  },
  {
    key: "根据客户在本网站采购商品的累计总金额设置手续费，",
    value: "根据客户在本网站采购商品的累计总金额设置手续费，",
  },
  {
    key: "根据采购总额手续费也不同，详情请参照下表。",
    value: "根据采购总额手续费也不同，详情请参照下表。",
  },
  {
    key: "*交易手续费的下调是在交易完成超过等级之后才适用。",
    value: "*交易手续费的下调是在交易完成超过等级之后才适用。",
  },
  {
    key: "例如:实际超过1000万日元以后的交易，适用4.2%的手续费。",
    value: "例如:实际超过1000万日元以后的交易，适用4.2%的手续费。",
  },
  {
    key: "大件物品需要单独报价，请与负责人联系",
    value: "大件物品需要单独报价，请与负责人联系",
  },
  {
    key: "默认为A4纸，其他类型纸张需求请联系负责人确认。",
    value: "默认为A4纸，其他类型纸张需求请联系负责人确认。",
  },
  {
    key: "大件物品我们需要单独报价。",
    value: "大件物品我们需要单独报价。",
  },
  {
    key: "专业摄影来完成，无论产品什么类型或者多复杂。",
    value: "专业摄影来完成，无论产品什么类型或者多复杂。",
  },
  {
    key: "支持各个仓库的贴标服务，比如FBA，乐天，雅虎，定制的标签也可以，请联系负责人进行确认。",
    value:
      "支持各个仓库的贴标服务，比如FBA，乐天，雅虎，定制的标签也可以，请联系负责人进行确认。",
  },
  {
    key: "定制生产取决于尺寸，颜色和所需的材料类型，您必须提供您品牌的所有元素与信息，以便我们进行预算，报价",
    value:
      "定制生产取决于尺寸，颜色和所需的材料类型，您必须提供您品牌的所有元素与信息，以便我们进行预算，报价",
  },
  {
    key: "与我们的负责人协商组装等要求",
    value: "与我们的负责人协商组装等要求",
  },
  {
    key: "请输入意见反馈",
    value: "请输入意见反馈",
  },
  {
    key: "配送单评价",
    value: "配送单评价",
  },
  {
    key: "满意度为1到5星，星级越高越满意",
    value: "满意度为1到5星，星级越高越满意",
  },
  {
    key: "服务流程是否满意",
    value: "服务流程是否满意",
  },
  {
    key: "商品质量是否符合您的预期",
    value: "商品质量是否符合您的预期",
  },
  {
    key: "商品是否完整（有无缺失、破损）",
    value: "商品是否完整（有无缺失、破损）",
  },
  {
    key: "整体服务满意度",
    value: "整体服务满意度",
  },
  {
    key: "服务人员是否能精准到位满足您的需求",
    value: "服务人员是否能精准到位满足您的需求",
  },
  {
    key: "服务人员是否态度良好",
    value: "服务人员是否态度良好",
  },
  {
    key: "服务人员是否处理问题及时",
    value: "服务人员是否处理问题及时",
  },
  {
    key: "物流满意度",
    value: "物流满意度",
  },
  {
    key: "对此物流速度是否满意",
    value: "对此物流速度是否满意",
  },
  {
    key: "物流费用是否满意",
    value: "物流费用是否满意",
  },
  {
    key: "物流服务（通关、配送等）",
    value: "物流服务（通关、配送等）",
  },
  {
    key: "ODM/OEM服务",
    value: "ODM/OEM服务",
  },
  {
    key: "服务评价",
    value: "服务评价",
  },
  {
    key: "已被使用",
    value: "ユーザー名が既に利用されています",
  },
  {
    key: "关键词",
    value: "キーワード",
  },
  {
    key: "没有找到商品，请换一个关键词试试",
    value: "没有找到商品，请换一个关键词试试",
  },
  {
    key: "图片上传中",
    value: "图片上传中",
  },
  {
    key: "图片上传",
    value: "画像添付",
  },
  {
    key: "请输入您想查找的商品名称或者淘宝与1688的商品URL",
    value: "キーワードまたは1688、タオバオの商品URL",
  },
  {
    key: "搜索",
    value: "検索",
  },
  {
    key: "标签名称",
    value: "タグ",
  },
  {
    key: "提交评价",
    value: "提交评价",
  },
  {
    key: "新增标签",
    value: "新規追加",
  },
  {
    key: "请输入标签名",
    value: "タグ",
  },
  {
    key: "option说明界面",
    value: "option说明界面",
  },
  {
    key: "上传的图片大小不能超过 10MB!",
    value: "上传的图片大小不能超过 10MB!",
  },
  {
    key: "图片格式不正确！",
    value: "图片格式不正确！",
  },
  {
    key: "请填写完评价内容再进行提交",
    value: "请填写完评价内容再进行提交",
  },
  {
    key: "确认开通",
    value: "确认开通",
  },
  {
    key: "天的有料会員？",
    value: "天的有料会員？",
  },
  {
    key: "合计金额",
    value: "合計金額",
  },
  {
    key: "附加服务说明",
    value: "附加服务说明",
  },
  {
    key: "不能全由空格组成",
    value: "不能全由空格组成",
  },
  {
    key: "国际物流说明",
    value: "国際送料",
  },
  {
    key: "手续费说明",
    value: "手数料について",
  },
  {
    key: "国际运费说明",
    value: "国際について",
  },
  {
    key: "收费会员服务",
    value: "收费会员服务",
  },
  {
    key: "全部商品",
    value: "全商品",
  },
  {
    key: "首页",
    value: "マイページ",
  },
  {
    key: "在库数",
    value: "在庫数",
  },
  {
    key: "国内送料",
    value: "国内送料",
  },
  {
    key: "スタッフ",
    value: "スタッフ",
  },
  {
    key: "备选链接",
    value: "备选链接",
  },
  {
    key: "注文書修正",
    value: "注文書修正",
  },
  {
    key: "支払い",
    value: "支払い",
  },
  {
    key: "购物车添加成功",
    value: "购物车添加成功",
  },
  {
    key: "移出收藏夹",
    value: "移出收藏夹",
  },
  {
    key: "商品详情",
    value: "商品詳細",
  },
  {
    key: "属性",
    value: "属性",
  },
  {
    key: "注文",
    value: "注文",
  },
  {
    key: "小計(元)",
    value: "小計(元)",
  },
  {
    key: "人民元対円換算",
    value: "人民元対円換算",
  },
  {
    key: "汇率从“三菱UFJ TTS レート”获取",
    value: "三菱UFJ銀行 TTS レート+0.7",
  },
  {
    key: "予想費用",
    value: "予想費用",
  },
  {
    key: "总价",
    value: "総額",
  },
  {
    key: "是否将选中的商品移除购物车",
    value: "是否将选中的商品移除购物车",
  },
  {
    key: "去订货",
    value: "注文へ",
  },
  {
    key: "更改头像",
    value: "更改头像",
  },
  {
    key: "头像上传中",
    value: "头像上传中",
  },
  {
    key: "张",
    value: "张",
  },
  {
    key: "手续费",
    value: "手数料",
  },
  {
    key: "无手续费",
    value: "无手数料",
  },
  {
    key: "商品调查 免费",
    value: "商品调查 免费",
  },
  {
    key: "会員",
    value: "会員",
  },
  {
    key: "订单手续费",
    value: "買付手数料",
  },
  {
    key: "入RAKUMART仓库费用",
    value: "入庫費用",
  },
  {
    key: "筛选",
    value: "検索",
  },
  {
    key: "保存",
    value: "保存",
  },
  {
    key: "订单手续费",
    value: "買付手数料",
  },
  {
    key: "入RAKUMART仓库费用",
    value: "入庫費用",
  },
  {
    key: "出RAKUMART仓库费用",
    value: "出庫費用",
  },
  {
    key: "简易检品",
    value: "簡易検品",
  },
  {
    key: "精细检品（表面）",
    value: "詳細検品（表）",
  },
  {
    key: "详细检品（表+里）",
    value: "詳細検品（表+裏）",
  },
  {
    key: "梱包代",
    value: "梱包代",
  },
  {
    key: "商品组套操作费用",
    value: "商品セット化作業",
  },
  {
    key: "数量和种类",
    value: "数量と種類",
  },
  {
    key: "注文書/配送書/内容",
    value: "注文書/配送書/内容",
  },
  {
    key: "请输入新密码",
    value: "新しいパスワードを入力してください",
  },
  {
    key: "请再次输入新密码",
    value: "もう一度新しいパスワードを入力してください",
  },
  {
    key: "付款",
    value: "支払い",
  },
  {
    key: "体积",
    value: "体積",
  },
  {
    key: "已产生费用",
    value: "課金費用",
  },
  {
    key: "计费天数",
    value: "課金日数",
  },
  {
    key: "天",
    value: "日",
  },
  {
    key: "和",
    value: "和",
  },
  {
    key: "的",
    value: "的",
  },
  {
    key: "个产品",
    value: "个产品",
  },
  {
    key: "商品库",
    value: "商品庫",
  },
  {
    key: "管理番号",
    value: "管理番号",
  },
  {
    key: "商品名称",
    value: "商品名",
  },
  {
    key: "请输入管理番号",
    value: "管理番号を入力してください",
  },
  {
    key: "接受",
    value: "接受",
  },
  {
    key: "提出番数",
    value: "提出番薯",
  },
  {
    key: "不良品换货",
    value: "不良品换货",
  },
  {
    key: "提交",
    value: "提出",
  },
  {
    key: "退货/取消购买",
    value: "返品/購入キャンセル",
  },
  {
    key: "请输入您的处理意见，如果您已选中多番，选中的各番将给出一样的处理意见。",
    value:
      "処理意見を入力してください。複数の番号を選択した場合、選択された各番号に同じ処理意見が表示されます。",
  },
  {
    key: "标签搜索",
    value: "タグ検索",
  },
  {
    key: "余额",
    value: "残高",
  },
  {
    key: "不可使用",
    value: "出金確認待ち",
  },
  {
    key: "待确认入金金额",
    value: "確認待ち",
  },
  {
    key: "请输入登录名",
    value: "ユーザー名を入力してください",
  },
  {
    key: "临时保存待提出",
    value: "未提出",
  },
  {
    key: "氏名",
    value: "氏名",
  },
  {
    key: "子账户管理",
    value: "サブアカウント管理",
  },
  {
    key: "帮助中心",
    value: "よくある質問",
  },
  {
    key: "RAKUMART帮助中心",
    value: "RAKUMARTよくある質問",
  },
  {
    key: "搜索结果",
    value: "検索結果",
  },
  {
    key: "请输入你的问题或者关键字",
    value: "質問内容または関連キーワード",
  },
  {
    key: "可根据订单号搜索",
    value: "注文番号検索",
  },
  {
    key: "新規社員",
    value: "新規社員",
  },
  {
    key: "仓库保管说明",
    value: "倉庫保管費用",
  },
  {
    key: "请输入氏名",
    value: "名前を入力してください",
  },
  {
    key: "长度不能超出24个字符",
    value: "长度不能超出24个字符",
  },
  {
    key: "长度不能超出99个字符",
    value: "长度不能超出99个字符",
  },
  {
    key: "实重",
    value: "実重量",
  },
  {
    key: "箱规",
    value: "箱の仕様",
  },
  {
    key: "公平的佣金，巨大的价值",
    value: "透明な手数料で輸入サポート",
  },
  {
    key: "用很少的钱利用Rakumart提供的所有服务，更好的帮助您进口",
    value:
      "Rakumartが提供するサービスをご利用いただく上で、シンプルな手数料でお客様の輸入ビジネスをサポート致します。",
  },
  {
    key: "如果在使用本网站的过程中有不方便的地方或者想要改善的地方，请填写在下方文本框里，有图片也可进行上传，我们会尽可能的改善。",
    value:
      "当サイトの使い勝手がわるいところや改善したい所がございましたら、下記の枠にご記入頂ければ、改善可能の限り対応させていただきます。",
  },
  {
    key: "改善意见",
    value: "改善要望",
  },
  {
    key: "请多多关照。",
    value: "宜しくお願い致します。",
  },
  {
    key: "紹介へ",
    value: "紹介へ",
  },
  {
    key: "订单",
    value: "注文書",
  },
  {
    key: "临时保存的订单",
    value: "一時保存（未提出）",
  },
  {
    key: "推荐给每月订购50万日元以上商品的顾客",
    value: "推荐给每月订购50万日元以上商品的顾客",
  },
  {
    key: "运输中",
    value: "运输中",
  },
  {
    key: "目前无论客户等级如何，会费支付完毕及确定后，以后的代理手续费一律为",
    value: "目前无论客户等级如何，会费支付完毕及确定后，以后的代理手续费一律为",
  },

  {
    key: "请至少选择一个问题产品",
    value: "请至少选择一个问题产品",
  },
  {
    key: "节省",
    value: "节省",
  },
  {
    key: "使用记录",
    value: "使用记录",
  },
  {
    key: "请选择",
    value: "请选择",
  },
  {
    key: "券编码",
    value: "券编码",
  },
  {
    key: "中国国内物流状态",
    value: "中国国内物流状态",
  },
  {
    key: "采购完成",
    value: "采购完成",
  },
  {
    key: "资金管理",
    value: "入出金管理",
  },
  {
    key: "账单",
    value: "入出金履歴",
  },
  {
    key: "收藏",
    value: "お気に入り",
  },
  {
    key: "協力先",
    value: "協力先",
  },
  {
    key: "点",
    value: "点",
  },
  {
    key: "等待付款的订单",
    value: "入金待ち",
  },
  {
    key: "需要支付金额",
    value: "需要支付金额",
  },
  {
    key: "立即付款",
    value: "支払いへ",
  },
  {
    key: "Excel导入",
    value: "Excel导入",
  },
  {
    key: "等待处理的问题产品",
    value: "処理待ち問題商品",
  },
  {
    key: "请先下载Excel模板表格，再上传文件",
    value: "请先下载Excel模板表格，再上传文件",
  },
  {
    key: "前往处理",
    value: "処理へ",
  },
  {
    key: "请选择上传文件",
    value: "请选择上传文件",
  },
  {
    key: "文件上传中",
    value: "文件上传中",
  },
  {
    key: "文件大小超出限制",
    value: "文件大小超出限制",
  },
  {
    key: "下载模板",
    value: "下载模板",
  },
  {
    key: "查看",
    value: "詳細",
  },
  {
    key: "点击或将EXCEL 文件拖拽到这里上传",
    value: "点击或将EXCEL 文件拖拽到这里上传",
  },
  {
    key: "等待付款的国际运费",
    value: "国際送料支払い待ち",
  },
  {
    key: "国际运费2",
    value: "国際送料",
  },
  {
    key: "日本语",
    value: "日本语",
  },
  {
    key: "授权许可",
    value: "授权许可",
  },
  {
    key: "普通流程",
    value: "普通流程",
  },
  {
    key: "商品到货检品",
    value: "商品到货检品",
  },
  {
    key: "上架仓库",
    value: "上架仓库",
  },
  {
    key: "商品从仓库取出",
    value: "商品从仓库取出",
  },
  {
    key: "打包装箱",
    value: "打包装箱",
  },
  {
    key: "确认商品",
    value: "确认商品",
  },
  {
    key: "授权方法",
    value: "授权方法",
  },
  {
    key: "负责人为您提配送单",
    value: "负责人为您提配送单",
  },
  {
    key: "配送单报价",
    value: "配送单报价",
  },
  {
    key: "当您授权给我们配送单提出权限后",
    value: "当您授权给我们配送单提出权限后",
  },
  {
    key: "当您授权给我们出金权限以及提出配送单权限后",
    value: "当您授权给我们出金权限以及提出配送单权限后",
  },
  {
    key: "对需要开通的授权内容进行确认",
    value: "对需要开通的授权内容进行确认",
  },
  {
    key: "点击授权按钮，点击“确认”授权。",
    value: "点击授权按钮，点击“确认”授权。",
  },
  {
    key: "常用收件地址与进口商信息确认好",
    value: "常用收件地址与进口商信息确认好",
  },
  {
    key: "与负责人联系",
    value: "与负责人联系",
  },
  {
    key: "授权完成",
    value: "授权完成",
  },
  {
    key: "授权RAKUMART公司提交配送委托",
    value: "授权RAKUMART公司提交配送委托",
  },
  {
    key: "负责人根据订单及配送委托书的余额给予扣款收款权限，余额不足时请再次汇款",
    value:
      "负责人根据订单及配送委托书的余额给予扣款收款权限，余额不足时请再次汇款",
  },
  {
    key: "当前状态",
    value: "当前状态",
  },
  {
    key: "取消配送委托权限",
    value: "取消配送委托权限",
  },
  {
    key: "权限许可后，已经进行了包装作业，如果想被取消的话，请尽快联系负责人。",
    value:
      "权限许可后，已经进行了包装作业，如果想被取消的话，请尽快联系负责人。",
  },
  {
    key: "余额收款支付权限撤销",
    value: "余额收款支付权限撤销",
  },
  {
    key: "可随时取消。",
    value: "可随时取消。",
  },
  {
    key: "用户协议",
    value: "利用規約",
  },
  {
    key: "未授权",
    value: "未授权",
  },
  {
    key: "订单入金，配送单入金的授权设定已经完成，衷心感谢您的信赖。",
    value: "订单入金，配送单入金的授权设定已经完成，衷心感谢您的信赖。",
  },
  {
    key: "订单入金，配送单入金的授权设定已经取消，衷心感谢您的信赖。",
    value: "订单入金，配送单入金的授权设定已经取消，衷心感谢您的信赖。",
  },
  {
    key: "常用地址设定未完成，请同时设定常用进口商地址和常用收件址。",
    value: "常用地址设定未完成，请同时设定常用进口商地址和常用收件址。",
  },
  {
    key: "配送单提出权限设定已经完成，授权许可后，已经在进行打包工作，如果想要被取消的话请务必尽早与负责人联系。",
    value:
      "配送单提出权限设定已经完成，授权许可后，已经在进行打包工作，如果想要被取消的话请务必尽早与负责人联系。",
  },
  {
    key: "常用地址",
    value: "常用地址",
  },
  {
    key: "常用进口商地址和常用收件地址相同也可以",
    value: "常用进口商地址和常用收件地址相同也可以",
  },
  {
    key: "只支持上传格式为png，jpg，jpeg，pneg文件，最大可上传三张",
    value: "対応できるファイル形式：png，jpg，jpeg，pneg最大３枚まで",
  },
  {
    key: "设定为常用收件地址",
    value: "设定为常用收件地址",
  },
  {
    key: "设定为常用进口商",
    value: "设定为常用进口商",
  },
  {
    key: "已设为常用",
    value: "已设为常用",
  },
  {
    key: "通知公告",
    value: "通知公告",
  },
  {
    key: "授权内容",
    value: "授权内容",
  },
  {
    key: "出入金详细",
    value: "入出金履歴詳細",
  },
  {
    key: "导出Excle",
    value: "导出Excle",
  },
  {
    key: "订单号/派送单号",
    value: "订单号/派送单号",
  },
  {
    key: "订单管理",
    value: "注文管理",
  },
  {
    key: "全部优惠券",
    value: "全部优惠券",
  },
  {
    key: "券类别",
    value: "券種類",
  },
  {
    key: "额度/可用次数",
    value: "额度/可用次数",
  },
  {
    key: "有效日期",
    value: "有效日期",
  },
  {
    key: "使用状态",
    value: "使用状态",
  },
  {
    key: "订单号",
    value: "注文書番号",
  },
  {
    key: "已选择",
    value: "選択中",
  },
  {
    key: "请输入订单号",
    value: "请输入订单号",
  },
  {
    key: "问题商品",
    value: "问题商品",
  },
  {
    key: "仓库",
    value: "倉庫",
  },
  {
    key: "标签设置",
    value: "ラベル設定",
  },
  {
    key: "剩余在库数量",
    value: "剩余在库数量",
  },
  {
    key: "为您的私人品牌生产定制包装，标签，定制摄影，ODM定制服务，按照您的要求进行处理，定制生产取决于尺寸，颜色和所需的材料类型，您必须提供您品牌的所有元素与信息，以便我们进行预算，报价",
    value:
      "各商品に対する個別での付属サービス依頼が可能になります、内容に応じて個別で対応させていただきます。",
  },
  {
    key: "配送单",
    value: "配送依頼書",
  },
  {
    key: "报价中配送单",
    value: "报价中配送单",
  },
  {
    key: "最低的的手续费，为您降低进口成本",
    value: "最低的的手续费，为您降低进口成本",
  },
  {
    key: "个人信息管理",
    value: "個人情報管理",
  },
  {
    key: "注册信息修改",
    value: "登録情報変更",
  },
  {
    key: "我的收货地址",
    value: "配送先管理",
  },
  {
    key: "子账户",
    value: "社員用アカウント(2 )",
  },
  {
    key: "配送先管理",
    value: "配送先管理",
  },
  {
    key: "添加进口商",
    value: "添加进口商",
  },
  {
    key: "更改密码",
    value: "更改密码",
  },
  {
    key: "预购订单",
    value: "预购订单",
  },
  {
    key: "全部订单",
    value: "全部订单",
  },
  {
    key: "报价中",
    value: "見積中",
  },
  {
    key: "采购完了",
    value: "買付完了",
  },
  {
    key: "正在购买",
    value: "買付中",
  },
  {
    key: "密码变更",
    value: "密码变更",
  },
  {
    key: "手机号变更",
    value: "手机号变更",
  },
  {
    key: "邮箱变更",
    value: "邮箱变更",
  },
  {
    key: "身份认证",
    value: "身份认证",
  },
  {
    key: "取消",
    value: "キャンセル",
  },
  {
    key: "欢迎来到",
    value: "欢迎来到",
  },
  {
    key: "确认删除选中的商品吗？",
    value: "确认删除选中的商品吗？",
  },
  {
    key: "确认删除该商品吗？",
    value: "确认删除该商品吗？",
  },
  {
    key: "长度在 1 到 64 个字符",
    value: "长度在 1 到 64 个字符",
  },
  {
    key: "长度在 1 到 200 个字符",
    value: "长度在 1 到 200 个字符",
  },
  {
    key: "残金支払い",
    value: "残金支払い",
  },
  {
    key: "銀行入金",
    value: "銀行入金",
  },
  {
    key: "残高",
    value: "残高",
  },
  {
    key: "残り",
    value: "残り",
  },
  {
    key: "支払い額",
    value: "支払い額",
  },
  {
    key: "見積金額",
    value: "見積金額",
  },
  {
    key: "注文番号",
    value: "注文番号",
  },
  {
    key: "最低入金金額",
    value: "最低入金金額",
  },
  {
    key: "*根据期间限定减价和批量价格变化的情况下，有用Rakumart不正确表示的情况，不过，因为在报价完成时被修正，请直接提交报价。",
    value:
      "*期間限定セール価格やロットによって値段が変わる場合、Rakumartで正しく表示されない場合がありますが、見積完了時に修正されますので、そのままお見積りをご提出下さいませ。",
  },
  {
    key: "开始日期",
    value: "开始日期",
  },
  {
    key: "至",
    value: "～",
  },
  {
    key: "结束日期",
    value: "结束日期",
  },
  {
    key: "交易方式",
    value: "取引方法",
  },
  {
    key: "出金额",
    value: "出金额",
  },
  {
    key: "确认入账时间",
    value: "确认入账时间",
  },
  {
    key: "入金额",
    value: "入金额",
  },
  {
    key: "残金",
    value: "残金",
  },
  {
    key: "提出时间",
    value: "提出时间",
  },
  {
    key: "注文書・配送書",
    value: "注文書・配送書",
  },
  {
    key: "注文書",
    value: "注文書",
  },
  {
    key: "配送書",
    value: "配送書",
  },
  {
    key: "内容",
    value: "内容",
  },
  {
    key: "账单类型",
    value: "账单类型",
  },
  {
    key: "搜 索",
    value: "索狩り",
  },
  {
    key: "请先选择要导出的时间范围",
    value: "出力される時間範囲を指定してください",
  },
  {
    key: "待打款",
    value: "振込待ち",
  },
  {
    key: "已拒绝",
    value: "拒否",
  },
  {
    key: "已打款",
    value: "送金済み",
  },
  {
    key: "导出excel",
    value: "エクセル導出",
  },
  {
    key: "检品中",
    value: "检品中",
  },
  {
    key: "在RAKUMART仓库中",
    value: "在RAKUMART仓库中",
  },
  {
    key: "订单状态",
    value: "注文書状態",
  },
  {
    key: "操作",
    value: "操作",
  },
  {
    key: "提出日期",
    value: "提出日",
  },
  {
    key: "发货日期",
    value: "出荷日",
  },
  {
    key: "包含订单",
    value: "関係注文書",
  },
  {
    key: "报价中订单",
    value: "見積中",
  },
  {
    key: "订单待付款",
    value: "支払い待ち",
  },
  {
    key: "所有正在进行订单",
    value: "所有正在进行订单",
  },
  {
    key: "付款日期",
    value: "入金日",
  },
  {
    key: "购买完成日期",
    value: "買付完了日",
  },
  {
    key: "购买完成",
    value: "買付完了",
  },
  {
    key: "商品数",
    value: "商品数",
  },
  {
    key: "暂无数据",
    value: "データがありません",
  },
  {
    key: "处理意见",
    value: "処理意見",
  },
  {
    key: "单价（元）",
    value: "単価（元）",
  },
  {
    key: "等待发货",
    value: "等待发货",
  },
  {
    key: "添加至收藏夹",
    value: "添加至收藏夹",
  },
  {
    key: "可用次数",
    value: "可用次数",
  },
  {
    key: "支付完成",
    value: "支付完成",
  },
  {
    key: "快捷处理",
    value: "快捷处理",
  },
  {
    key: "仅当前账号",
    value: "仅当前账号",
  },
  {
    key: "其他费用明细",
    value: "その他費用明細",
  },
  {
    key: "普通仓库",
    value: "倉庫",
  },
  {
    key: "收费仓库",
    value: "課金倉庫",
  },
  {
    key: "最终订单金额",
    value: "最終金額",
  },
  {
    key: "名义",
    value: "内容",
  },
  {
    key: "调整金额",
    value: "調整額",
  },
  {
    key: "返金额（円）",
    value: "返金額（円）",
  },
  {
    key: "追加额（円）",
    value: "追加費用（円）",
  },
  {
    key: "备选链接选择：",
    value: "备选链接选择：",
  },
  {
    key: "点击展开显示明细",
    value: "クリックして明細展開",
  },
  {
    key: "点击收起明细",
    value: "クリックして閉じる",
  },
  {
    key: "费用（元）",
    value: "费用（元）",
  },
  {
    key: "注文書",
    value: "注文書",
  },
  {
    key: "订单汇率",
    value: "订单汇率",
  },
  {
    key: "订单入金金额",
    value: "订单入金金额",
  },
  {
    key: "类目",
    value: "类目",
  },
  {
    key: "金额（元）",
    value: "金额（元）",
  },
  {
    key: "金额（円）",
    value: "金额（円）",
  },
  {
    key: "当前链接：",
    value: "当前链接：",
  },
  {
    key: "备选链接：",
    value: "备选链接：",
  },
  {
    key: "备注栏",
    value: "备注栏",
  },
  {
    key: "已选商品",
    value: "選択中商品",
  },
  {
    key: "状态",
    value: "状態",
  },
  {
    key: "店铺名",
    value: "店舗名",
  },
  {
    key: "子账户信息编辑",
    value: "子账户信息编辑",
  },
  {
    key: "备注",
    value: "備考欄",
  },
  {
    key: "使用中",
    value: "使用中",
  },
  {
    key: "配送单列表",
    value: "配送依頼書",
  },
  {
    key: "中止",
    value: "中止",
  },
  {
    key: "* 请输入6到16位的密码，可由字母数字及@符号组成，不支持空格。",
    value: "* 请输入6到16位的密码，可由字母数字及@符号组\n成，不支持空格。",
  },
  {
    key: "回到首页",
    value: "トップページへ",
  },
  {
    key: "展开列表",
    value: "展开列表",
  },
  {
    key: "收起列表",
    value: "收起列表",
  },
  {
    key: "历史已签收",
    value: "历史已签收",
  },
  {
    key: "提出配送单",
    value: "提出",
  },
  {
    key: "全部配送单",
    value: "全部配送单",
  },
  {
    key: "待发货",
    value: "配送待ち",
  },
  {
    key: "国际物流费用",
    value: "国际物流费用",
  },
  {
    key: "箱规（cm）",
    value: "箱规（cm）",
  },
  {
    key: "实际重量（kg）",
    value: "实际重量（kg）",
  },
  {
    key: "配送单单番备注",
    value: "配送单单番备注",
  },
  {
    key: "国际运输中",
    value: "国际运输中",
  },
  {
    key: "追加到配送单",
    value: "配送依頼書へ追加",
  },
  {
    key: "上架中",
    value: "確認中",
  },
  {
    key: "上架完成",
    value: "倉庫",
  },
  {
    key: "已签收",
    value: "到着作業待ち",
  },
  {
    key: "配送单已签收",
    value: "受領済み",
  },
  {
    key: "商品作业中",
    value: "到着作業中",
  },
  {
    key: "请输入配送单号搜索",
    value: "配送番号記入して検索",
  },
  {
    key: "配送单号3",
    value: "配送番号",
  },
  {
    key: "箱",
    value: "箱",
  },
  {
    key: "取消失败",
    value: "操作が失敗しました",
  },
  {
    key: "商品照片",
    value: "画像",
  },
  {
    key: "日本物流",
    value: "日本物流",
  },
  {
    key: "所在配送单箱号",
    value: "所在箱No",
  },
  {
    key: "国际运输方式",
    value: "国际运输方式",
  },
  {
    key: "箱数",
    value: "箱数",
  },
  {
    key: "去提现",
    value: "去提现",
  },
  {
    key: "剩余可用点数",
    value: "剩余可用点数",
  },
  {
    key: "已充值待确认点数",
    value: "已充值待确认点数",
  },
  {
    key: "订单支付入金",
    value: "注文書入金",
  },
  {
    key: "订单支付出金",
    value: "注文書支払い",
  },
  {
    key: "配送单支付入金",
    value: "配送依頼書入金",
  },
  {
    key: "配送单支付出金",
    value: "配送依頼書支払い",
  },
  {
    key: "客户提现",
    value: "客户提现",
  },
  {
    key: "人工调整",
    value: "手動調整",
  },
  {
    key: "开通付费会员出金",
    value: "定額会員費用",
  },
  {
    key: "配送单回退入金",
    value: "配送依頼書キャンセル入金",
  },
  {
    key: "提现中",
    value: "提现中",
  },
  {
    key: "充值",
    value: "チャージ",
  },
  {
    key: "公司名称",
    value: "公司名称",
  },
  {
    key: "公司地址",
    value: "公司地址",
  },
  {
    key: "公司营业执照",
    value: "公司营业执照",
  },
  {
    key: "银行账户",
    value: "银行账户",
  },
  {
    key: "出金金額",
    value: "出金金額",
  },
  {
    key: "SWIFT账户",
    value: "SWIFT账户",
  },
  {
    key: "汇款日期",
    value: "汇款日期",
  },
  {
    key: "汇款人",
    value: "汇款人",
  },
  {
    key: "汇款金额",
    value: "汇款金额",
  },
  {
    key: "汇款截图",
    value: "汇款截图",
  },
  {
    key: "户名",
    value: "户名",
  },
  {
    key: "请输入户名",
    value: "请输入户名",
  },
  {
    key: "提现申请原因",
    value: "出金申請原因",
  },
  {
    key: "原",
    value: "原",
  },
  {
    key: "确认提交",
    value: "确认提交",
  },
  {
    key: "余额提现明细",
    value: "残高出金詳細",
  },
  {
    key: "提现申请发起失败，余额不足，无法发起提现",
    value: "提现申请发起失败，余额不足，无法发起提现",
  },
  {
    key: "提现申请发起成功",
    value: "提现申请发起成功",
  },
  {
    key: "最晚1-2个工作日资金自动打入账户",
    value: "最晚1-2个工作日资金自动打入账户",
  },
  {
    key: "请输入提现申请原因",
    value: "出金申請の理由を入力してください",
  },
  {
    key: "请您核对以下信息是否正确!",
    value: "请您核对以下信息是否正确!",
  },
  {
    key: "交易类型",
    value: "交易类型",
  },
  {
    key: "支付方式",
    value: "支付方式",
  },
  {
    key: "收支",
    value: "收支",
  },
  {
    key: "オプション详情",
    value: "オプション详情",
  },
  {
    key: "小计（元）",
    value: "小计（元）",
  },
  {
    key: "账户余额",
    value: "账户余额",
  },
  {
    key: "发起时间",
    value: "提出時間",
  },
  {
    key: "流水号",
    value: "番号",
  },
  {
    key: "收款户名",
    value: "口座名義",
  },
  {
    key: "收款户号",
    value: "口座番号",
  },
  {
    key: "请输入口座番号",
    value: "口座番号を入力してください",
  },
  {
    key: "提现金额",
    value: "出金額",
  },
  {
    key: "银行名",
    value: "銀行名",
  },
  {
    key: "处理状态",
    value: "処理状態",
  },
  {
    key: "查看凭证",
    value: "詳細凭证",
  },
  {
    key: "下载",
    value: "下载",
  },
  {
    key: "确认关闭",
    value: "确认关闭",
  },
  {
    key: "商品金額.元",
    value: "商品金額.元",
  },
  {
    key: "国内送料.元",
    value: "国内送料.元",
  },
  {
    key: "オプション手数料.元",
    value: "オプション手数料.元",
  },
  {
    key: "商品码",
    value: "商品码",
  },
  {
    key: "标签号",
    value: "标签号",
  },
  {
    key: "请输入订单号搜索",
    value: "注文番号検索",
  },
  {
    key: "请输入关键词",
    value: "キーワードをご記入ください",
  },
  {
    key: "开始时间",
    value: "開始日",
  },
  {
    key: "选择",
    value: "選択",
  },
  {
    key: "照片",
    value: "照片",
  },
  {
    key: "商品链接",
    value: "商品链接",
  },
  {
    key: "单价",
    value: "単価",
  },
  {
    key: "商品状态",
    value: "商品状態",
  },
  {
    key: "问题写真",
    value: "問題商品写真",
  },
  {
    key: "配送单附件",
    value: "関係ファイル",
  },
  {
    key: "标签信息",
    value: "ラベル",
  },
  {
    key: "问题数",
    value: "数量",
  },
  {
    key: "问题详细",
    value: "問題点",
  },
  {
    key: "个人信息确认",
    value: "个人信息确认",
  },
  {
    key: "正常",
    value: "Normal",
  },
  {
    key: "座机",
    value: "電話",
  },
  {
    key: "添加",
    value: "添加",
  },
  {
    key: "是否提交座机与注意事项修改",
    value: "是否提交座机与注意事项修改",
  },
  {
    key: "一時保存しました。",
    value: "一時保存しました。",
  },
  {
    key: "一時保存",
    value: "一時保存",
  },
  {
    key: "提交订单",
    value: "注文を提出",
  },
  {
    key: "商品库提出订单",
    value: "商品库提出订单",
  },
  {
    key: "写真",
    value: "写真",
  },
  {
    key: "商品详细",
    value: "商品詳細",
  },
  {
    key: "调整前数据",
    value: "調整前",
  },
  {
    key: "调整后数据",
    value: "調整後",
  },
  {
    key: "请输入想搜索的URL",
    value: "商品キーワードまたはアリババ、タオバオのURL",
  },
  {
    key: "订购数量",
    value: "発注数",
  },
  {
    key: "单价(元)",
    value: "単価（元）",
  },
  {
    key: "标签",
    value: "标签",
  },
  {
    key: "备考栏",
    value: "備考欄",
  },
  {
    key: "下单数量",
    value: "下单数量",
  },
  {
    key: "添加一行",
    value: "添加一行",
  },
  {
    key: "历史标签",
    value: "历史标签",
  },
  {
    key: "关于RAKUMART",
    value: "rakumartについて",
  },
  {
    key: "请输入FBA码",
    value: "请输入FBA码",
  },
  {
    key: "商品庫から追加",
    value: "商品庫から追加",
  },
  {
    key: "保存中...",
    value: "ほぞんちゅう...",
  },
  {
    key: "当前默认地址",
    value: "当前默认地址",
  },
  {
    key: "编辑",
    value: "編集",
  },

  {
    key: "单价.元",
    value: "単価.元",
  },
  {
    key: "小计.元",
    value: "小计.元",
  },
  {
    key: "请输入1688链接或taobao链接或RAKUMART链接",
    value: "请输入1688链接或taobao链接或RAKUMART链接",
  },
  {
    key: "这是你的控制面板",
    value: "este es tu panel de control",
  },
  {
    key: "你好",
    value: "你好",
  },
  {
    key: "*请务必填写附带*的项目",
    value: "*ついている項目は必ずご記入ください",
  },
  {
    key: "入金日",
    value: "入金日",
  },
  {
    key: "*ついている項目は必ずご記入ください",
    value: "*ついている項目は必ずご記入ください",
  },
  {
    key: "入金額",
    value: "入金額",
  },
  {
    key: "入金名義カタカナ記載",
    value: "入金名義カタカナ記載",
  },
  {
    key: "カタカナ記載",
    value: "カタカナ記載",
  },
  {
    key: "入金名義",
    value: "入金名義",
  },
  {
    key: "着金予定日",
    value: "着金予定日",
  },
  {
    key: "请输入着金予定日",
    value: "请输入着金予定日",
  },
  {
    key: "请输入入金名義",
    value: "请输入入金名義",
  },
  {
    key: "*请填写整数",
    value: "*整数をご記入ください",
  },
  {
    key: "請求料金",
    value: "請求料金",
  },
  {
    key: "請求料金",
    value: "請求料金",
  },
  {
    key: "当前订单状态",
    value: "注文書状態",
  },
  {
    key: "金额变更明细",
    value: "金額変更詳細",
  },
  {
    key: "入金时数据",
    value: "入金時",
  },
  {
    key: "入金时金额",
    value: "入金時最終",
  },
  {
    key: "最新数值",
    value: "現在",
  },
  {
    key: "※ 请勿在汇款报告的备注栏中填写问题。\n因为担当不同所以不能传达。",
    value:
      "※入金報告の備考欄で質問を入れないでください。\n担当が異なりますので伝わりません。",
  },
  {
    key: "国际运费报价",
    value: "国际运费报价",
  },
  {
    key: "国际运费待支付",
    value: "国际运费待支付",
  },
  {
    key: "日本派送",
    value: "日本派送",
  },
  {
    key: "需要付款的配送单",
    value: "需要付款的配送单",
  },
  {
    key: "到达RAKUMART物流中心",
    value: "到着RAKUMART物流中心",
  },
  {
    key: "优惠券",
    value: "クーポン券",
  },
  {
    key: "待支付国际运费的订单",
    value: "待支付国际运费的订单",
  },
  {
    key: "所有订单",
    value: "すべての注文書",
  },
  {
    key: "待收货",
    value: "待收货",
  },
  {
    key: "待处理的订单",
    value: "待处理的订单",
  },
  {
    key: "编辑地址",
    value: "编辑地址",
  },
  {
    key: "选中",
    value: "选中",
  },
  {
    key: "選択中の商品",
    value: "選択中の商品",
  },
  {
    key: "是否默认",
    value: "是否默认",
  },
  {
    key: "开始时间不能晚于结束时间",
    value: "开始时间不能晚于结束时间",
  },
  {
    key: "结束时间不能早于开始时间",
    value: "结束时间不能早于开始时间",
  },
  {
    key: "结束时间",
    value: "終了日",
  },
  {
    key: "找回密码",
    value: "找回密码",
  },
  {
    key: "日元",
    value: "日本円",
  },
  {
    key: "重置",
    value: "リセット",
  },
  {
    key: "输入备注",
    value: "输入备注",
  },
  {
    key: "请输入商品名称",
    value: "请输入商品名称",
  },
  {
    key: "新增",
    value: "新增",
  },
  {
    key: "从仓库选择地址",
    value: "从仓库选择地址",
  },
  {
    key: "地址标题",
    value: "地址标题",
  },
  {
    key: "请输入地址标题",
    value: "请输入地址标题",
  },
  {
    key: "密码验证",
    value: "密码验证",
  },
  {
    key: "手机号验证",
    value: "手机号验证",
  },
  {
    key: "邮箱验证",
    value: "邮箱验证",
  },
  {
    key: "安全验证",
    value: "安全验证",
  },
  {
    key: "请再次输入新密码确认",
    value: "请再次输入新密码确认",
  },
  {
    key: "调整后option",
    value: "调整后option",
  },
  {
    key: "调整前option",
    value: "调整前option",
  },
  {
    key: "设置新密码",
    value: "设置新密码",
  },
  {
    key: "找回成功",
    value: "找回成功",
  },
  {
    key: "账号未注册",
    value: "账号未注册",
  },
  {
    key: "请输入您注册时使用的邮箱",
    value: "请输入您注册时使用的邮箱",
  },
  {
    key: "下一步",
    value: "次へ",
  },
  {
    key: "请再次输入密码",
    value: "パスワードを再入力",
  },
  {
    key: "更改成功",
    value: "更改成功",
  },
  {
    key: "国际运费待支付",
    value: "国际运费待支付",
  },
  {
    key: "西班牙派送",
    value: "西班牙派送",
  },
  {
    key: "需要付款的配送单",
    value: "需要付款的配送单",
  },
  {
    key: "国际运费待报价",
    value: "国际运费待报价",
  },
  {
    key: "订单到达RAKUMART仓库",
    value: "订单到着RAKUMART仓库",
  },
  {
    key: "临时保存的配送单",
    value: "一時保存（未提出）",
  },
  {
    key: "所有正在进行的配送单",
    value: "所有正在进行的配送单",
  },
  {
    key: "所有配送单",
    value: "すべての配送書",
  },
  {
    key: "货物装箱中",
    value: "梱包中",
  },
  {
    key: "紧急情况下可以联系负责人，应对发送。",
    value: "急ぎの場合は担当者へ連絡し、発送対応可能",
  },
  {
    key: "国际运费待付款",
    value: "支払い待ち",
  },
  {
    key: "国际物流待发货",
    value: "物流業者へ輸送中",
  },
  {
    key: "前往日本途中",
    value: "国際輸送中",
  },
  {
    key: "已经收到货物",
    value: "受け取り",
  },
  {
    key: "已完成",
    value: "已完成",
  },
  {
    key: "待支付国际运费的订单",
    value: "待支付国际运费的订单",
  },
  {
    key: "已取消",
    value: "キャンセル",
  },
  {
    key: "电子邮件",
    value: " 电子邮件",
  },
  {
    key: "在库更新",
    value: "在庫数更新",
  },
  {
    key: "如果与原始商品页面的库存数量不匹配，请点击'在库更新'",
    value: "在庫数1688，taobaoページと違う場合はクリックして更新。",
  },
  {
    key: "原商品页面的库存数为'0'时，请向负责人咨询，并向店铺确认",
    value:
      "1688，タオバオの実際ページ在庫数0の場合は担当者まで問い合わせください。",
  },
  {
    key: "手机号码",
    value: "手机号码",
  },
  {
    key: "邮箱",
    value: "メールアドレス",
  },
  {
    key: "时间",
    value: "日付",
  },
  {
    key: "出入金编号",
    value: "入出金No",
  },
  {
    key: "出入金类别",
    value: "出入金種類",
  },
  {
    key: "调整时间",
    value: "調整日付",
  },
  {
    key: "调整具体明细",
    value: "変動明細",
  },
  {
    key: "调整明细",
    value: "调整明细",
  },
  {
    key: "调整金额（元）",
    value: "调整金额（元）",
  },
  {
    key: "关联优惠券",
    value: "关联优惠券",
  },
  {
    key: "入金额（円）",
    value: "入金额（円）",
  },
  {
    key: "出金额（円）",
    value: "出金额（円）",
  },
  {
    key: "全选",
    value: "すべて選択",
  },
  {
    key: "购买中",
    value: "処理待ち",
  },
  {
    key: "说说你对本次服务的感受",
    value: "说说你对本次服务的感受",
  },
  {
    key: "已到达rakumart仓库",
    value: "已到达rakumart仓库",
  },
  {
    key: "购买数量",
    value: "购买数量",
  },
  {
    key: "请至少选择一个商品",
    value: "请至少选择一个商品",
  },
  {
    key: "是否确认删除选中的内容。",
    value: "是否确认删除选中的内容。",
  },
  {
    key: "是否确认删除选中的商品。",
    value: "是否确认删除选中的商品。",
  },
  {
    key: "当前选择的通知中，包含有未读的通知，是否确认删除选中的内容。",
    value: "当前选择的通知中，包含有未读的通知，是否确认删除选中的内容。",
  },
  {
    key: "标签选择",
    value: "标签选择",
  },
  {
    key: "查找相似",
    value: "查找相似",
  },
  {
    key: "问题产品批量回复",
    value: "一括返答",
  },
  {
    key: "两次输入的密码不一致",
    value: "两次输入的密码不一致",
  },
  {
    key: "已取消删除",
    value: "已取消删除",
  },
  {
    key: "确认删除选中的地址吗？",
    value: "确认删除选中的地址吗？",
  },
  {
    key: "商品配送信息",
    value: "商品配送信息",
  },
  {
    key: "订单初始付款",
    value: "订单初始付款",
  },
  {
    key: "有料会員天数有误",
    value: "有料会員天数有误",
  },
  {
    key: "订单中未找到该商品",
    value: "订单中未找到该商品",
  },
  {
    key: "正在获取图片信息",
    value: "正在获取图片信息",
  },
  {
    key: "代行専用",
    value: "代行専用",
  },
  {
    key: "商品规格",
    value: "商品詳細",
  },
  {
    key: "图片",
    value: "画像",
  },
  {
    key: "聊天",
    value: "チャット",
  },
  {
    key: "详情",
    value: "详细",
  },
  {
    key: "详细",
    value: "詳細",
  },
  {
    key: "问题提出时间",
    value: "提出時間",
  },
  {
    key: "店铺",
    value: "店舗名",
  },
  {
    key: "选择附加服务",
    value: "选择附加服务",
  },
  {
    key: "选择标签编号",
    value: "选择标签编号",
  },
  {
    key: "标签编号",
    value: "标签编号",
  },
  {
    key: "附件上传",
    value: "関係ファイル添付",
  },
  {
    key: "订单附件",
    value: "订单附件",
  },
  {
    key: "返回付款",
    value: "返回付款",
  },
  {
    key: "その他",
    value: "その他",
  },
  {
    key: "再提出",
    value: "再提出",
  },
  {
    key: "从购物车中导入更多产品",
    value: "从购物车中导入更多产品",
  },
  {
    key: "从购物车中导入更多产品",
    value: "从购物车中导入更多产品",
  },
  {
    key: "删除",
    value: "削除",
  },
  {
    key: "物流号",
    value: "物流号",
  },
  {
    key: "合计：",
    value: "合計：",
  },
  {
    key: "合计",
    value: "合計",
  },
  {
    key: "抵扣金额",
    value: "抵扣金额",
  },
  {
    key: "应付",
    value: "应付",
  },
  {
    key: "商品金額",
    value: "商品金額",
  },
  {
    key: "手数料",
    value: "手数料",
  },
  {
    key: "手数料について",
    value: "手数料について",
  },
  {
    key: "オプション手数料",
    value: "オプション手数料",
  },
  {
    key: "当前订单金额明细",
    value: "当前订单金额明细",
  },
  {
    key: "商品金额变动明细",
    value: "金額変動明細",
  },
  {
    key: "对比入金变动金额",
    value: "对比入金变动金额",
  },
  {
    key: "金额变动明细",
    value: "金额变动明细",
  },
  {
    key: "合計金額",
    value: "合計金額",
  },
  {
    key: "国际运输公司选择",
    value: "国際配送業者選択",
  },
  {
    key: "*一箱30KG以内、電池、水は含んだ商品は発送できません。",
    value: "*一箱30KG以内、電池、水は含んだ商品は発送できません。",
  },
  {
    key: "添加附加",
    value: "添加附加",
  },
  {
    key: "当前限制选择 3 个文件",
    value: "当前限制选择 3 个文件",
  },
  {
    key: "确定移除",
    value: "确定移除",
  },
  {
    key: "円金券",
    value: "円金券",
  },
  {
    key: "导出装箱明细",
    value: "导出装箱明细",
  },
  {
    key: "*单个文件不能超过50MB，一个订单最多上传3个文件。",
    value:
      "*ファイルサイズは最大50MB，３つまで、それ以上の場合は担当者までご連絡ください。",
  },
  {
    key: "提出数量",
    value: "数量",
  },
  {
    key: "添加附件",
    value: "アップロード",
  },
  {
    key: "附件",
    value: "付属ファイル",
  },
  {
    key: "取 消",
    value: "取 消",
  },
  {
    key: "保 存",
    value: "保 存",
  },
  {
    key: "网站属性选择",
    value: "网站属性选择",
  },
  {
    key: "人工编辑属性",
    value: "人工编辑属性",
  },
  {
    key: "属性名称",
    value: "属性名称",
  },
  {
    key: "具体属性值",
    value: "具体属性值",
  },
  {
    key: "添加属性",
    value: "添加属性",
  },
  {
    key: "属性编辑",
    value: "属性编辑",
  },
  {
    key: "注文書備考欄",
    value: "注文書備考欄",
  },
  {
    key: "业务员订单备注回复",
    value: "注文書備考欄に対する返答",
  },
  {
    key: "*对该订单有特殊要求的，可以在这边备注备注后会同步显示给业务员",
    value:
      "* 注文書に対する備考欄はこちらへご記入ください\n\n* 記載後、担当者へ共有されます",
  },
  {
    key: "人民币",
    value: "人民元",
  },
  {
    key: "订单备注栏",
    value: "注文書備考欄",
  },
  {
    key: "确定",
    value: "確定",
  },
  {
    key: "购物车为空",
    value: "购物车为空",
  },
  {
    key: "商品库为空",
    value: "商品库为空",
  },
  {
    key: "使用优惠券",
    value: "クーポン券利用",
  },
  {
    key: "优惠券使用中",
    value: "クーポン券利用中",
  },
  {
    key: "代金券",
    value: "金券",
  },
  {
    key: "免手续费",
    value: "手数料無料",
  },
  {
    key: "表格下载",
    value: "表格下载",
  },
  {
    key: "用户指南",
    value: "初めての方",
  },
  {
    key: "共",
    value: "共",
  },
  {
    key: "商品种类",
    value: "商品種類",
  },
  {
    key: "问题商品明细",
    value: "問題商品詳細",
  },
  {
    key: "在rakumart仓库数量",
    value: "在rakumart仓库数量",
  },
  {
    key: "邮编输入错误",
    value: "邮编输入错误",
  },
  {
    key: "装箱数量",
    value: "梱包数",
  },
  {
    key: "问题产品回复",
    value: "问题产品回复",
  },
  {
    key: "问题产品",
    value: "問題商品",
  },
  {
    key: "中国国内物流跟进",
    value: "中国国内状態",
  },
  {
    key: "等待购买",
    value: "買付中",
  },
  {
    key: "中国国内派送中",
    value: "中国国内配送中",
  },
  {
    key: "计费数据",
    value: "计费数据",
  },
  {
    key: "收货地址",
    value: "收货地址",
  },
  {
    key: "请输入邮编",
    value: "请输入邮编",
  },
  {
    key: "ローマ字",
    value: "ローマ字",
  },
  {
    key: "日期",
    value: "日期",
  },
  {
    key: "不能为空",
    value: "不能为空",
  },
  {
    key: "使用条款和隐私政策还没有被同意。",
    value: "Rakumart利用規約＆プライバシーポリシーを選択してください。",
  },
  {
    key: "返回重新编辑",
    value: "戻る",
  },
  {
    key: "发送方式",
    value: "发送方式",
  },
  {
    key: "物流单号",
    value: "国際追跡",
  },
  {
    key: "物流方式为空",
    value: "配送業者を選択してください",
  },
  {
    key: "配送单状态",
    value: "状態",
  },
  {
    key: "该配送单状态不支持此操作",
    value: "操作できません",
  },
  {
    key: "配送单待报价",
    value: "見積待ち",
  },
  {
    key: "配送单待付款",
    value: "支払い町",
  },
  {
    key: "待财务确认到账",
    value: "着金確認待ち",
  },
  {
    key: "待出货",
    value: "出荷待ち",
  },
  {
    key: "国际配送中",
    value: "国際配送中",
  },
  {
    key: "箱号",
    value: "箱No",
  },
  {
    key: "实际重量",
    value: "实际重量",
  },
  {
    key: "权限范围",
    value: "权限范围",
  },
  {
    key: "展开",
    value: "展开",
  },
  {
    key: "该商品因库存不足或其他原因已经找不到，请查看其他商品！",
    value: "该商品因库存不足或其他原因已经找不到，请查看其他商品！",
  },
  {
    key: "返回首页",
    value: "トップページへ",
  },
  {
    key: "去登录",
    value: "去登录",
  },
  {
    key: "手机号输入错误",
    value: "携帯電話に誤りがございます",
  },
  {
    key: "请选择要提出订单的商品",
    value: "请选择要提出订单的商品",
  },
  {
    key: "请选择要提出订单的商品",
    value: "请选择要提出订单的商品",
  },
  {
    key: "长度在 2 到 24 个字符",
    value: "长度在 2 到 24 个字符",
  },
  {
    key: "价格",
    value: "価格",
  },
  {
    key: "起批量",
    value: "最低ロット",
  },
  {
    key: "特殊要求备注",
    value: "備考欄",
  },
  {
    key: "选项",
    value: "オプション",
  },
  {
    key: "小计(元)",
    value: "小計（元）",
  },
  {
    key: "小計",
    value: "小計",
  },
  {
    key: "合计（元）",
    value: "合計（元）",
  },
  {
    key: "リセット",
    value: "リセット",
  },
  {
    key: "筛选",
    value: "検索",
  },
  {
    key: "请再次输入新密码",
    value: "请再次输入新密码",
  },
  {
    key: "付款",
    value: "付款",
  },
  {
    key: "体积",
    value: "体積",
  },
  {
    key: "已产生费用",
    value: "課金費用",
  },
  {
    key: "计费天数",
    value: "課金日数",
  },
  {
    key: "和",
    value: "和",
  },
  {
    key: "的",
    value: "的",
  },
  {
    key: "个产品",
    value: "个产品",
  },
  {
    key: "商品库",
    value: "商品庫",
  },
  {
    key: "管理番号",
    value: "管理番号",
  },
  {
    key: "商品名称",
    value: "商品名",
  },
  {
    key: "请输入管理番号",
    value: "请输入管理番号",
  },
  {
    key: "接受",
    value: "接受",
  },
  {
    key: "提出番数",
    value: "提出番数",
  },
  {
    key: "不良品换货",
    value: "不良品换货",
  },
  {
    key: "提交",
    value: "提出",
  },
  {
    key: "退货/取消购买",
    value: "退货/取消购买",
  },
  {
    key: "请输入您的处理意见，如果您已选中多番，选中的各番将给出一样的处理意见。",
    value:
      "请输入您的处理意见，如果您已选中多番，选中的各番将给出一样的处理意见。",
  },
  {
    key: "标签搜索",
    value: "标签搜索",
  },
  {
    key: "不可使用",
    value: "出金確認待ち",
  },
  {
    key: "待确认入金金额",
    value: "確認待ち",
  },
  {
    key: "请输入登录名",
    value: "ユーザー名を入力してください",
  },
  {
    key: "临时保存待提出",
    value: "未提出",
  },
  {
    key: "子账户管理",
    value: "子账户管理",
  },
  {
    key: "新規社員",
    value: "新規社員",
  },
  {
    key: "长度不能超出24个字符",
    value: "长度不能超出24个字符",
  },
  {
    key: "实重",
    value: "実重量",
  },
  {
    key: "箱规",
    value: "箱规",
  },
  {
    key: "容积重量",
    value: "容积重量",
  },
  {
    key: "国际物流单号",
    value: "国际物流单号",
  },
  {
    key: "邮箱、手机号或密码输入错误",
    value: "邮箱、手机号或密码输入错误",
  },
  {
    key: "销量",
    value: "销量",
  },
  {
    key: "已使用",
    value: "使用済み",
  },
  {
    key: "个",
    value: "个",
  },
  {
    key: "暂无订单",
    value: "注文書なし",
  },
  {
    key: "暂无配送单",
    value: "暂无配送单",
  },
  {
    key: "商品金额",
    value: "商品金額",
  },
  {
    key: "手数料（5%）",
    value: "手数料（5%）",
  },
  {
    key: "ペイパル入金の場合、さらに4％手数料が必要になりますので、予めご了承ください",
    value:
      "ペイパル入金の場合、さらに4％手数料が必要になりますので、予めご了承ください",
  },
  {
    key: "海关",
    value: "海关",
  },
  {
    key: "可用",
    value: "可用",
  },
  {
    key: "订单大于",
    value: "订单大于",
  },
  {
    key: "请输入新的邮箱",
    value: "请输入新的邮箱",
  },
  {
    key: "请输入 6 到 16 位的密码,不支持空格",
    value: "パスワードは6桁以上16桁以内に収めてください",
  },
  {
    key: "总成本详细",
    value: "总成本详细",
  },
  {
    key: "单个成本",
    value: "单个成本",
  },
  {
    key: "长度不超过25个字符",
    value: "长度不超过25个字符",
  },
  {
    key: "请输入名字罗马字",
    value: "请输入名字罗马字",
  },
  {
    key: "请输入公司名",
    value: "请输入公司名",
  },
  {
    key: "请输入邮编",
    value: "请输入邮编",
  },
  {
    key: "请输入携带電話",
    value: "请输入携带電話",
  },
  {
    key: "在库更新中",
    value: "在库更新中",
  },
  {
    key: "请输入您正常使用的邮箱地址，将用于重要的验证和通知的接收",
    value: "请输入您正常使用的邮箱地址，将用于重要的验证和通知的接收",
  },
  {
    key: "请输入您正常使用的手机号码，将用于重要的验证和通知的接收",
    value: "请输入您正常使用的手机号码，将用于重要的验证和通知的接收",
  },
  {
    key: "请输入電話",
    value: "请输入電話",
  },
  {
    key: "添加到收藏夹成功",
    value: "添加到收藏夹成功",
  },
  {
    key: "您可以在这边添加商品备注",
    value: "您可以在这边添加商品备注",
  },
  {
    key: "描述",
    value: "描述",
  },
  {
    key: "账户余额不足",
    value: "账户余额不足",
  },
  {
    key: "是否签收选中的商品？",
    value: "是否签收选中的商品？",
  },
  {
    key: "请输入标签号",
    value: "コード",
  },
  {
    key: "请输入商品码",
    value: "ASIN",
  },
  {
    key: "签收成功",
    value: "签收成功",
  },
  {
    key: "已取消签收",
    value: "已取消签收",
  },
  {
    key: "收费仓库里货物需要发货，请直接与您的负责人联系",
    value: "收费仓库里货物需要发货，请直接与您的负责人联系",
  },
  {
    key: "手续费（元）",
    value: "手续费（元）",
  },
  {
    key: "全部通知",
    value: "全部通知",
  },
  {
    key: "通知",
    value: "お知らせ",
  },
  {
    key: "删除(购物车)",
    value: "删除(购物车)",
  },
  {
    key: "删除提示",
    value: "删除提示",
  },
  {
    key: "其他",
    value: "其他",
  },
  {
    key: "成为Rakumart的会员",
    value: "rakumart会員登録",
  },
  {
    key: "立即注册",
    value: "今すぐ新規登録",
  },
  {
    key: "寻找产品下单，我们确认报价方案，您可进行付款",
    value:
      "ご希望の商品をカートに入れ、まとめたらオファーをご提出いただきます。弊社より見積提出後、支払いへ",
  },
  {
    key: "下单",
    value: "発注",
  },
  {
    key: "在我们仓库进行质量把控与存储",
    value: "弊社の中国検品センターで日本の消費者基準で検品した後再梱包します。",
  },
  {
    key: "提出发货指令，我们进行物流咨询，给您提供报价方案，由您选择国际运输并付款",
    value: "商品入庫後、発送分の商品をご指定頂ければ発送手配へ",
  },
  {
    key: "等待产品运输到您指定的地点",
    value: "輸出入通関を経てご指定の場所へお届けします",
  },
  {
    key: "工作时间",
    value: "受付時間",
  },
  {
    key: "09:30-18:30(日本時間)",
    value: "日本時間 09:30-18:30（日曜日、当社指定休日は休み）",
  },
  {
    key: "为你推荐",
    value: "お勧め",
  },
  {
    key: "全部问题",
    value: "すべて",
  },
  {
    key: "所有",
    value: "すべて",
  },
  {
    key: "请输入银行名",
    value: "请输入银行名",
  },
  {
    key: "代购",
    value: "代行サービス",
  },
  {
    key: "请输入支店番号",
    value: "支店番号を入力してください",
  },
  {
    key: "请先输入邮箱",
    value: "メールアドレスを入力してください",
  },
  {
    key: "请填写必填信息",
    value: "必須項目を入力してください",
  },
  {
    key: "请输入支店名",
    value: "支店名を入力してください",
  },
  {
    key: "请输入預金種目",
    value: "預金種目を入力してください",
  },
  {
    key: "请输入口座名義",
    value: "口座番号を入力してください",
  },
  {
    key: "请输入姓氏",
    value: "请输入姓氏",
  },
  {
    key: "无在库",
    value: "無在庫サービス（D2C）",
  },
  {
    key: "OEM",
    value: "OEM、ODMサービス",
  },
  {
    key: "待回复",
    value: "返答待ち",
  },
  {
    key: "待后续处理",
    value: "処理中",
  },
  {
    key: "已处理",
    value: "完了",
  },
  {
    key: "额外服务",
    value: "额外服务",
  },
  {
    key: "邮件箱",
    value: "邮件箱",
  },
  {
    key: "产品",
    value: "商品",
  },
  {
    key: "城市",
    value: "城市",
  },
  {
    key: "全名",
    value: " 全名",
  },
  {
    key: "请输入全名",
    value: "请输入全名",
  },
  {
    key: "Rakumart，我们帮助您从中国进口",
    value: "Rakumart，我们帮助您从中国进口",
  },
  {
    key: "Rakumart让您轻松获得从中国进口的“一站式”服务，包括工厂采购、质量检验、附加服务和国际航运。",
    value:
      "Rakumart让您轻松获得从中国进口的“一站式”服务，包括工厂采购、质量检验、附加服务和国际航运。",
  },
  {
    key: "全面、便捷的中国进口管理。",
    value: "全面、便捷的中国进口管理。",
  },
  {
    key: "专注于您的业务",
    value: "专注于您的业务",
  },
  {
    key: "可发往世界各地，优化所有物流成本",
    value: "可发往世界各地，优化所有物流成本",
  },
  {
    key: "多样化的物流选择",
    value: "多样化的物流选择",
  },
  {
    key: "为所有产品需求提供额外服务",
    value: "为所有产品需求提供额外服务",
  },
  {
    key: "为您全流程的附加服务提供解决方案",
    value: "为您全流程的附加服务提供解决方案",
  },
  {
    key: "出货完成",
    value: "出荷完成",
  },
  {
    key: "创建新帐户",
    value: "创建新帐户",
  },
  {
    key: "商品属性",
    value: "商品情報",
  },
  {
    key: "商品标题",
    value: "商品标题",
  },
  {
    key: "规格",
    value: "その他",
  },
  {
    key: "购入数据",
    value: "買付詳細",
  },
  {
    key: "颜色",
    value: "カラー",
  },
  {
    key: "选择该商品",
    value: "选择该商品",
  },
  {
    key: "回到顶部",
    value: "回到顶部",
  },
  {
    key: "你的最爱",
    value: "你的最爱",
  },
  {
    key: "资料库",
    value: "资料库",
  },
  {
    key: "标签类别",
    value: "标签種類",
  },
  {
    key: "仓库类别",
    value: "倉庫種類",
  },
  {
    key: "需支付金额：",
    value: "需支付金额：",
  },
  {
    key: "请输入CIF/NIF",
    value: "请输入CIF/NIF",
  },
  {
    key: "数据获取错误",
    value: "数据获取错误",
  },
  {
    key: "数据获取错误",
    value: "数据获取错误",
  },
  {
    key: "*根据期间限定减价和批量价格变化的情况下，有用Rakumart不正确表示的情况，不过，因为估价完成时被修正，请那样提交估价。",
    value:
      "*期間限定セール価格やロットによって値段が変わる場合、Rakumartで正しく表示されない場合がありますが、見積完了時に修正されますので、その ままお見積りをご提出下さいませ。",
  },
  {
    key: "国内配送",
    value: "国内配送",
  },
  {
    key: "运费",
    value: "中国送料",
  },
  {
    key: "到着",
    value: "到着",
  },
  {
    key: "出荷",
    value: "出荷",
  },
  {
    key: "Rakumart新会员问卷调查",
    value: "Rakumart新規会員アンケート調査",
  },
  {
    key: "承蒙关照。",
    value: "お世話になっております。",
  },
  {
    key: "衷心感谢您这次登录Rakumart。",
    value: "この度Rakumartへのご登録いただき誠にありがとうございます。",
  },
  {
    key: "今后在使用本公司的时候，请回答以下的问卷调查。",
    value:
      "今後弊社をご利用にあたり、下記のアンケート調査にお答えをお願い申し上げます。",
  },
  {
    key: "填写完之后，会根据问卷的内容安排专属的负责人。",
    value:
      "ご記入後、アンケートの内容により専属の担当者を配属させていただきます。",
  },
  {
    key: "以下填写的内容只在本公司内部使用，请放心。",
    value:
      "下記ご記入の内容は弊社内部のみでのご利用になりますので、ご安心ください。",
  },
  {
    key: "请多多关照。",
    value: "宜しくお願い致します。",
  },
  {
    key: "感谢您的填写",
    value: "ご記入いただきありがとうございます。",
  },
  {
    key: "您已填写了该问卷，请勿重复作答",
    value:
      "アンケートに既に回答いただいておりますので、重複して回答しないようお願いいたします。",
  },
  {
    key: "减免",
    value: "割引",
  },
  {
    key: "使用条件",
    value: "利用条件",
  },
  {
    key: "所有物流",
    value: "すべての物流で利用可能",
  },
  {
    key: "计费重量",
    value: "課金重量",
  },
  {
    key: "金额",
    value: "金額",
  },
  {
    key: "最低批次",
    value: "最低ロット",
  },
  {
    key: "综合(综合排名)",
    value: "総合ランキング",
  },
  {
    key: "按价格从高到低",
    value: "価格が高い順に",
  },
  {
    key: "按价格从低到高",
    value: "価格が低い順に",
  },
  {
    key: "价格从低到高",
    value: "価格が低い順に",
  },
  {
    key: "价格从高到低",
    value: "価格が高い順に",
  },
  {
    key: "价格区间",
    value: "価格帯",
  },
  {
    key: "客户备注",
    value: "備考欄",
  },
  {
    key: "业务回复",
    value: "スタッフ",
  },
  {
    key: "由1688提供部分技术服务⽀持",
    value: "1688 より、一部技術、情報サポートを受けて提供させて頂いております",
  },
  {
    key: "本次搜索由1688提供部分技术服务⽀持",
    value: "今回の検索はアリババより一部技術サポートを提供しております。",
  },
  {
    key: "日语·中文关键词或者1688、淘宝的商品及店铺URL",
    value: "日本語?中国語のキーワードまたは1688、タオバオの商品及び店舗URL",
  },
  {
    key: "没有注册账号",
    value: "アカウントが登録されていません",
  },
  {
    key: "分批付款的订单，请使用电脑打开网站进行付款，手机浏览器，APP暂时无法进行支付，请谅解。",
    value:
      "分割注文書は現在、パソコンの分割機能をご利用下さい。アプリと携帯ブラウザーは一時的にご利用不可にさせて頂きます。ご了承ください。",
  },
  {
    key: "中国国内运费预估",
    value: "中国送料",
  },
  {
    key: "日本热卖",
    value: "日本売れ筋",
  },
  {
    key: "韩国热卖",
    value: "韓国売れ筋",
  },
  {
    key: "严选工厂",
    value: "1688厳選工場",
  },
  {
    key: "最低价格",
    value: "最安価格",
  },
  {
    key: "最高价格",
    value: "最高価格",
  },
  {
    key: "销量最高", //(月销量最高)
    value: "月販売数",
  },
  {
    key: "复购率高",
    value: "リピード率",
  },
  {
    key: "最近售出",
    value: "月販売数",
  },
  {
    key: "复购率",
    value: "リピード率",
  },
  {
    key: "严选商品",
    value: "厳選商品",
  },
  {
    key: "工厂",
    value: "工厂",
  },
  {
    key: "店铺评分",
    value: "店舗評点",
  },
  {
    key: "服务保障",
    value: "サービス保障",
  },
  {
    key: "当日发货",
    value: "当日出荷",
  },
  {
    key: "24小时发货",
    value: "24時間以内出荷",
  },
  {
    key: "48小时发货",
    value: "48時間以内出荷",
  },
  {
    key: "7天内无理由退货",
    value: "7日間以内無条件返品・交換",
  },
  {
    key: "5星",
    value: "5つ星",
  },
  {
    key: "4.5星-5.0星",
    value: "4.5星~5.0星",
  },
  {
    key: "4星-4.5星",
    value: "4.0星~4.5星",
  },
  {
    key: "工厂",
    value: "工場",
  },
  {
    key: "优惠券兑换",
    value: "クーポン申請",
  },

  {
    key: "立即兑换",
    value: "今すぐ申請",
  },
  {
    key: "请填写到会登记的email地址",
    value: "参加登録したメールアドレスを記入してください",
  },
  {
    key: "请填写兑换码",
    value: "シリアルナンバーを記入してください",
  },
  {
    key: "兑换成功",
    value: "成功",
  },
  {
    key: "活动已结束,兑换失败",
    value: "イベントが終了のため、交換に失敗",
  },
  {
    key: "Email地址不在到会登记的Email集里",
    value: "メールアドレスが参加登録したメールリストにありません",
  },
  {
    key: "兑换码有误请检查",
    value: "シリアルナンバーが間違っています、確認してください",
  },
  {
    key: "兑换码已经被使用过",
    value: "シリアルナンバーは既に使用されています",
  },
  {
    key: "一个账号只能使用一个兑换码,您的账号已经使用过兑换码",
    value:
      "一つのアカウントについてシリアルナンバー1つしか使用できません、こちらのアカウントは既に申請済みです",
  },
  {
    key: "兑换失败,请稍后再试",
    value: "申請に失敗しました、後ほど再試行してください",
  },
  {
    key: "兑换失败,请稍后再试",
    value: "申請に失敗しました、後ほど再試行してください",
  },
  {
    key: "上传文件",
    value: "ファイルをアップロードする",
  },
  {
    key: "定额会员限定路线，设定12天的时限。",
    value: "定額会員限定ルートで、12日間のタイムリミットを設けます。",
  },
  {
    key: "注意:国际货物通关时产生的关税将由客人承担。",
    value: "注意：国際荷物の通関時に発生した関税はお客様のご負担となります。",
  },
  {
    key: "注意:EMS除外的配送方式是由运输公司垫付税金后再向客户收取。(有可能产生垫付手续费)",
    value:
      "EMSを除く配送方法は運送会社から税金を立て替えてからお客様に請求されます。(立替手数料が発生する可能性がございます)",
  },
  {
    key: "H5日本仓库交货流程",
    value: "H5日本倉庫納品の流れ",
  },
  {
    key: "H5交付委托书的提交步骤",
    value: "H5配送依頼書の提出手順",
  },
  {
    key: "H5聊天的使用方法",
    value: "H5チャットの使用方法",
  },
  {
    key: "仓库交付和转让",
    value: "倉庫納品＆転送",
  },
  {
    key: "报关费",
    value: "通関手数料",
  },
  {
    key: "预计费用",
    value: "予想费用",
  },
];

// replaceParameters传入数组[{key:'',value:''}]
let fanyi = (value, replaceParameters) => {
  if (store.state.fanyi == true) {
    return value;
  }
  for (let i = 0; i < langs.length; i++) {
    if (langs[i].key == value) {
      // 替换指定字符串为参数
      if (replaceParameters) {
        let str = langs[i].value;
        for (let i in replaceParameters) {
          str = str.replace(
            replaceParameters[i].key,
            replaceParameters[i].value
          );
        }
        return str;
      } else {
        return langs[i].value;
      }
    }
  }

  if (
    value &&
    !LiData.find((item) => {
      return item.key == value;
    })
  ) {
    LiData.push({ key: value, value: value });
    // console.log(LiData);
  }
  return value;
};

export default fanyi;
