import { createRouter, createWebHistory } from "vue-router";
import api from "../api/apis";
import Europess from "../api/wangZhi";
import store from "../store/index.js";
// import fanyi from '../utlis/language'

import Message from "../utlis/notice";
const routes = [
  // 首页
  {
    path: "/",
    name: "homepage",
    component: () =>
      import(
        /* webpackChunkName: "homepageIndex" */ "../view/homepageIndex/index.vue"
      ),
    meta: {
      tagTitle: "Rakumartラクマート 中国輸入 アリババ　タオバオ代行",
      tagDescription:
        "中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結",
      tagKeywords:
        '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      buDaiRakumart: true,
      keepAlive: true,
    },
  },
  /* 注册页面*/
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../view/login/register.vue"),
    meta: {
      tagTitle: "新規会員登録",

      buDaiRakumart: true,
    },
  },
  /* 注册成功页面*/
  {
    path: "/registerSuccess",
    name: "registerSuccess",
    component: () =>
      import(/* webpackChunkName: "register" */ "../view/login/register.vue"),
    meta: {
      tagTitle: "新規会員登録",
      buDaiRakumart: true,
    },
  },
  /* 商品搜索页*/
  {
    path: "/CommoditySearch",
    name: "CommoditySearch",
    component: () =>
      import(
        /* webpackChunkName: "search" */ "../view/CommoditySearch2/index.vue"
      ),
    meta: {
      tagTitle: "商品リサーチ",
      tagDescription:
        '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      tagKeywords:
        "中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結",
      buDaiRakumart: true,
      keepAlive: true,
    },
  },

  // 商品详情
  {
    path: "/ProductDetails",
    name: "ProductDetails",
    component: () =>
      import(
        /* webpackChunkName: "goodsDetail" */ "../view/ProductDetails/index.vue"
      ),
    meta: {
      // tagTitle: '商品詳細',//这里不需要写,设置title的代码已经写在商品详情里了,因为需要随商品title变化
    },
  },
  // 店铺商品列表
  {
    path: "/shopGoods",
    name: "shopGoods",
    component: () =>
      import(
        /* webpackChunkName: "shopGoods" */ "../view/shopGoods2/index.vue"
      ),
    meta: {
      tagTitle: "Rakumart-店舗情報",
      tagDescription:
        "アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。",
      tagKeywords:
        "アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart",
      buDaiRakumart: true,
    },
  },

  {
    path: "/user/index",
    name: "userIndex",
    // ../views/UserCenter/index.vue
    component: () =>
      import(/* webpackChunkName: "userindex" */ "../view/user/index.vue"),
    meta: {
      tagTitle: "マイページ",
      buDaiRakumart: true,
      requireAuth: true,
    },
    // children: [
    //   // 用户-首页
    //   {
    //     path: '/user/index',
    //     name: 'userIndex',
    //     component: () => import('../views/UserCenter/views/UserIndex/index'),
    //   },
    // ]
  },
  // 用户-仓库页面
  {
    path: "/warehouse",
    name: "warehouse",
    component: () =>
      import(/* webpackChunkName: "warehouse" */ "../view/warehouse/index.vue"),
    meta: {
      requireAuth: true,
      tagTitle: "倉庫管理",
      buDaiRakumart: true,
    },
  },
  // 用户-通知页面
  {
    path: "/user/inform",
    name: "inform",
    component: () =>
      import(/* webpackChunkName: "inform" */ "../view/user/view/inform"),
    meta: {
      requireAuth: true,
      tagTitle: "お知らせ",

      buDaiRakumart: true,
    },
  },
  //问题产品
  {
    path: "/user/issueOrder",
    name: "issueOrder",
    component: () =>
      import(
        /* webpackChunkName: "issueOrder" */ "../view/user/view/issueOrder/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "問題商品",

      buDaiRakumart: true,
    },
  },
  //问题产品
  {
    path: "/user/issueOrder",
    name: "issueOrder",
    component: () =>
      import(
        /* webpackChunkName: "issueOrder" */ "../view/user/view/issueOrder/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "問題商品",
      buDaiRakumart: true,
    },
  },
  //钱包
  {
    path: "/user/purse",
    name: "purse",
    component: () =>
      import(/* webpackChunkName: "purse" */ "../view/purse/index.vue"),
    meta: {
      requireAuth: true,
      tagTitle: "金入れ",

      buDaiRakumart: true,
    },
  },
  //充值
  {
    path: "/user/recharge",
    name: "recharge",
    component: () =>
      import(/* webpackChunkName: "recharge" */ "../view/recharge/index.vue"),
    meta: {
      requireAuth: true,
      tagTitle: "新規入金",

      buDaiRakumart: true,
    },
  },
  //出入金列表
  {
    path: "/user/churujinList",
    name: "churujinList",
    component: () =>
      import(
        /* webpackChunkName: "churujinList" */ "../view/user/view/churujinList/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "入出金履歴",

      buDaiRakumart: true,
    },
  },
  // 售后列表
  {
    path: "/user/aftersale",
    name: "aftersale",
    component: () => import("../view/aftersale/index.vue"),
    meta: {
      tagTitle: "アフターサービス",
      buDaiRakumart: true,
    },
  },
  // 2023/8/15 需要这个页面，但是不设置入口，只能通过链接跳转
  // Withdraw 提现页面
  {
    path: "/withdrawDeposit",
    name: "withdrawDeposit",
    component: () => import("../view/recharge/Withdraw.vue"),
    meta: {
      tagTitle: "残高返金明細",
      buDaiRakumart: true,
      requireAuth: true,
    },
  },
  // Memberfee 会员充值页面
  {
    path: "/Memberfee",
    name: "Memberfee",
    component: () =>
      import(
        /* webpackChunkName: "Memberfee" */ "../view/user/view/Memberfee/index.vue"
      ),
    meta: {
      tagTitle: "Rakumart 有料会員制度",

      buDaiRakumart: true,
    },
  },
  // Memberspay 会员充值余额支付页面
  {
    path: "/balancePayTollVip",
    component: () =>
      import(
        /* webpackChunkName: "balancePayTollVip" */ "../view/user/view/Memberfee/Memberspay.vue"
      ),
    name: "balancePayTollVip",
    meta: {
      tagTitle: "有料会員制度　申し込み",

      buDaiRakumart: true,
    },
  },
  //忘记密码
  {
    path: "/ForgetThePassword",
    name: "ForgetThePassword",
    component: () =>
      import(
        /* webpackChunkName: "ForgetThePassword" */ "../view/ForgetThePassword/index.vue"
      ),
    meta: {
      tagTitle: "パスワードの再発行",

      buDaiRakumart: true,
    },
  },

  // 用户-优惠券
  {
    path: "/user/coupon",
    name: "coupon",
    component: () =>
      import(/* webpackChunkName: "coupon" */ "../view/coupon/index.vue"),
    meta: {
      requireAuth: true,
      tagTitle: "クーポン券",

      buDaiRakumart: true,
    },
  },
  // 用户信息设置
  {
    path: "/user/userDetail",
    name: "userDetail",
    component: () =>
      import(
        /* webpackChunkName: "userDetail" */ "../view/user/view/usersetting/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "個人情報管理",
      buDaiRakumart: true,
    },
  },

  // 个人信息页面  Personalinformation
  {
    path: "/user/personalinformation",
    name: "personalinformation",
    component: () =>
      import(
        /* webpackChunkName: "Personalinformation" */ "../view/user/view/Personalinformation/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "個人情報管理",
      buDaiRakumart: true,
    },
  },
  // 账户设置   Accountsetting
  {
    path: "/user/accountsetting",
    name: "accountsetting",
    component: () =>
      import(
        /* webpackChunkName: "Accountsetting" */ "../view/user/view/Accountsetting/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "個人情報管理",
      buDaiRakumart: true,
    },
  },
  // Informationmodification 账户设置密码修改，更换绑定邮箱，更换绑定手机页面
  {
    path: "/user/informationmodification",
    name: "informationmodification",
    component: () =>
      import(
        /* webpackChunkName: "informationmodification" */ "../view/user/view/Informationmodification/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "個人情報管理",
      buDaiRakumart: true,
    },
  },
  // 注销账户 closeanaccount
  {
    path: "/user/closeanaccount",
    name: "closeanaccount",
    component: () =>
      import(
        /* webpackChunkName: "closeanaccount" */ "../view/user/view/Closeanaccount/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "個人情報管理",
      buDaiRakumart: true,
    },
  },
  // 用户地址管理列表
  {
    path: "/user/deliverySite",
    name: "deliverySite",
    component: () =>
      import(
        /* webpackChunkName: "deliverySite" */ "../view/user/view/addressmanagement/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "配送先管理",

      buDaiRakumart: true,
    },
  },
  // 用户地址管理，修改和删除页面
  {
    path: "/user/addressmodification",
    name: "addressmodificatio",
    component: () =>
      import(
        /* webpackChunkName: "addressmodification" */ "../view/user/view/addressmodification/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "配送先管理",

      buDaiRakumart: true,
    },
  },

  // 收藏商品页面  collectionofgoods
  {
    path: "/user/commodity",
    name: "commodity",
    component: () =>
      import(
        /* webpackChunkName: "commodity" */ "../view/user/view/commodity/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "お気に入り商品",
      buDaiRakumart: true,
    },
  },
  // 配送单列表  deliverlist
  {
    path: "/user/deliveryList",
    name: "deliverlist",
    component: () =>
      import(
        /* webpackChunkName: "deliveryList" */ "../view/user/view/deliveryList/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "配送書管理",
      buDaiRakumart: true,
    },
  },
  // 订单列表 orderlist
  {
    path: "/user/order",
    name: "orderlist",
    component: () =>
      import(
        /* webpackChunkName: "order" */ "../view/user/view/order/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "代行注文書",
      buDaiRakumart: true,
    },
  },
  //  中国国内物流列表  wuliuStatus
  {
    path: "/user/wuliuStatus",
    name: "wuliuStatus",
    component: () =>
      import(
        /* webpackChunkName: "wuliuStatus" */ "../view/user/view/wuliuStatus"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "中国国内の物流追跡",
      buDaiRakumart: true,
    },
  },
  /* 购物车列表*/
  {
    path: "/cart",
    name: "cart",
    component: () =>
      import(/* webpackChunkName: "cart" */ "../view/cart/index.vue"),
    meta: {
      requireAuth: true,
      meta: {
        tagTitle: "買い物かご",
        buDaiRakumart: true,
      },
    },
  },
  /* 订单详情*/
  {
    path: "/OrderDetails",
    name: "OrderDetails",
    component: () =>
      import(
        /* webpackChunkName: "OrderDetails" */ "../view/orderDetail/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "注文詳細",
      buDaiRakumart: true,
    },
  },
  /* 订单支付*/
  {
    path: "/payment",
    name: "payment",
    component: () =>
      import(/* webpackChunkName: "payment" */ "../view/Payment/index.vue"),
    meta: {
      requireAuth: true,
      meta: {
        tagTitle: "支払い",
        buDaiRakumart: true,
      },
    },
  },
  /* 配送单详情*/
  {
    path: "/deliveryDetails",
    name: "deliveryDetails",
    component: () =>
      import(
        /* webpackChunkName: "deliveryDetails" */ "../view/deliveryDetails/index.vue"
      ),
    meta: {
      requireAuth: true,
      tagTitle: "配送書詳細",
      tagDescription:
        '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      tagKeywords:
        "中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結",
      buDaiRakumart: true,
    },
  },
  //  箱子货物信息
  {
    path: "/deliveryEncasementDetails",
    name: "deliveryEncasementDetails",
    component: () =>
      import(
        /* webpackChunkName: "deliveryEncasementDetails" */ "../view/user/view/boxGoodsInformation"
      ),
    meta: {
      tagTitle: "配送書詳細",
      tagDescription:
        '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      tagKeywords:
        "中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結",
      buDaiRakumart: true,
    },
  },
  //  箱子货物信息
  {
    path: "/boxdetail",
    name: "boxdetail",
    component: () =>
      import(/* webpackChunkName: "boxdetail" */ "../view/boxdetail"),
    meta: {
      tagTitle: "配送書詳細",
      tagDescription:
        '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      tagKeywords:
        "中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結",
      buDaiRakumart: true,
    },
  },
  //  paypalPaymentSuccess 订单和配送单paypal 充值支付成功 页面
  {
    path: "/paypalPaymentSuccess",
    name: "paypalPaymentSuccess",
    component: () =>
      import(
        /* webpackChunkName: "paypalPaymentSuccess" */ "../view/recharge/paypalPaymentSuccess.vue"
      ),
    meta: {
      tagTitle: "支払い完了",
      buDaiRakumart: true,
    },
  },
  //  paypal支付失败页面
  {
    path: "/paypalPaymentError",
    name: "paypalPaymentError",
    component: () =>
      import(
        /* webpackChunkName: "paypalPaymentError" */ "../view/recharge/paypalPaymentError.vue"
      ),
    meta: {
      tagTitle: "支払い失敗しました",
      buDaiRakumart: true,
    },
  },
  // 问题中心答案列表页面(可以搜索到结果)
  {
    path: "/resultado-de-busqueda",
    name: "resultado-de-busqueda",
    component: () =>
      import(
        /* webpackChunkName: "resultado-de-busqueda" */ "../view/centro-de-ayudacopy/resultado-de-busqueda.vue"
      ),
  },
  // 问题中心答案列表页面(没有搜索到结果)
  {
    path: "/resultado-busqueda-no-encontrado",
    name: "resultado-busqueda-no-encontrado",
    component: () =>
      import(
        /* webpackChunkName: "encontrado" */ "../view/centro-de-ayudacopy/resultado-busqueda-no-encontrado.vue"
      ),
  },
  // QA
  {
    path: "/questionAndAnswer",
    name: "questionAndAnswer",
    component: () => import("../view/centro-de-ayudacopy/centro-de-ayuda.vue"),
  },
  // 页脚页面列表集合-会員利用規約
  {
    path: "/membershipUtilizationProtocol",
    name: "membershipUtilizationProtocol",
    component: () =>
      import(
        /* webpackChunkName: "membershipUtilizationProtocol" */ "../view/staticPage/view/membershipUtilizationProtocol.vue"
      ),
    meta: {
      tagTitle: "Rakumart 会員利用規約",

      buDaiRakumart: true,
    },
  },
  // 页脚页面列表集合-返品・返金及び免責事項
  {
    path: "/disclaimer",
    name: "disclaimer",
    component: () =>
      import(
        /* webpackChunkName: "disclaimer" */ "../view/staticPage/view/disclaimer.vue"
      ),
    meta: {
      tagTitle: "Rakumart 返品・返金及び免責事項",
      buDaiRakumart: true,
    },
  },
  // 页脚页面列表集合-プライバシーポリシー
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "privacyPolicy" */ "../view/staticPage/view/privacyPolicy.vue"
      ),
    meta: {
      tagTitle: "Rakumart プライバシーポリシー",

      buDaiRakumart: true,
    },
  },

  // 国际物流
  {
    path: "/internationalLogistics",
    name: "internationalLogistics",
    meta: {
      tagTitle: "国際送料",
      buDaiRakumart: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "internationalLogistics" */ "../view/staticPage/view/internationalLogistics.vue"
      ),
  },
  // 收费会员申请
  {
    path: "/tollVipApplyFor",
    name: "tollVipApplyFor",
    component: () =>
      import(
        /* webpackChunkName: "tollVipApplyFor" */ "../view/staticPage/view/tollVipApplyFor.vue"
      ),
    meta: {
      tagTitle: "Rakumart 有料会員制度",
      buDaiRakumart: true,
    },
  },
  // 商品详情-查看视频
  {
    path: "/ProductDetails-watchVideo",
    name: "ProductDetailsWatchVideo",
    meta: {
      tagDescription:
        "Junsheng mochila al aire libre bolsa de ventilador militar mochila de viaje X7 pez espada bolsa táctica montañismo bolsa de camuflaje 40 litros",
    },
    component: () =>
      import(
        /* webpackChunkName: "ProductDetails" */ "../view/watchVideo/index.vue"
      ),
  },
  // option公司费用介绍
  {
    path: "/option",
    name: "option",
    meta: {
      tagTitle: "rakumart オプションサービス",
      tagDescription:
        '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      tagKeywords:
        "中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結",
      buDaiRakumart: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "option" */ "../view/staticPage/view/option.vue"
      ),
  },
  // 普检精检详细说明
  {
    path: "/inspectionDetail",
    name: "inspectionDetail",
    meta: {
      tagTitle: " Rakumart 検品について",
      tagDescription:
        '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      tagKeywords:
        "中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結",
      buDaiRakumart: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "inspectionDetail" */ "../view/staticPage/view/inspectionDetail.vue"
      ),
  },
  /* 日本人公司介绍 */
  {
    path: "/japanCompanyIntroduce",
    name: "japanCompanyIntroduce",
    meta: {
      tagTitle: "Rakumart　日本会社",
      tagDescription:
        "アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。",
      tagKeywords:
        "アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart",
      buDaiRakumart: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "foot" */ "../view/staticPage/view/japanCompanyIntroduce.vue"
      ),
  },
  /* 中国公司介绍 */
  {
    path: "/chinaCompanyIntroduce",
    name: "chinaCompanyIntroduce",
    meta: {
      tagTitle: "Rakumart　中国会社",
      tagDescription:
        "アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。",
      tagKeywords:
        "アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart",
      buDaiRakumart: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "foot" */ "../view/staticPage/view/chinaCompanyIntroduce.vue"
      ),
  },
  // 仓库保管说明
  {
    path: "/storageInstructions",
    name: "storageInstructions",
    meta: {
      tagTitle: "倉庫保管費用",
      tagDescription:
        "アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。",
      tagKeywords:
        "アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart",
      buDaiRakumart: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "storageInstructions" */ "../view/staticPage/view/storageInstructions.vue"
      ),
  },
  // 用户指南
  {
    path: "/guia-de-usuario",
    name: "guia-de-usuario",
    meta: {
      tagTitle: "初めての方",
      tagDescription:
        "アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。",
      tagKeywords:
        "アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart",
      buDaiRakumart: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "usuario" */ "../view/staticPage/view/guia-de-usuario"
      ),
  },
  // 调查问卷
  {
    path: "/questionnaire",
    name: "questionnaire",
    component: () =>
      import(
        /* webpackChunkName: "questionnaire" */ "../view/questionnaire/index.vue"
      ),
    meta: {
      tagDescription:
        "アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。",
      tagKeywords:
        "アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart",
      buDaiRakumart: true,
    },
  },
  /* 会员等级单独页面*/
  {
    path: "/comisiones-rakumart",
    name: "comisiones-rakumart",
    meta: {
      tagTitle: "Rakumart　手数料",
      tagDescription:
        "アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。",
      tagKeywords:
        "アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart",
      buDaiRakumart: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "foot" */ "../view/staticPage/view/comisiones-rakumart.vue"
      ),
  },
  /* 网易严选商品页*/
  {
    path: "/neteaseClassifyDetails",
    name: "neteaseClassifyDetails",
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "neteaseClassifyDetails" */ "../view/neteaseClassifyDetails/index.vue"
      ),
  },
  // 售后详情
  {
    path: "/aftersaledetails",
    name: "aftersaledetails",
    component: () =>
      import(
        /* webpackChunkName: "aftersaledetails" */ "../view/aftersaledetails/index.vue"
      ),
    meta: {
      tagTitle: " アフターサービスの詳細",
      buDaiRakumart: true,
    },
  },
  // rakumart&1688
  {
    path: "/accountSynchronization",
    name: "accountSynchronization",
    component: () =>
      import(
        /* webpackChunkName: "accountSynchronization" */ "../view/staticPage/view/accountSynchronization.vue"
      ),
    meta: {
      tagTitle: " アフターサービスの詳細",
      buDaiRakumart: true,
    },
  },
  // 优惠券兑换
  {
    path: "/couponRedemption",
    name: "couponRedemption",
    component: () =>
      import(
        /* webpackChunkName: "couponRedemption" */ "../view/user/view/couponRedemption/index.vue"
      ),
    meta: {
      tagTitle: " アフターサービスの詳細",
      buDaiRakumart: true,
    },
  },
  // 仓库交付和转让
  {
    path: "/forwardToWarehouse",
    name: "forwardToWarehouse",
    component: () =>
      import(
        /* webpackChunkName: "couponRedemption" */ "../view/forwardToWarehouse/index.vue"
      ),
    meta: {
      tagTitle: " アフターサービスの詳細",
      buDaiRakumart: true,
    },
  },
];
const router = createRouter({
  routes,
  history: createWebHistory(),
  base: "/",
});
// // // 循环输出所有路由
// let logPath = () => {
//   // "<url> <loc>" + element2.path + "</loc> <priority>0.8</priority> <lastmod>2022-10-18T02:03:18+00:00</lastmod> <changefreq>Always</changefreq> </url>"
//   let data = []
//   routes.forEach(element1 => {
//     if (element1.meta && (element1.meta.tagTitle || element1.meta.buDaiRakumart || element1.meta.tagDescription)) {
//       data.push({
//         path: element1.path,
//         tagTitle: element1.meta.tagTitle,
//         buDaiRakumart: element1.meta.buDaiRakumart,
//         tagDescription: element1.meta.tagDescription
//       })
//     }

//     if (element1.children && element1.children.length != 0) {
//       element1.children.forEach(element2 => {
//         // data.push(element2.path)
//         if (element2.meta && (element2.meta.tagTitle || element2.meta.buDaiRakumart || element2.meta.tagDescription)) {
//           data.push({
//             path: element2.path,
//             tagTitle: element2.meta.tagTitle,
//             buDaiRakumart: element2.meta.buDaiRakumart,
//             tagDescription: element2.meta.tagDescription
//           })
//         }

//       });
//     }
//   });
//   console.log(data);
// }
// logPath()
let urlOrigin = location.origin;
let openLogin = function (to) {
  //打开登录弹窗
  store.commit("changeshowLoginDia", {
    success: () => {
      if (store.state.activePage.indexOf("ProductDetails") != -1) {
        router.push(store.state.activePage);
      } else {
        router.go(0);
      }
    },
    cancel: () => {
      if (store.state.activePage.indexOf("ProductDetails") != -1) {
      } else {
        location.href = "/";
      }
    },
  });

  store.commit("getactivePage", to.fullPath);
};

function changetag(to) {
  if (to.meta.buDaiRakumart) {
    return (document.title = to.meta.tagTitle);
  }
  if (to.name == "ProductDetails") {
    return;
  }
  // 假如设置了meta就使用meta
  if (to.meta.tagTitle) {
    return (document.title = "Rakumart-" + to.meta.tagTitle);
  } else {
    return (document.title = "Rakumart-" + to.name);
  }
}

// 设置页面标识符,因为谷歌在爬取商品详情页面时爬取不到接口返回数据,所以需要加一个唯一的页面标示符以区分页面,否则会提示重定向
function setOnlyName(to) {
  document.getElementsByName("onlyName")[0].innerText = to.fullPath;
}

// 设置网站的tagDescription 推广用
function changeDescription(to) {
  // 假如设置了meta就使用meta
  document.getElementsByName("description")[0].content =
    to.meta.tagDescription ||
    "アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。";
  document.getElementsByName("keywords")[0].content =
    to.meta.tagKeywords ||
    "アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart";
}

// 商品详情页面只能登录的情况下进入
function come_product_details(to) {
  if (to.name == "ProductDetails" && !store.state.userInfo) {
    return false;
  }
  return true;
}
router.beforeEach((to, from, next) => {
  to_info = to;
  setOnlyName(to);
  changetag(to);
  changeDescription(to);
  if (!come_product_details(to)) {
    return openLogin(to);
  }
  store.commit("changeshowLoginDia", false); //初始化登录弹窗状态
  // 假如路由里面带有token就把token赋予本地
  if (to.query.token) {
    localStorage.setItem("japan_user_token", to.query.token);
  }

  // changetag(to)
  // changeDescription(to)
  if (to.matched.some((record) => record.meta.requireAuth)) {
    // 判断该路由是否需要登录权限
    if (localStorage.getItem("japan_user_token")) {
      let diapyong = false;
      // 为了加快页面加载速度,如果有登录信息就先跳转页面,用户信息的获取和验证异步进行
      if (store.state.userInfo) {
        diapyong = true;
        store.commit("getlast_router", from);
        next();
      }
      //判斷是否拥有token
      api.EuropegetUserInfo().then((res) => {
        // 登录失效
        if (res.code != 0) {
          // 需要登录并且已经登录但是token失效了就清空数据并打开登录弹窗
          store.commit("userData", null);
          localStorage.removeItem("japan_user_token");
          // 有需要验证的用户中心
          Message.error(res.msg);
          openLogin(to);
          return;
        } else {
          //需要登录并且已经登录了token正常的情况下跳转并更新用户信息
          store.commit("userData", res.data); // 用户信息存储
          if (!diapyong) {
            store.commit("getlast_router", from);
            next();
          }
        }
      });
      // saveUserRequest('https://rakumart.es' + to.fullPath)
    } else {
      // 需要登录但是没有登录的情况下打开弹窗
      openLogin(to);
    }
  } else {
    // 不需要登录的路由直接跳转
    // saveUserRequest('https://rakumart.es' + to.fullPath)
    // 如果登录了就更新登录数据
    if (localStorage.getItem("japan_user_token")) {
      api.EuropegetUserInfo().then((res) => {
        // 登录失效
        if (res.code != 0) {
        } else {
          //需要登录并且已经登录了token正常的情况下跳转并更新用户信息
          store.commit("userData", res.data); // 用户信息存储
        }
      });
    }
    store.commit("getlast_router", from);
    next();
  }
});
router.afterEach((to, from, next) => {
  if (to.name != "CommoditySearch") {
    window.scrollTo(0, 0);
  }

  // 首页时开始计时
  if (to.name == "homepage") {
    if (!sessionStorage.getItem("startTimestamp")) {
      sessionStorage.setItem("startTimestamp", Date.now());
    }
  } else {
    calculateStayDuration();
  }

  // 爆品商品详情页时开始计时
  if (
    to.name == "ProductDetails" &&
    to.query.recommendedGoods &&
    to.query.recommendedGoods == "true"
  ) {
    if (!sessionStorage.getItem("startTimestamp_hotGoods")) {
      sessionStorage.setItem("startTimestamp_hotGoods", Date.now());
    }
  } else {
    calculateStayDuration_hotGoods();
  }

  // 跳转店铺详情时统计跳转店铺详情数量
  if (to.name == "shopGoods") {
    if (location.host.indexOf(".com") !== -1) {
      __bl.sum("店铺详情页访问量统计");
    }
  }
});

//
let to_info = {};
document.addEventListener("visibilitychange", () => {
  // if (document.visibilityState === "visible") {

  // }
  // 关闭或者跳转页面时结束计时并上传
  if (document.visibilityState === "hidden") {
    calculateStayDuration();
    calculateStayDuration_hotGoods();
  }
});

let calculateStayDuration = () => {
  sessionStorage.setItem("endTimestamp", Date.now());
  if (
    sessionStorage.getItem("startTimestamp") &&
    (sessionStorage.getItem("endTimestamp") -
      sessionStorage.getItem("startTimestamp")) /
      1000 >
      1.5
  ) {
    // console.log(
    //   (sessionStorage.getItem('endTimestamp') - sessionStorage.getItem('startTimestamp')) / 1000,
    //   "停留时间"
    // );

    if (location.host.indexOf(".com") !== -1) {
      __bl.avg(
        "用户停留在rakumart首页停留平均时长统计",
        (sessionStorage.getItem("endTimestamp") -
          sessionStorage.getItem("startTimestamp")) /
          1000
      );
    }

    // console.log(to_info.name);
    if (to_info.name == "homepage") {
      sessionStorage.setItem(
        "startTimestamp",
        sessionStorage.getItem("endTimestamp")
      );
    } else {
      sessionStorage.setItem("startTimestamp", "");
    }
  }
};

let calculateStayDuration_hotGoods = () => {
  sessionStorage.setItem("endTimestamp_hotGoods", Date.now());
  if (
    sessionStorage.getItem("startTimestamp_hotGoods") &&
    (sessionStorage.getItem("endTimestamp_hotGoods") -
      sessionStorage.getItem("startTimestamp_hotGoods")) /
      1000 >
      1.5
  ) {
    console.log(
      (sessionStorage.getItem("endTimestamp_hotGoods") -
        sessionStorage.getItem("startTimestamp_hotGoods")) /
        1000,
      "停留时间"
    );

    if (location.host.indexOf(".com") !== -1) {
      __bl.avg(
        "爆品商品详情页停留平均时长统计",
        (sessionStorage.getItem("endTimestamp_hotGoods") -
          sessionStorage.getItem("startTimestamp_hotGoods")) /
          1000
      );
    }

    // console.log(to_info.query.recommendedGoods);
    if (
      to_info.name == "ProductDetails" &&
      to_info.query.recommendedGoods &&
      to_info.query.recommendedGoods == "true"
    ) {
      sessionStorage.setItem(
        "startTimestamp_hotGoods",
        sessionStorage.getItem("endTimestamp_hotGoods")
      );
    } else {
      sessionStorage.setItem("startTimestamp_hotGoods", "");
    }
  }
};

///////////////////////////////
// router.beforeEach((to, from, next) => {
//   const title = to.meta && to.meta.title;
//   if (title) {
//     document.title = title;
//   }
//   next();
// });
export { router };
