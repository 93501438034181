<template>
  <div id="app">
    <div class="yuan" v-if="benDi" @click="qieHuan">
      切换{{ $store.state.showLoginDia }} {{ $route.meta.keepAlive }}
    </div>
    <loginDialog />
    <!-- vue3.0配置 -->
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component
          :is="Component"
          :key="$route.name"
          v-if="$route.meta.keepAlive"
        />
      </keep-alive>
      <component
        :is="Component"
        :key="$route.name"
        v-if="!$route.meta.keepAlive"
      />
    </router-view>
  </div>
  <!-- 聊天 -->
  <chat />
  <!-- 调查问卷 -->
  <questionnaireDIalog />
</template>

<script>
import chat from "./components/chat";
import { getCurrentInstance, ref } from "vue";
import store from "./store/index";
import loginDialog from "./components/loginDialog";
import questionnaireDIalog from "./view/questionnaire/components/questionnaireDIalog.vue";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const time = ref(false);
    document.addEventListener("click", () => {
      if (!time.value) {
        //这是为了确保在页面上的点击事件后面执行
        setTimeout(() => {
          proxy.$store.dispatch("getTimMessageUnreadNum");
          time.value = true;
          setTimeout(() => {
            time.value = false;
          }, 180000);
        }, 100);
      }
    });
    document.addEventListener("scroll", () => {
      if (!time.value) {
        proxy.$store.dispatch("getTimMessageUnreadNum");
        time.value = true;
        setTimeout(() => {
          time.value = false;
        }, 180000);
      }
    });
    return {};
  },
  beforeCreate() {
    if (!!sessionStorage.getItem("state")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("state"))
        )
      );
    }
  },
  created() {
    window.addEventListener("unload", this.saveState); //监听页面刷新
    // 更改标签
    // console.log(this.$store.state.TagTitle);
    // document.getElementsByName("description")[0].content =
    //   this.$store.state.TagDescription;
    this.commonExchangeRate();
  },
  mounted() {},
  computed: {
    benDi() {
      return (
        ["8080", "8081", "8082"].includes(location.port) ||
        (this.$store.state.userInfo &&
          [2000025, 2000021].indexOf(this.$store.state.userInfo.user_id) != -1)
      );
    },
  },
  components: {
    chat,
    loginDialog,
    questionnaireDIalog,
  },
  methods: {
    saveState() {
      sessionStorage.setItem("state", JSON.stringify(this.$store.state));
    },
    qieHuan() {
      store.commit("changeData", !store.state.fanyi);
      // this.$store.commit("changeshowLoginDia", !this.$store.state.showLoginDia);
    },
    commonExchangeRate() {
      // 获取汇率
      this.$api.commonExchangeRate().then((res) => {
        this.$store.commit("getexchangeRate", res.data.rmb_to_jpy);
      });
    },
  },
};
</script>

<style lang="scss">
/* @import './styles/theme.css'; */
.yuan {
  position: fixed;
  top: 40%;
  right: 30px;
  width: 80px;
  font-size: 40px;
  height: 80px;
  border: solid 1px;
  z-index: 999;
  cursor: pointer;
}

body {
  font-size: 24px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  /* height: 100%; */
  min-height: 100vh;
  /* background-image: url('./logo.png'); */
}

* {
  font-family: Meiryo, "Microsoft YaHei" !important;
  box-sizing: border-box;
  font-size: 20px;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
}

body,
#app {
  height: 100%;
}

input[type="text"],
input[type="password"],
input[type="tel"] {
  border-radius: 6px;
  width: 100%;
  font-size: 16px;
  transition: 0.3s;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  padding: 10px;

  &:focus {
    border-color: #1e2997;
  }
}

button {
  border: none;
}

input[type="checkbox"] {
  $checkBoxSize: 24px;
  flex: 0 0 $checkBoxSize;
  -webkit-appearance: none;
  // 这里设置大小的话会破坏一些页面的协调性所以不在这里设置
  // transform: scale(1.5);
  width: $checkBoxSize;
  height: $checkBoxSize;
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:checked {
    background: #b4272b;
    border: none;
  }

  &:checked::after {
    content: "";

    position: absolute;
    background: transparent;
    border: #fff solid 1.5px;
    border-top: none;
    border-right: none;
    height: 2px;
    width: 40%;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

.van-stepper {
  min-width: 146px;
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;

  button {
    flex: 0 0 53px;
    height: 53px;
    background: #f2f2f2;
    border-radius: 6px;
  }

  button:before {
    width: 13px !important;
    height: 2px;
  }

  button:after {
    width: 2px !important;
    height: 13px;
  }

  input {
    flex: 1;
    width: 80px;
    flex: 0 0 80px;
    padding: 0;
    height: 53px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    margin: 0 5px;
  }
}

:deep().van-checkbox__icon {
  width: 20px;
  height: 20px;
}

.van-icon {
  font-family: vant-icon !important;
}

.van-action-sheet__header {
  text-align: left !important;
  padding-left: 20px;
}
</style>
<style>
.van-popup--top {
  border-radius: 6px;
  font-size: 24px !important;
  padding: 25px 60px;
  line-height: 39px !important;
  width: 682px;
  margin: 26px 31px !important;
}

.van-notify--danger {
  background: #fde0e3 !important;
  border: 1px solid #e7e7e7 !important;
  color: #ff526b !important;
}
</style>
<style lang="scss">
.van-swipe {
  user-select: text !important;
}
.headBtnGroup {
  left: unset !important;
  right: 30px !important;
  margin-top: 15px !important;

  .van-popover__content {
    border-radius: 6px;
    color: white;
  }

  * {
    font-size: 28px;
  }

  .van-popover__action {
    // border-bottom: solid 1px white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.9);
    height: 82px;

    &:active {
      background-color: rgba(0, 0, 0, 0.7) !important;
    }

    &:after {
      position: absolute;
      box-sizing: border-box;
      content: " ";
      margin: 0 20px;
      pointer-events: none;
      top: -50%;
      right: -50%;
      bottom: -50%;
      left: -50%;
      border-bottom: 2px solid #bcbcbc;
      transform: scale(0.5);
    }
  }

  .van-popover__action-icon {
    margin-right: 20px;
  }

  .van-popover__action-text {
    flex: unset;

    &:after {
      border: none;
    }
  }
}
</style>
