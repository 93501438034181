import {
  createStore
} from 'vuex'


import api from '../api/apis'
export default createStore({
  state: {
    activePage: '',
    last_router: "",//上一个路由
    userInfo: null, // 登入信息
    userInfoDetail: {}, //登入信息详情
    message: null, //站内信通知
    cartCount: 0, //购物车数量
    unreadMessage: 0,//未读数量
    searchInfo: '', //搜索关键词
    searchInfoCN: '',
    searchImg: '', //搜索关键图
    exchangeRate: 0, //汇率
    kaishisousuo: false,
    showChat: false, //聊天框显示
    leftSidebarNum: [{
      num: 0
    }, {
      num: 0
    }, {
      num: 0
    }, {
      num: 0
    }, {
      num: 0
    }], //订单菜单侧边栏数量统计
    pageLoading: null, //是否显示加载中
    goodsList: [{}], //顶部搜索框选项
    goodsListActiveId: '',
    showChinese: false, //是否显示中文
    ordersSumList: {
      temporary: 0,
      delete: 0,
      purchased: 0,
      purchase: 0,
      obligation: 0,
      offer: 0,
      all: 0,
    }, //商品列表各种类商品数据
    sidebarNumList: {
      订单列表: {
        进行中订单: 0,
      },
      问题产品列表: {
        未完成: 0,
      },
      仓库列表: {
        待提出: 0,
        待装箱: 0,
        总数: 0,
      },
      发票: {
        历史发票: 0
      },
      配送单列表: {
        行中配送单: 0,
      },
      国际物流列表: {
        已发货未签收: 0,
      },
    },
    favoriteGoodsCount: 0,
    topScrollMoreHeight: 0, //页面是否滑动到一定高度
    TagTitle: 'RAKUMART',
    fanyi: false,
    QASearchHistore: [],//QA页面的搜索历史
    optionList: [], //附加服务列表
    TagDescription: 'Ahorra tiempo y costes al importar desde China. Accede al catálogo de miles de proveedores y disfruta de nuestra plataforma de importación todo en 1.',
    showLoginDia: false,
    scrollMethods: [],
    odata: {}, // 订单详情数据
    homeCategoriesData: [],//网易严选分类数据
    searchList: []//qa搜索结果列表
  },
  getters: {
    token: state => state.token
  },
  mutations: {
    setsearchList(state, data) {
      state.searchList = data
    },
    sethomeCategoriesData(state, data) {
      state.homeCategoriesData = data
    },
    getQASearchHistore(state, data) {
      state.showLoginDialog = data
    },
    add(state, data) {
      state.showLoginDia = data
    },
    changeshowLoginDia(state, data) {
      state.showLoginDia = data
    },
    changeoptionList(state, data) {
      state.optionList = data
    },
    changeshowChat(state, data) {
      state.showChat = data
    },
    changeData(state, data) {
      state.fanyi = data
    },
    changeGoodsName50Str(state, data) {
      state.goodsName50Str = data
    },
    changeTagDescription(state, data) {
      state.TagDescription = data
    },
    changeTagTitle(state, data) {
      state.TagTitle = data
    },
    settopScrollMoreHeight(state, data) {
      state.topScrollMoreHeight = data
    },
    showChinese(state, data) {
      state.showChinese = data
    },
    goodsListActiveId(state, data) {
      state.goodsListActiveId = data
    },
    getgoodsList(state, data) {
      state.goodsList = data
    },
    pageLoading(state, data) {
      state.pageLoading = data
    },
    kaishisousuo(state, data) {
      state.kaishisousuo = data
    },
    getexchangeRate(state, data) {
      state.exchangeRate = data
    },
    getactivePage(state, data) {
      state.activePage = data
    },
    // 搜索关键图
    getsearchImg(state, data) {
      state.searchImg = data
    },
    // 搜索关键词
    getsearchInfo(state, data) {
      state.searchInfo = data
    },
    getsearchInfoCN(state, data) {
      state.searchInfoCN = data
    },
    getlast_router(state, data) {
      state.last_router = data
    },
    /* 获取登录信息 */
    userData(state, data) {
      //  console.log(data)
      state.userInfo = data
      if ( location.host.indexOf('.com') !== -1) {
        __bl.setConfig({
          uid: data.operation_id
        });
      }
    },
    /* 获取登录信息 */
    userInfo(state, data) {

      state.userInfo = data

    },

    // 站内信通知
    getMessage(state, data) {
      state.message = data
    },
    /* 退出登录 */
    outUser(state) {
      state.userInfo = null
      localStorage.removeItem("token");
      state.message = null
      window.location.reload()
    },
    // 汇率
    rateData(state, data) {
      state.rate = data
    },
    // 客户会员中心数据
    getUserIndex(state, data) {
      state.userIndex = data
    },
    // 存储关键词
    setQ(state, data) {
      state.q = data
    },
    // excelOrder 下单
    getexcelOrder(state, data) {
      state.excelOrder = data
    },
    // 购物车数量
    setcartCount(state, data) {
      state.cartCount = data
    },
    setunreadMessage(state, data) {
      state.unreadMessage = data
    },
    // 购物车数量
    sidebarNumList(state, data) {
      state.sidebarNumList = data
    },
    //获取每个订单数量
    getOrdersSum(state, data) {
      state.ordersSumList = data
    },
    //获取订单菜单侧边栏数量统计
    getLeftSidebarNum(state, data) {
      state.leftSidebarNum = data
    },
    // 获取收藏商品数量
    getfavoriteGoodsCount(state, data) {
      state.favoriteGoodsCount = data
    }
  },
  actions: {
    // 获取左侧用户菜单列表
    sidebarNumList({
      commit
    }) {
      let japan_user_token = localStorage.getItem('japan_user_token')
      if (japan_user_token) {
        api.sidebarNum().then((res) => {
          commit('sidebarNumList', res.data)
        });
      }
    },
    //获取每个订单数量
    getOrdersSum({
      commit
    }) {
      let japan_user_token = localStorage.getItem('japan_user_token')
      if (japan_user_token) {
        api.ordersSum().then((res) => {
          commit('getOrdersSum', res.data)
        });
      }
    },
    //获取订单菜单侧边栏数量统计
    getLeftSidebarNum({
      commit
    }) {
      let japan_user_token = localStorage.getItem('japan_user_token')
      if (japan_user_token) {
        api.leftSidebarNum().then((res) => {
          commit('getLeftSidebarNum', res.data)
        });
      }
    },
    //获取收藏商品数量
    getfavoriteGoodsCount({
      commit
    }) {
      let japan_user_token = localStorage.getItem('japan_user_token')

      if (japan_user_token) {
        api
          .favoriteGoodsList({
            page: 1,
            pageSize: 99999999999999999999999999999999999999999999999999999,
          })
          .then((res) => {
            commit('getfavoriteGoodsCount', res.data.data.length)
          })
          .catch((err) => { });


      }
    },
    // 获取购物车数量
    goodsToCartNum({
      commit
    }) {
      let japan_user_token = localStorage.getItem('japan_user_token')
      if (japan_user_token) {
        api.goodsToCartNum().then(res => {
          if (res.code == 0) {
            commit('setcartCount', res.data)
          } else {
            commit('setcartCount', 0)
          }
        })
      }
    },
    // 获取未读消息数量
    getTimMessageUnreadNum({
      commit
    }) {
      let japan_user_token = localStorage.getItem('japan_user_token')
      if (japan_user_token) {
        api.getTimMessageUnreadNum().then(res => {
          if (res.code == 0) {
            commit('setunreadMessage', res.data.messageUnreadNum)
          } else {
            commit('setunreadMessage', 0)
          }
        })
      }
    },
    // 获取用户资料
    EuropegetUserInfo({
      commit
    }) {
      api.EuropegetUserInfo().then(res => {
        //(res);
        if (res.code == 0) {
          commit('userData', res.data)
        } else {
          commit('userData', null)
        }
      })

    }
  },
  modules: {}
})