import { apiAxios } from "../axios";

import Europess from "../wangZhi";

let Europe = Europess + "/client/";
export default {
  // 获取跨境1688运费
  goodsFreightPredict: (data) =>
    apiAxios("post", Europe + "goodsFreightPredict", data),
  // 获取店铺全部商品
  getShopAllGoods: (data) => apiAxios("post", Europe + "shopAllGoods", data),
  // 图片搜索商品
  imgSearchFengGoods: (data) =>
    apiAxios("post", Europe + "imgSearchFengGoods", data),
  // 关键词搜索分销商品
  searchFengGoods: (data) => apiAxios("post", Europe + "searchFengGoods", data),
  //图片搜索分销商品上传base64
  uploadImagePic: (data) =>
    apiAxios("post", "https://api-landingpage.rakumart.cn/api/cross", data),
  //上传完的文件转Base64
  getBase64: (data) =>
    apiAxios("post", Europess + "/plugin/wpi/getBase64", data),
  // 商品详情页面信息
  goodsParticulars: (data) =>
    apiAxios("post", Europe + "goodsParticulars", data),
  // 仅获取商品的规格属性及不同SKU单价
  getGoodsAttribute: (data) =>
    apiAxios("post", Europe + "goodsAttribute", data),
  // 获取店铺推荐商品
  getShopRecommendedGoods: (data) =>
    apiAxios("post", Europe + "getShopRecommendedGoods", data),
  // 订单预览(提出前)
  orderPreview: (data) => apiAxios("post", Europe + "order.orderPreview", data),

  // 订单金额计算
  calculateOrder: (data) => apiAxios("post", Europe + "calculateOrder", data),
  // 商品附加服务列表(option)
  optionList: (data) => apiAxios("post", Europe + "order.optionList", data),
  // tag（标签）列表
  orderTagList: (data) => apiAxios("post", Europe + "order.orderTagList", data),
  // 提出订单&&临时保存
  orderCreate: (data) => apiAxios("post", Europe + "order.orderCreate", data),
  // 订单列表
  orderList: (data) => apiAxios("post", Europe + "order.orderList", data),
  // 正式订单软删除
  orderDelete: (data) => apiAxios("post", Europe + "order.orderDelete", data),
  // 订单详情
  orderDetail: (data) => apiAxios("post", Europe + "order.orderDetail", data),
  // 订单预览从购物车追加商品数据展示
  orderAddCartGoods: (data) =>
    apiAxios("post", Europe + "order.previewAddBeCart", data),
  // 订单预览从商品库追加商品数据展示
  orderAddInventoryGoods: (data) =>
    apiAxios("post", Europe + "order.previewAddBeInventory", data),
  // 订单商品单番金额明细
  orderOneGoodsFee: (data) =>
    apiAxios("post", Europe + "orderOneGoodsFee", data),
  //查询某商品是否已收藏
  favoriteGoodsCheckIn: (data) =>
    apiAxios("post", Europe + "favorite.goodsCheckIn", data),
  // 查询某店铺是否已收藏
  favoriteShopCheckIn: (data) =>
    apiAxios("post", Europe + "favorite.shopCheckIn", data),
  // 正式订单编辑(目前仅支持待付款订单)
  orderEdit: (data) => apiAxios("post", Europe + "order.orderEdit", data),
  // 订单支付界面数据
  orderPayment: (data) => apiAxios("post", Europe + "order.payData", data),
  // 余额支付订单
  orderBalancePayment: (data) =>
    apiAxios("post", Europe + "orderBalancePayment", data),
  // 银行汇款支付订单
  orderBankPayment: (data) =>
    apiAxios("post", Europe + "orderBankPayment", data),
  // 获取国际物流支持的运输方式
  internationalLogisticsTransportation: (data) =>
    apiAxios("post", Europe + "order.internationalLogisticsList", data),
  // 通过订单查询配送单概况
  onePorderOverview: (data) =>
    apiAxios("post", Europe + "order.onePorderOverview", data),
  // 配送单支付界面数据
  storagePOrderPayment: (data) =>
    apiAxios("post", Europe + "porder.payData", data),
  // 配送单支持使用的优惠券
  usableDiscount: (data) =>
    apiAxios("post", Europe + "porder.usableDiscount", data),
  // 配送单装箱明细
  porderBoxDetail: (data) =>
    apiAxios("post", Europe + "porder.porderBoxDetail", data),
  // 配送单装箱明细导出
  porderBoxDetailExcel: (data) =>
    apiAxios("post", Europe + "dataDownload.porderBoxDetailExcel", data),
  // 余额支付配送单
  storagePOrderBalancePayment: (data) =>
    apiAxios("post", Europe + "porder.balancePayOrder", data),
  // 从普通仓库追加商品
  porderPreviewAddBeStockAuto: (data) =>
    apiAxios("post", Europe + "porder.porderPreviewAddBeStockAuto", data),
  // 银行转账支付配送单
  storagePOrderBankPayment: (data) =>
    apiAxios("post", Europe + "porder.bankPayOrder", data),
  // 配送单可选支付方式列表
  porderPayMethodList: (data) =>
    apiAxios("post", Europe + "porder.payMethodList", data),
  // 修改配送单
  porderporderEdit: (data) =>
    apiAxios("post", Europe + "porder.porderEdit", data),
  // 订单详情下载,形式发票下载
  downloadOrderDetail: (data) =>
    apiAxios("post", Europe + "download.orderDetail", data),
  // 物流运费模拟计算接口
  orderLogisticsPriceTest: (data) =>
    apiAxios(
      "post",
      Europess + "/api/common/" + "order.logisticsPriceTest",
      data
    ),
  // 生成信用卡在线支付Api
  createPayApi: (data) =>
    apiAxios("post", Europess + "/client/createPayApi", data),
  // 添加独立地址
  addressStore: (data) =>
    apiAxios("post", Europess + "/client/user.addressStore", data),
  // 配送单提出前预览
  porderSubmitPreview: (data) =>
    apiAxios("post", Europe + "porder.porderPreview", data),
  // 修改订单地址
  updateOrderAddressId: (data) =>
    apiAxios("post", Europess + "/client/user.updateOrderAddressId", data),
  // 替换商品
  replaceGoods: (data) => apiAxios("post", Europe + "order.replaceGoods", data),

  // 修改订单物流方式
  updateOrderLogistics: (data) =>
    apiAxios("post", Europe + "updateOrderLogistics", data),
  // 余额支付订单
  balancePayOrder: (data) =>
    apiAxios("post", Europe + "order.balancePayOrder", data),
  // 可选支付方式列表
  payMethodList: (data) =>
    apiAxios("post", Europe + "order.payMethodList", data),
  //银行转账支付订单
  bankPayOrder: (data) => apiAxios("post", Europe + "order.bankPayOrder", data),
  //订单金额变动明细
  orderAmountChangeDetail: (data) =>
    apiAxios("post", Europe + "order.orderAmountChangeDetail", data),
  //查询单番商品标准手续费-费率
  orderGetServiceRate: (data) =>
    apiAxios("post", Europe + "order.getServiceRate", data),
  // tag（标签）列表
  getOrderTagList: (data) =>
    apiAxios("post", Europe + "order.orderTagList", data),
  //装箱中列表
  encasementList: (data) =>
    apiAxios("post", Europe + "wms.encasementList", data),
};
