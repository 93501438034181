let timeout = null; // 创建一个标记用来存放定时器的返回值
let canRun = true;
import md5 from "js-md5";
import OSS from "ali-oss";
import api from "../api/apis.js";
import { specifiedFormatDate } from "@/utlis/date.js";
import { router } from "@/router/index.js";
import store from "../store/index.js";
import messageJs from "@/utlis/notice.js";

export default {
  toCommodityDetails: (goods_id, shop_type, recommendedGoods, source) => {
    router.push(
      "/ProductDetails?goods_id=" +
        goods_id +
        "&fromPlatform=" +
        shop_type +
        "&recommendedGoods=" +
        recommendedGoods +
        "&source=" +
        source
    );
    // location.href = "/ProductDetails?goods_id=" + goods_id + '&fromPlatform=' + shop_type

    // location.reload()
    // router.push({
    //     path: "/ProductDetails",
    //     query: {
    //         goods_id: data
    //     }
    // });
    // location.reload()
    // let routeUrl = router.resolve({
    // 	path: "/ProductDetails",
    // 	query: {
    // 		goods_id: data
    // 	}
    // });
    // window.open(routeUrl.href);
  },

  toPage: (url) => {
    // location.href = url
    router.push(url);
  },
  findIndexLast(data, conditions) {
    for (let i = data.length; i > 0; i--) {
      if (conditions(data[i - 1])) {
        return i - 1;
      }
    }
    return -1;
  },
  setUserID() {
    let str = `rakumart-${(store.state.userInfo || {}).operation_id}`;
    return md5(str);
  },

  closeWindow: () => {
    let userAgent = navigator.userAgent;
    if (
      userAgent.indexOf("Firefox") != -1 ||
      userAgent.indexOf("Chrome") != -1
    ) {
      window.location.href = "about:blank";
      window.close();
    } else {
      window.opener = null;
      window.open("", "_self");
      window.close();
    }
  },
  routerToPage: (url, isBlank) => {
    // router.push(url)
    if (isBlank) {
      window.open(url, "_blank");
    } else {
      // console.log('CommoditySearch');
      if (url == "/register" || url.indexOf("CommoditySearch") != -1) {
        // router.push(url)
        // return location.reload()
        return (location.href = url);
      }
      router.push(url);
    }
  },
  judgeClient() {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //判断是否是 android终端
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
    // console.log("是否是Android：" + isAndroid); //true,false
    // console.log("是否是iOS：" + isIOS);
    if (isAndroid) {
      return "Android";
    } else if (isIOS) {
      return "IOS";
    } else {
      return "PC";
    }
  },
  unique: (arr) => {
    //数组去重
    return Array.from(new Set(arr));
  },
  //去除一个数组中与另一个数组中的值相同的元素
  quChu: (a, b) => {
    //数组去重
    let c = [];
    for (let i of a) {
      if (b.indexOf(i) === -1) {
        c.push(i);
      }
    }
    return c;
  },
  //   将数组转换为onum个一组
  changeArr: (oarr, onum) => {
    const arr = oarr;
    const len = arr.length;
    let result = [];
    const sliceNum = onum; // 这个值代表几个一组

    for (let i = 0; i < len / sliceNum; i++) {
      result.push(arr.slice(i * sliceNum, (i + 1) * sliceNum));
    }
    // 补足不足onum个的最后一个数组
    // for(let j = 0; j < (sliceNum - len % sliceNum); j++) {
    //     result[result.length - 1].push({})
    // }

    return result;
  },
  /**
   * 函数防抖
   * 触发事件后在n秒后执行，如果n秒内又触发事件，则重新计算时间
   */
  debounce(fn, wait = 1000) {
    let timer;
    return function () {
      let context = this;
      let args = arguments;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, wait);
    };
  },

  /**
   * 函数节流
   * 触发事件立即执行，但在n秒内连续触发则不执行
   */
  throttle(fn, wait, options = {}) {
    let timer;
    let previous = 0;
    let throttled = function () {
      let now = +new Date();
      // remaining 不触发下一次函数的剩余时间
      if (!previous && options.leading === false) previous = now;
      let remaining = wait - (now - previous);
      if (remaining < 0) {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        previous = now;
        fn.apply(this, arguments);
      } else if (!timer && options.trailing !== false) {
        timer = setTimeout(() => {
          previous = options.leading === false ? 0 : new Date().getTime();
          timer = null;
          fn.apply(this, arguments);
        }, remaining);
      }
    };
    return throttled;
  },
  httpRequest(file, ImSuccess) {
    // 新上传文件
    let size = file.size;
    api.getUploadToken().then((tokenres) => {
      ////console.log('事件名',res)
      if (tokenres.code != 0) return messageJs.error(tokenres.msg);
      //接下来的操作
      let upToken = tokenres.data;
      let rawname = file.name.split(".")[1];
      let fileUp = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: "oss-cn-hangzhou",
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: upToken.AccessKeyId,
        accessKeySecret: upToken.AccessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: upToken.SecurityToken,
        refreshSTSToken: async () => {
          // 向您搭建的STS服务获取临时访问凭证。
          const info = await fetch("your_sts_server");
          return {
            accessKeyId: info.accessKeyId,
            accessKeySecret: info.accessKeySecret,
            stsToken: info.stsToken,
          };
        },
        // 刷新临时访问凭证的时间间隔，单位为毫秒。
        refreshSTSTokenInterval: 300000,
        // 填写Bucket名称。
        bucket: "rakumart-test",
      });

      if (size > 1000000 && window.location.href.includes("rakumart.com.br")) {
        //这是分片上传
        fileUp
          .multipartUpload(
            `${specifiedFormatDate({
              format: "yyyymmddhhminss",
            })}${this.getRandomNumber(10000, 999999)}.${rawname}`,
            file,
            {
              progress: (p, cpt, res) => {
                // 为中断点赋值。
                abortCheckpoint = cpt;
                console.log(cpt);

                // 获取上传进度。
                console.log("progress=====", p);
              },
            }
          )
          .then((imgOBJ) => {
            // console.log(imgOBJ);
            // .split("?")[0]是为了去掉oss返回链接上自动加的uploadId参数
            imgOBJ.res.data = imgOBJ.res.requestUrls[0].split("?")[0];
            ImSuccess(file, imgOBJ.res);
          });
      } else {
        //这是简单上传
        fileUp
          .put(
            `${specifiedFormatDate({
              format: "yyyymmddhhminss",
            })}${this.getRandomNumber(10000, 999999)}.${rawname}`,
            file
          )
          .then((imgOBJ) => {
            // console.log(imgOBJ);
            // .split("?")[0]是为了去掉oss返回链接上自动加的uploadId参数
            imgOBJ.res.data = imgOBJ.res.requestUrls[0].split("?")[0];
            ImSuccess(imgOBJ.res, file);
          });
      }
    });
  },
  // 根据数字匹配tag
  matchTag(num) {
    if (num && !isNaN(num)) {
      let arr = [
        "①",
        "②",
        "③",
        "④",
        "⑤",
        "⑥",
        "⑦",
        "⑧",
        "⑨",
        "⑩",
        "⑪",
        "⑫",
        "⑬",
        "⑭",
        "⑮",
        "⑯",
        "⑰",
        "⑱",
        "⑲",
        "⑳",
      ];
      return arr[num - 1] || num;
    }
    return num;
  },
  //   加密字符串
  toCode(str) {
    //加密字符串
    //定义密钥，36个字母和数字
    var key = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var l = key.length; //获取密钥的长度
    var a = key.split(""); //把密钥字符串转换为字符数组
    var s = "",
      b,
      b1,
      b2,
      b3; //定义临时变量
    for (var i = 0; i < str.length; i++) {
      //遍历字符串
      b = str.charCodeAt(i); //逐个提取每个字符，并获取Unicode编码值
      b1 = b % l; //求Unicode编码值得余数
      b = (b - b1) / l; //求最大倍数
      b2 = b % l; //求最大倍数的于是
      b = (b - b2) / l; //求最大倍数
      b3 = b % l; //求最大倍数的余数
      s += a[b3] + a[b2] + a[b1]; //根据余数值映射到密钥中对应下标位置的字符
    }
    return s; //返回这些映射的字符
  },
  // 解密字符串
  fromCode(str) {
    //定义密钥，36个字母和数字
    var key = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var l = key.length; //获取密钥的长度
    var b,
      b1,
      b2,
      b3,
      d = 0,
      s; //定义临时变量
    s = new Array(Math.floor(str.length / 3)); //计算加密字符串包含的字符数，并定义数组
    b = s.length; //获取数组的长度
    for (var i = 0; i < b; i++) {
      //以数组的长度循环次数，遍历加密字符串
      b1 = key.indexOf(str.charAt(d)); //截取周期内第一个字符串，计算在密钥中的下标值
      d++;
      b2 = key.indexOf(str.charAt(d)); //截取周期内第二个字符串，计算在密钥中的下标值
      d++;
      b3 = key.indexOf(str.charAt(d)); //截取周期内第三个字符串，计算在密钥中的下标值
      d++;
      s[i] = b1 * l * l + b2 * l + b3; //利用下标值，反推被加密字符的Unicode编码值
    }
    b = eval("String.fromCharCode(" + s.join(",") + ")"); //用fromCharCode()算出字符串
    return b; //返回被解密的字符串
  },
  // 日本站日元计算限制(舍弃小数取整数)
  roundNumber(num) {
    if (num && typeof Number(num) !== "number") {
      return num;
    }
    return Math.round(num);
  },
  //  将数字四舍五入，传入参数:数字，小数位数
  ceil(Num, wei) {
    let ni = 2;
    if (wei || (wei != undefined && wei == 0)) {
      ni = wei;
    }
    if (Num && !isNaN(Num)) {
      return Number(Number(Math.round(Num * 10 ** ni) / 10 ** ni).toFixed(ni));
      // return Math.round(Num * 100) / 100
    }

    return Num;
  },
  // 查找数组中的对应值
  // {
  //     keyName,  比对的对象里key的名字
  //     valueName,比对的对象里value的名字
  //     list, 对象数组
  //     key,  key的值
  //     type, 输出格式 传object输出{key,value}格式
  // }
  findCorrespondingValue(data) {
    if (data) {
      if (!data.keyName) {
        data.keyName = "key";
      }
      if (!data.valueName) {
        data.valueName = "value";
      }
      let findcon = data.list.find((item) => {
        return item[data.keyName] == data.key;
      });
      if (data.type != "object") {
        if (!findcon) {
          return data.key;
        }
        return findcon[data.valueName];
      } else {
        if (!findcon) {
          return { key: data.key, value: data.key };
        }
        return { key: findcon[data.keyName], value: findcon[data.valueName] };
      }
    }
  },
  // 跳转到指定元素，传入元素的ref或者id，如this.$refs.hfdh
  maoDian(element, orderHeight) {
    let toTop = element.offsetTop;
    if (orderHeight) {
      toTop = element.offsetTop + orderHeight;
    }
    window.scrollTo(0, toTop);
  },

  downloadIamge(imgsrc, name) {
    // 下载图片地址和图片名
    var image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      var context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      var url = canvas.toDataURL("image/png"); // 得到图片的base64编码数据
      var a = document.createElement("a"); // 生成一个a元素
      var event = new MouseEvent("click"); // 创建一个单击事件
      a.download = name || "photo"; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = imgsrc;
  },
  // 把价格两位小数后的文字缩小
  twoSmall(value, fontSize) {
    //(value, value % 1 > 0);
    if (value % 1 > 0) {
      let arr = value.toString().split(",");
      return "<span>" + arr[0] + ".</span><span style='font-size:'" + fontSize
        ? fontSize
        : "20" + "'px'>" + arr[1] + "</span>";
    }
    return value;
  },
  // 欧元价格千分位
  EURNumSegmentation(Num) {
    if (Num == 0) {
      return Num;
    }
    if (!Num) {
      return Num;
    }

    let re = /\d(?=(?:\d{3})+\b)/g;
    // let num = String(Num).replace(/./, ',')
    let num = String(Num).replace(/\./g, ",");
    num = String(num).replace(re, "$&.");
    return num;
  },
  // 深度克隆
  deepClone(obj) {
    // 对常见的“非”值，直接返回原来值
    if ([null, undefined, NaN, false].includes(obj)) return obj;
    if (typeof obj !== "object" && typeof obj !== "function") {
      //原始类型直接返回
      return obj;
    }
    var o = this.isArray(obj) ? [] : {};
    for (let i in obj) {
      if (obj.hasOwnProperty(i)) {
        o[i] = typeof obj[i] === "object" ? this.deepClone(obj[i]) : obj[i];
      }
    }
    return o;
  },
  huilvqianf(Num) {
    // let num = String(Num).replace(/./, ',')
    let num = String(Num).replace(/\./g, ",");
    return num;
  },

  // 日元价格千分位
  JPYNumSegmentation(Num) {
    if (!Num && Num != 0) return "";
    let re = /\d(?=(?:\d{3})+\b)/g;
    Num = String(Num).replace(re, "$&,");
    return Num;
  },
  // 人民币价格千分位
  RMBNumSegmentation(Num) {
    if (!Num && Num != 0) return "";
    let re = /\d(?=(?:\d{3})+\b)/g;
    // let num = String(Num).replace(/./, ',')
    // let num = (String(Num).replace(/\./g, ','));
    Num = String(Num).replace(re, "$&,");
    return Num;
  },
  //日本站人民币计算限制(保留两位小数四舍五入)
  rMBCalculation(num) {
    if (num && !isNaN(num)) {
      return Number(Number(num).toFixed(2));
    }
    return num || "";
  },
  /* 防抖节流函数 */

  //!!!!这里一定要把变量设在外面，不然会失效！我搞了好久才搞明白！
  debounce(fn) {
    return function () {
      clearTimeout(timeout); // 每当用户输入的时候把前一个 setTimeout clear 掉
      timeout = setTimeout(() => {
        // 然后又创建一个新的 setTimeout, 这样就能保证输入字符后的 interval 间隔内如果还有字符输入的话，就不会执行 fn 函数
        fn.apply(this, arguments);
      }, 2000);
    };
  },
  // 计算对象数组中某一属性的总和
  sumValues(propName, objArr) {
    return objArr.reduce((accumulator, currentValue) => {
      return accumulator + currentValue[propName];
    }, 0);
  },

  throttle(fn) {
    return function () {
      if (!canRun) return;
      canRun = false;
      setTimeout(function () {
        fn.apply(this, arguments);
        canRun = true;
      }, 3000);
    };
  },
  // 将每个单词首字母转换成大写
  trunUpperCase(v) {
    return v
      .trim()
      .toLowerCase()
      .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
  },
  //西班牙时间
  timeE(v) {
    if (v)
      return (
        v.substr(8, 2) +
        "-" +
        v.substr(5, 2) +
        "-" +
        v.substr(0, 4) +
        " " +
        v.substr(10)
      );
  },
  // 生成随机数
  getRandomNumber(max, min) {
    let x = Math.floor(Math.random() * (max - min + 1)) + min;
    return x;
  },
  japanStrReplace(e) {
    if (!e) return e;
    let sNum = e.toString(); //先转换成字符串类型
    sNum = sNum.replace(/１/g, "1");
    sNum = sNum.replace(/２/g, "2");
    sNum = sNum.replace(/３/g, "3");
    sNum = sNum.replace(/４/g, "4");
    sNum = sNum.replace(/５/g, "5");
    sNum = sNum.replace(/６/g, "6");
    sNum = sNum.replace(/７/g, "7");
    sNum = sNum.replace(/８/g, "8");
    sNum = sNum.replace(/９/g, "9");
    sNum = sNum.replace(/０/g, "0");
    sNum = sNum.replace(/＠/g, "@");
    return sNum;
  },
  isOfficialWeb() {
    return location.host.indexOf(".com") !== -1;
  },
  statistics_page_sum(v) {
    if (location.host.indexOf(".com") !== -1) {
      __bl.sum(v);
    }
  },
  statisticsNumberOfOrders(arr, type) {
    let numInfo = {
      num1: 0, //通过词搜下单的商品个数
      num2: 0, //通过图搜下单的商品个数
      num3: 0, //通过链搜下单的商品个数
      num4: 0, //通过分类搜下单的商品个数
      num5: 0, //通过首页爆品榜单下单的商品个数
      num6: 0, //通过搜索（词搜、图搜、链搜、品类搜）下单的商品个数
    };
    arr.forEach((item) => {
      if (item.trace != undefined && item.trace != "") {
        let trace = JSON.parse(item.trace);
        if (trace.type == "index") {
          numInfo.num5++;
        } else {
          if (trace.isJxhy == true) {
            numInfo.num6++;
          }
          switch (trace.type) {
            case "link":
              numInfo.num3++;
              break;
            case "classify":
              numInfo.num4++;
              break;
            case "img":
              numInfo.num2++;
              break;
            case "keyword":
              numInfo.num1++;
              break;
          }
        }
      }
    });
    // console.log('上传统计', numInfo, arr);
    if (location.host.indexOf(".com") !== -1) {
      if (numInfo.num1 > 0) {
        __bl.sum(
          type == "send"
            ? "通过词搜下单的商品个数统计"
            : "通过词搜下单并付款的商品个数统计"
        );
      }
      if (numInfo.num2 > 0) {
        __bl.sum(
          type == "send"
            ? "通过图搜下单的商品个数统计"
            : "通过图搜下单并付款的商品个数统计",
          numInfo.num2
        );
      }
      if (numInfo.num3 > 0) {
        __bl.sum(
          type == "send"
            ? "通过链搜下单的商品个数统计"
            : "通过链搜下单并付款的商品个数统计",
          numInfo.num3
        );
      }
      if (numInfo.num4 > 0) {
        __bl.sum(
          type == "send"
            ? "通过分类搜下单的商品个数统计"
            : "通过分类搜下单并付款的商品个数统计",
          numInfo.num4
        );
      }
      if (numInfo.num5 > 0) {
        __bl.sum(
          type == "send"
            ? "通过首页爆品榜单下单的商品个数统计"
            : "通过首页爆品榜单下单并付款的商品个数统计",
          numInfo.num5
        );
      }
      if (numInfo.num6 > 0) {
        __bl.sum(
          type == "send"
            ? "通过搜索（词搜、图搜、链搜、品类搜）下单的商品个数统计"
            : "通过搜索（词搜、图搜、链搜、品类搜）下单并付款的商品个数统计",
          numInfo.num6
        );
      }
      if (type != "send") {
        localStorage.removeItem("paySubmitGoodsList");
      }
    }
  },
  // 检查参数是否为空
  checkParams(value) {
    if (value === "" || value === undefined || value === null) {
      return false;
    }
    return true;
  },
  // 检查参数是否为空(为空数组时也返回false)
  checkParamsNullArray(value) {
    if (value === "" || value === undefined || value === null) {
      return false;
    }
    if (Array.isArray(value) && !value.length) {
      return false;
    }
    return true;
  },
};
