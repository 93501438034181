let data = [];

// data.forEach(element => {
//   if (element.key == element.value) {
//     console.log(element);
//   }
// });

// 去重
// var uniqueArr = [];
// data.filter(function (item) {
//   var key = item.key;
//   var existItem = uniqueArr.find(function (uitem) {
//     return uitem.key === key;
//   });
//   if (!existItem) {
//     uniqueArr.push(item);
//   }
// });
// console.log(uniqueArr);
export default data;
