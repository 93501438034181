<template>
  <div class="loadimgBox">
    <img
      v-show="imgStatus == 1"
      v-if="lazyload || lazyload != undefined"
      alt=""
      v-lazy="src"
      @load="onLoaded"
      :onerror="onError"
      lazy="loaded"
    />
    <img v-else alt="" :src="src" />
    <svg
      v-if="imgStatus == 0 && lazyload"
      t="1696907245331"
      class="loadingIcon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="4062"
      width="48"
      height="48"
    >
      <path
        d="M486.4 102.4a25.6 25.6 0 1 1 51.2 0v170.666667a25.6 25.6 0 1 1-51.2 0V102.4z m208.213333 42.0864a25.6 25.6 0 0 1 44.373334 25.6l-85.333334 147.797333a25.6 25.6 0 0 1-44.373333-25.6l85.333333-147.797333z m159.300267 140.526933a25.6 25.6 0 0 1 25.6 44.373334l-147.797333 85.333333a25.6 25.6 0 0 1-25.6-44.373333l147.797333-85.333334zM921.6 486.4a25.6 25.6 0 1 1 0 51.2h-170.666667a25.6 25.6 0 1 1 0-51.2h170.666667z m-42.0864 208.213333a25.6 25.6 0 1 1-25.6 44.373334l-147.797333-85.333334a25.6 25.6 0 0 1 25.6-44.373333l147.797333 85.333333z m-140.526933 159.300267a25.6 25.6 0 0 1-44.373334 25.6l-85.333333-147.797333a25.6 25.6 0 0 1 44.373333-25.6l85.333334 147.797333zM537.6 921.6a25.6 25.6 0 1 1-51.2 0v-170.666667a25.6 25.6 0 1 1 51.2 0v170.666667z m-208.213333-42.0864a25.6 25.6 0 1 1-44.373334-25.6l85.333334-147.797333a25.6 25.6 0 0 1 44.373333 25.6l-85.333333 147.797333zM170.0864 738.986667a25.6 25.6 0 0 1-25.6-44.373334l147.797333-85.333333a25.6 25.6 0 0 1 25.6 44.373333l-147.797333 85.333334zM102.4 537.6a25.6 25.6 0 1 1 0-51.2h170.666667a25.6 25.6 0 1 1 0 51.2H102.4z m42.0864-208.213333a25.6 25.6 0 0 1 25.6-44.373334l147.797333 85.333334a25.6 25.6 0 0 1-25.6 44.373333l-147.797333-85.333333zM285.013333 170.0864a25.6 25.6 0 1 1 44.373334-25.6l85.333333 147.797333a25.6 25.6 0 0 1-44.373333 25.6l-85.333334-147.797333z"
        fill="#999999"
        p-id="4063"
      ></path>
    </svg>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const props = defineProps({
  src: {
    default: "",
  },
  lazyload: Boolean,
});
const imgStatus = ref(0); //0-加载中 1-加载成功 2-加载失败
//------------------------ methods -------------------------------------
// 加载成功事件
const onLoaded = () => {
  imgStatus.value = 1;
};
// 加载失败事件
const onError = () => {
  imgStatus.value = 2;
};
//------------------------ pageLoad -------------------------------------
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.loadimgBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
img {
  width: 100%;
  height: 100%;
}
.loadingIcon {
  /* 旋转的动画无限循环 */
  animation: rotate 3s infinite;
  /* 原图大小不变，旋转中心为图片中心点 */
  transform-origin: center center;
}

/* 定义旋转动画 */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
