// 根据传入的元素创建一个不可见的临时元素,根据这个临时元素赋予传入元素高度
let setEl = function (el, fir) {
  var styles = window.getComputedStyle(el); //获取元素css列表
  let str = el.value;
  var tmpDiv = document.createElement("textarea"); //创建临时元素
  let elheight = 0;
  if (fir) {
    // 在加载成功的时候获取输入框初始高度,防止后续生成临时元素时混淆
    elheight = styles.getPropertyValue("height");
  }
  tmpDiv.style.position = "absolute"; // 设置临时元素定位属性，避免影响页面布局
  tmpDiv.style.visibility = "hidden"; // 隐藏临时元素
  tmpDiv.innerHTML = str; // 设置要计算的字符串
  tmpDiv.style.lineHeight = styles.getPropertyValue("line-height");
  tmpDiv.style.height = elheight;
  tmpDiv.style.padding = styles.getPropertyValue("padding"); // 设置临时元素内边距
  tmpDiv.style.fontSize = styles.getPropertyValue("font-size"); // 设置临时元素字体大小
  tmpDiv.style.width = styles.getPropertyValue("width"); // 设置临时元素宽度
  document.body.appendChild(tmpDiv); // 添加到页面中
  tmpDiv.style.overflow = "scroll";
  tmpDiv.style.top = "200px";
  tmpDiv.style.left = 0;
  tmpDiv.style.border = "solid";
  var height = tmpDiv.scrollHeight; // 获取临时元素高度
  document.body.removeChild(tmpDiv); // 从页面中移除临时元素
  el.style.height = `${(height || tmpDiv.style.lineHeight) + 5}px`; //赋予el高度
};
let loadingBox = document.createElement("div");
loadingBox.style.position = "absolute";
loadingBox.style.top = "0";
loadingBox.style.left = "0";
loadingBox.style.width = "100%";
loadingBox.style.height = "100%";
loadingBox.style.display = "flex";
loadingBox.style.justifyContent = "center";
loadingBox.style.alignItems = "center";
loadingBox.style.zIndex = "10";
loadingBox.style.background = "rgba(255,255,255,0.5)";
loadingBox.innerHTML =
  '<span class="van-loading__spinner van-loading__spinner--circular" style="color: rgb(180, 39, 43); width: 50px; height: 50px;"><svg class="van-loading__circular" viewBox="25 25 50 50"><circle cx="50" cy="50" r="20" fill="none"></circle></svg></span>';
let bottomDianZi = null;
// 自定义指令合集
export default (app) => {
  app.directive("autoHeightForLineBreak", {
    mounted(el) {
      setTimeout(() => {
        setEl(el, true);
      }, 100);
    },
    updated(el) {
      setEl(el);
    },
  });
  app.directive("loading", {
    mounted(el, data) {
      if (!!data.value) {
        el.appendChild(loadingBox);
      }
    },
    updated(el, data) {
      if (el) {
        if (!!data.value) {
          el.appendChild(loadingBox);
        } else if (el.contains(loadingBox)) {
          el.removeChild(loadingBox);
        }
      }
    },
  });
  app.directive("bottomModel", {
    mounted(el, data) {
      el.style.position = "fixed";
      el.style.bottom = "-1px";
      let elheight = el.offsetHeight;
      bottomDianZi = document.createElement("div");
      bottomDianZi.style.height = elheight + "px";
      let parentNodeBox = el.parentNode;
      // console.log(el);
      parentNodeBox.insertBefore(bottomDianZi, el);
      // console.log(bottomDianZi);
    },
    // updated(el, data) {
    //     console.log(bottomDianZi);

    // }
  });
  app.directive("createHeightBox", {
    mounted(el, data) {
      let elheight = el.offsetHeight;
      let elwidth = el.offsetWidth;
      let heightBox = document.createElement("div");
      heightBox.style.height = elheight + "px";
      heightBox.style.width = elwidth + "px";
      let parentNodeBox = el.parentNode;
      // console.log(el);
      parentNodeBox.insertBefore(heightBox, el);
      // console.log(heightBox);
    },
    // updated(el, data) {
    //     console.log(heightBox);

    // }
  });

  // 长按事件
  // 需要使用browserPatch.js来消除阻止元素默认事件的报错
  app.directive("longpress", {
    beforeMount(el, binding) {
      const cb = binding.value;
      el.$duration = binding.arg || 1000; // 获取长按时长，默认3秒
      if (typeof cb !== "function")
        return console.warn("v-longpress指令必须接收一个回调函数");

      let timer = null;

      const add = (e) => {
        // 排除右键点击情况
        if (e.type === "click" && e.button !== 0) return;

        e.preventDefault();

        if (timer === null) {
          timer = setTimeout(() => {
            cb();
            timer = null;
          }, el.$duration);
        }
      };

      const cancel = (e) => {
        if (timer !== null) {
          clearTimeout(timer);
          timer = null;
          e.target.click();
        }
      };

      // 添加事件监听器
      el.addEventListener("mousedown", add);
      el.addEventListener("touchstart", add);
      // 取消计时器
      el.addEventListener("click", cancel);
      el.addEventListener("mouseout", cancel);
      el.addEventListener("touchend", cancel);
      el.addEventListener("touchcancel", cancel);
    },
    updated(el, binding) {
      // 可以实时更新时长
      el.$duration = binding.arg || 1000;
    },
    unmounted(el) {
      try {
        // 关闭页面时会读取不到add和cancel导致报错
        el.removeEventListener("mousedown", add);
        el.removeEventListener("touchstart", add);
        el.removeEventListener("click", cancel);
        el.removeEventListener("mouseout", cancel);
        el.removeEventListener("touchend", cancel);
        el.removeEventListener("touchcancel", cancel);
      } catch (err) {}
    },
  });
};
