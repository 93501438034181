import {
  Button,
  Icon,
  Image as VanImage,
  Checkbox,
  CheckboxGroup,
  PullRefresh,
  Collapse,
  CollapseItem,
  Stepper,
  DropdownMenu,
  DropdownItem,
  Loading,
  Tab,
  List,
  Sticky,
  //     SkeletonTitle,
  //   SkeletonImage,
  //   SkeletonAvatar,
  //   SkeletonParagraph,
  Locale,
  Calendar,
  ActionSheet,
  Lazyload,
  Dialog,
  Form,
  Field,
  Popup,
  Picker,
  Skeleton,
  NavBar,
  Empty,
  CellGroup,
  Toast,
  Swipe,
  Tabs,
  SwipeItem,
  Popover,
  DatetimePicker,
  Uploader,
  Cell,
  ImagePreview,
} from "vant";
// 切换vantui语言
import jaJP from "vant/es/locale/lang/ja-JP";
export default (app) => {
  app.use(Lazyload);
  app.use(Button);
  app.use(Icon);
  app.use(VanImage);
  app.use(Checkbox);
  app.use(CheckboxGroup);
  app.use(Skeleton);
  app.use(PullRefresh);
  app.use(Stepper);
  app.use(Loading);
  app.use(Picker);
  app.use(Toast);
  app.use(Popup);
  app.use(Cell);
  app.use(List);
  app.use(Sticky);
  app.use(DropdownMenu);
  app.use(DropdownItem);
  app.use(Empty);
  app.use(Tabs);
  app.use(Tab);
  app.use(NavBar);
  // app.use(SkeletonImage);
  // app.use(SkeletonAvatar);
  // app.use(SkeletonParagraph);
  Locale.use("ja-JP", jaJP); //
  app.use(Calendar);
  app.use(ActionSheet);
  app.use(Dialog);
  app.use(Form);
  app.use(Field);
  app.use(CellGroup);
  app.use(Swipe);
  app.use(SwipeItem);

  app.use(DatetimePicker);
  app.use(Uploader);
  app.use(Collapse);
  app.use(CollapseItem);
  app.use(Popover);
  app.config.globalProperties.$toast = Toast;
  app.config.globalProperties.$ImagePreview = ImagePreview;
  // 查看单张大图
  app.config.globalProperties.$lookImg = (img) => {
    ImagePreview([img]);
  };
};
