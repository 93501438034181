import { apiAxios } from "../axios";

import Europess from "../wangZhi";

let Europe = Europess + "/client/";
export default {
  // 删除订单-在已取消列表是看不到的
  orderorderRemove: (data) =>
    apiAxios("post", Europe + "order.orderRemove", data),
  //订单-再来一单
  ordercopyOrder: (data) => apiAxios("post", Europe + "order.copyOrder", data),
  //国际物流查询
  logisticsTrack: (data) =>
    apiAxios("post", Europess + "/api/common/logistics.logisticsTrack", data),
  //订单-计算并修正订单状态(待支付点击取消确定才调用)
  updateOrderStatus: (data) =>
    apiAxios("post", Europe + "order.updateStatus", data),
  //售后列表
  aftersaleList: (data) => apiAxios("post", Europe + "aftersale.list", data),
  //售后列表
  aftersaledetail: (data) =>
    apiAxios("post", Europe + "aftersale.detail", data),
  //仓库修改备注
  wmsupdateClientRemark: (data) =>
    apiAxios("post", Europe + "wms.updateClientRemark", data),
  //仓库修改标签
  wmsupdateOrderDetailTag: (data) =>
    apiAxios("post", Europe + "wms.updateOrderDetailTag", data),
};
